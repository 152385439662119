import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  Button,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  InputAdornment,
} from "@mui/material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import {
  subscriptionPackegesByIdGet,
  subscriptionPackegesUpdatePut,
} from "../../requests/subscriptionPackeges";
import { numberValidate } from "../../validation/validation";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

function UpdateForm() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [fee, setFee] = useState("");
  const [periodInMonths, setPeriodInMonths] = useState("");
  const [active, setActive] = useState(true);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPackagesDetails();
  }, []);

  const getPackagesDetails = async () => {
    setLoading(true);
    const responses = await subscriptionPackegesByIdGet(id);
    try {
      setLoading(false);

      if (responses.success) {
        const packages = responses.data;
        setName(packages.name);
        setFee(packages.fee);
        setPeriodInMonths(packages.periodInMonths);
        setDescription(packages.description);
        setActive(packages.active);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "fee":
        setFee(value);
        break;
      case "periodInMonths":
        setPeriodInMonths(value);
        break;
      case "active":
        setActive(value);
        break;
      default:
        break;
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    const putData = {
      subscriptionPackageId: id,
      active,
      description,
      fee,
      name,
      periodInMonths,
    };
    const responses = await subscriptionPackegesUpdatePut(putData);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate("/subscription-packages/list");
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Update
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6} md={8}>
            <TextField
              fullWidth
              margin="normal"
              id="name"
              name="name"
              label="Name"
              required
              variant="outlined"
              value={name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth margin="normal" sx={{ ml: 10 }}>
              <FormLabel id="demo-active">Is Active?</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-active"
                name="active"
                value={active}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              margin="normal"
              id="fee"
              name="fee"
              label="Fee (In $)"
              variant="outlined"
              value={fee}
              onChange={handleChange}
              error={
                fee
                  ? numberValidate.test(fee)
                    ? fee <= 0
                      ? true
                      : false
                    : true
                  : false
              }
              helperText={
                fee
                  ? numberValidate.test(fee)
                    ? fee <= 0
                      ? "Fee cannot be equal to 0"
                      : false
                    : "Please give a valid number"
                  : ""
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              margin="normal"
              id="periodInMonths"
              name="periodInMonths"
              label="Period (In Months)"
              variant="outlined"
              value={periodInMonths}
              onChange={handleChange}
              error={
                periodInMonths
                  ? numberValidate.test(periodInMonths)
                    ? periodInMonths <= 0
                      ? true
                      : false
                    : true
                  : false
              }
              helperText={
                periodInMonths
                  ? numberValidate.test(periodInMonths)
                    ? periodInMonths <= 0
                      ? "Period cannot be equal to 0"
                      : false
                    : "Please give a valid number"
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Months</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              margin="normal"
              id="description"
              name="description"
              label="Description"
              variant="outlined"
              value={description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleUpdate}
          disabled={
            name === "" ||
            fee === "" ||
            fee <= 0 ||
            periodInMonths === "" ||
            periodInMonths <= 0
          }
        >
          Update
        </LoadingButton>
        <Button
          sx={{ mt: 3, ml: 1 }}
          onClick={() => navigate("/subscription-packages/list")}
        >
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function SubscriptionPackagesUpdate() {
  return (
    <React.Fragment>
      <Helmet title="Package Update" />

      <Typography variant="h3" gutterBottom display="inline">
        Package Update
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/subscription-packages/list">
          Packages
        </Link>
        <Typography> Update</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <UpdateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SubscriptionPackagesUpdate;
