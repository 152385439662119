import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  TablePagination,
  Typography,
  CircularProgress,
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import { Bell, Gift } from "react-feather";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { LockOpen, Task } from "@mui/icons-material";
import moment from "moment";
import { spacing } from "@mui/system";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { notificationAllGet } from "../../requests/notification";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

function EnhancedTable() {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllNotifications();
  }, [pageNo, pageSize]);

  const getAllNotifications = async () => {
    setLoading(true);
    const responses = await notificationAllGet({
      sort_by: "id",
      asc_or_desc: "desc",
      page_no: pageNo,
      page_size: pageSize,
    });
    try {
      setLoading(false);

      if (responses.success) {
        setNotifications(responses.data.content);
        setTotalItems(responses.data.totalElements);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const redirectToNotification = (type) => {
    if (type.includes("SUBSCRIPTION") || type.includes("TRANSACTION")) {
      return "/transaction-history";
    } else if (type.includes("BEQUEST")) {
      return "/bequest-history";
    } else if (type.includes("WILL")) {
      return "/will-history";
    } else if (type.includes("HEALTH_CARE_DIRECTIVE")) {
      return "/healthcare-directive-history";
    } else if (type.includes("STOCK")) {
      return "/donate-stock-history";
    } else if (type.includes("NEW_DOC") || type.includes("DOCUMENT")) {
      return "/verification-docs/all";
    } else if (type.includes("SIGN_UP")) {
      return "/organization/non-profit/list";
    } else if (type.includes("INQUIRY") || type.includes("DEMO")) {
      return "/inquiry/list";
    }
  };

  return (
    <div>
      <Paper>
        <Grid
          container
          display={loading ? "flex" : "none"}
          justifyContent="center"
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
        <List>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <ListItem
                key={index}
                divider
                component={Link}
                to={redirectToNotification(notification.type)}
              >
                <ListItemAvatar>
                  <Avatar>
                    <SvgIcon fontSize="small">
                      {notification.type.includes("SUBSCRIPTION") ? (
                        <SubscriptionsIcon />
                      ) : notification.type.includes("NEW_DOC") ||
                        notification.type.includes("DOCUMENT") ? (
                        <Task />
                      ) : notification.type.includes("SIGN_UP") ? (
                        <LockOpen />
                      ) : notification.type.includes("BEQUEST") ? (
                        <Gift />
                      ) : (
                        <Bell />
                      )}
                    </SvgIcon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={notification.title}
                  primaryTypographyProps={{
                    variant: "subtitle2",
                    color: "textPrimary",
                  }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body1"
                        color="text.secondary"
                      >
                        {notification.message}
                      </Typography>
                      <br />
                      {notification.creationDateTimeStamp &&
                        `- ${moment
                          .unix(notification.creationDateTimeStamp)
                          .format("Do MMMM YYYY, h:mm a")}`}
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))
          ) : (
            <ListItem divider>
              <ListItemText
                primary={"No Notifications"}
                primaryTypographyProps={{
                  variant: "subtitle2",
                  color: "textPrimary",
                }}
              />
            </ListItem>
          )}
        </List>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function NotificationList() {
  return (
    <React.Fragment>
      <Helmet title="Notifications" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Notifications
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default NotificationList;
