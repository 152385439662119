import React, { useEffect, useState } from "react";
import styles from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  FormControlLabel,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import {
  roleTypesAllGet,
  roleUpdatePut,
  roleByIdGet,
} from "../../requests/role";
import { privilegeAllGet } from "../../requests/privilege";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Breadcrumbs = styles(MuiBreadcrumbs)(spacing);

const Card = styles(MuiCard)(spacing);

const Divider = styles(MuiDivider)(spacing);

const TextField = styles(MuiTextField)(spacing);

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon color="primary" sx={{ fontSize: "0.9rem" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function UpdateForm() {
  const navigate = useNavigate();

  const privilegeGroup = [
    "ROLE",
    "USER",
    "CMS",
    "RATING",
    "GLOSSARY",
    "LEGAL_DOC",
    "SUBSCRIPTION_PACKAGE",
    "COUPON",
    "PROMO",
    "HISTORY",
    "VERIFICATIONDOC",
    "UPLOADEDDOC",
    "BLOG",
    "ORGANIZATIONTYPE",
    "PRODUCT",
    "SCHOLAR",
    "INQUIRY_ALL",
    "MARKETING_ALL",
    "FORM_MANAGE",
  ];

  const { id } = useParams();

  const [description, setDescription] = useState("");
  const [roleName, setRoleName] = useState("");

  const [privilege, setPrivilege] = useState([]);
  const [selectedPrivileges, setSelectedPrivileges] = useState([]);
  const [inputValuePrivilege, setInputValuePrivilege] = useState("");
  const [allPrivilegeIndex, setAllPrivilegeIndex] = useState([]);

  const [roleType, setRoleType] = useState("");
  const [roleTypeOptions, setRoleTypeOptions] = useState([]);
  const [expanded, setExpanded] = useState(["panel0", "panel1"]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllRoleTypes();
    getAllPrivilege();
  }, []);

  // useEffect(() => {
  //   let allIndex = [];
  //   selectedPrivileges.map((pri) => {
  //     let priIndex = privilege.findIndex((priv) => priv.id == pri.id);
  //     if (priIndex !== -1) {
  //       allIndex.push(priIndex);
  //     }
  //   });

  //   setAllPrivilegeIndex(allIndex);
  // }, [selectedPrivileges, privilege]);

  const getAllRoleTypes = async () => {
    setLoading(true);
    const responses = await roleTypesAllGet();
    try {
      setLoading(false);

      if (responses.success) {
        setRoleTypeOptions(responses.data);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllPrivilege = async () => {
    setLoading(true);
    const responses = await privilegeAllGet();
    try {
      setLoading(false);

      if (responses.success) {
        setPrivilege(responses.data);
        getRoleDetails();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getRoleDetails = async () => {
    setLoading(true);
    const responses = await roleByIdGet(id);
    try {
      setLoading(false);

      if (responses.success) {
        const role = responses.data;
        setRoleName(role.roleName);
        setRoleType(role.roleType);
        setDescription(role.description ? role.description : "");
        let selected = role.privileges.map((pri) => pri.id);
        // console.log(selected);
        setSelectedPrivileges(selected);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "roleName":
        setRoleName(value);
        break;
      case "roleType":
        setRoleType(value);
        break;
      case "description":
        setDescription(value);
        break;

      default:
        break;
    }
  };

  const handleAccordianChange = (panel) => (event, newExpanded) => {
    let expanded = [];
    let ind = panel.split("panel")[1];
    if (newExpanded) {
      if (parseInt(ind) % 2 === 0) {
        expanded = [`panel${parseInt(ind)}`, `panel${parseInt(ind) + 1}`];
      } else {
        expanded = [`panel${parseInt(ind)}`, `panel${parseInt(ind) - 1}`];
      }
    }
    setExpanded(expanded);
    // setExpanded(newExpanded ? panel : false);
  };
  const handleChangePrivileges = (e) => {
    let selected = [...selectedPrivileges];
    if (e.target.checked) {
      selected.push(parseInt(e.target.name));
    } else {
      if (selected.includes(parseInt(e.target.name))) {
        selected = selected.filter((item) => item != parseInt(e.target.name));
      }
    }
    setSelectedPrivileges(selected);
  };

  const handleUpdate = async () => {
    setLoading(true);

    // var privilegeIdArray = selectedPrivileges.map(function (priv) {
    //   return priv.id;
    // });

    const putData = {
      id,
      previlegeId: selectedPrivileges,
      description,
      roleName,
      roleType,
    };
    const responses = await roleUpdatePut(putData);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate("/role/list");
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Update
        </Typography>

        <TextField
          fullWidth
          margin="normal"
          id="roleName"
          name="roleName"
          label="Role Name*"
          variant="outlined"
          value={roleName}
          onChange={handleChange}
        />

        <Accordion>
          <AccordionSummary aria-controls={`panel-content`} id={`panel-header`}>
            <Typography variant="h6" color="primary">
              Select Privileges*
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {privilegeGroup.map((pri, index) => {
                return (
                  <Grid item xs={12} sm={12} md={6}>
                    {privilege.findIndex((privilege) =>
                      privilege.name.match(pri)
                    ) !== -1 ? (
                      <Accordion
                        key={index}
                        expanded={expanded.includes(`panel${index}`)}
                        onChange={handleAccordianChange(`panel${index}`)}
                      >
                        <AccordionSummary
                          aria-controls={`panel${index}-content`}
                          id={`panel${index}-header`}
                        >
                          <Typography variant="button" color="primary">
                            {pri === "VERIFICATIONDOC"
                              ? "VERIFICATION DOC (CONFIGURATION)"
                              : pri === "SUBSCRIPTION_PACKAGE"
                              ? "SUBSCRIPTION PACKAGE"
                              : pri === "UPLOADEDDOC"
                              ? "NON-PROFIT UPLOADED DOCS"
                              : pri === "ORGANIZATIONTYPE"
                              ? "ORGANIZATION TYPE"
                              : pri === "MARKETING_ALL"
                              ? "COMMUNICATION CENTER"
                              : pri === "INQUIRY_ALL"
                              ? "INQUIRIES"
                              : pri === "RATING"
                              ? "TESTIMONIAL"
                              : pri === "CMS"
                              ? "PAGES AND SECTIONS"
                              : pri === "LEGAL_DOC"
                              ? "LEGAL DOCUMENTS"
                              : pri === "PROMO"
                              ? "MANAGE PROMOS"
                              : pri === "FORM_MANAGE"
                              ? "MANAGE FORMS"
                              : pri}
                          </Typography>
                        </AccordionSummary>
                        {privilege
                          ?.filter((item) => item.name.match(pri))
                          .map((item, inde) => (
                            <AccordionDetails key={inde}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={item.id}
                                    onChange={(e) => handleChangePrivileges(e)}
                                    checked={selectedPrivileges.includes(
                                      item.id
                                    )}
                                  />
                                }
                                label={
                                  // item.name === "SUBSCRIPTION_PACKAGE" ||
                                  item.name === "MARKETING_ALL" ||
                                  item.name === "INQUIRY_ALL" ||
                                  item.name === "FORM_MANAGE_ALL"
                                    ? "ALL"
                                    : pri === "HISTORY"
                                    ? item.name
                                        .replace(pri + "_", "")
                                        .replace(/_/g, " ")
                                    : item.name.replace(pri + "_", "")
                                }
                              />
                            </AccordionDetails>
                          ))}
                      </Accordion>
                    ) : null}
                  </Grid>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <TextField
          fullWidth
          multiline
          rows={3}
          margin="normal"
          id="description"
          name="description"
          label="Description"
          variant="outlined"
          error={description.length > 255}
          helperText={
            description.length > 255
              ? "Limit exceeds(Maximum 255 character"
              : "Character limit: Maximum 255"
          }
          value={description}
          onChange={handleChange}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3, mr: 1 }}
          onClick={handleUpdate}
          disabled={
            roleName === "" ||
            roleType === "" ||
            description.length > 255 ||
            selectedPrivileges.length === 0
          }
        >
          Update
        </LoadingButton>
        <Button sx={{ mt: 3 }} onClick={() => navigate("/role/list")}>
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function RoleUpdate() {
  return (
    <React.Fragment>
      <Helmet title="Role Update" />

      <Typography variant="h3" gutterBottom display="inline">
        Role Update
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/role/list">
          Roles
        </Link>
        <Typography> Update</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <UpdateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default RoleUpdate;
