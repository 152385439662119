import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  InputLabel,
  Rating,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload, Delete } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { fileCommonPost, fileCommonUpdatePut } from "../../../requests/file";
import { getToastError, getToastSuccess } from "../../../utils/toasts";
import { ratingByIdGet, ratingUpdatePut } from "../../../requests/testimonial";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function UpdateForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [occupation, setOccupation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [reviewText, setReviewText] = useState("");
  const [starCount, setStarCount] = useState(null);

  const inputFile = useRef(null);
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState(0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTestimonialDetails();
  }, []);

  const getTestimonialDetails = async () => {
    setLoading(true);
    const responses = await ratingByIdGet(id);
    try {
      setLoading(false);

      if (responses.success) {
        const testimonial = responses.data;
        setName(testimonial.name);
        setCompanyName(testimonial.companyName);
        setOccupation(testimonial.occupation);
        setStarCount(testimonial.starCount);
        setReviewText(testimonial.reviewText);
        if (testimonial.image) {
          setFile(testimonial.image);
          setFileId(testimonial.image.id);
          setImage(testimonial.image.fileUrl);
        }
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "occupation":
        setOccupation(value);
        break;
      case "companyName":
        setCompanyName(value);
        break;
      case "reviewText":
        setReviewText(value);
        break;
      default:
        break;
    }
  };

  const handleChangeFile = (e) => {
    if (e.target.files[0] != null) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    inputFile.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImage("");
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (file) {
      if (fileId) {
        if (file.id) {
          handleUpdate(file.id);
        } else {
          let formData = new FormData();
          formData.append("file", file);
          const response = await fileCommonUpdatePut(
            formData,
            "RATING_IMAGE",
            fileId
          );

          try {
            setLoading(false);
            if (response.success) {
              handleUpdate(fileId);
            } else {
              getToastError(response.message);
            }
          } catch (error) {
            setLoading(false);
            getToastError(error.response.data.message);
          }
        }
      } else {
        let formData = new FormData();
        formData.append("file", file);
        const response = await fileCommonPost(formData, "RATING_IMAGE");

        try {
          setLoading(false);

          if (response.success) {
            handleUpdate(response.data.id);
          } else {
            getToastError(response.message);
          }
        } catch (error) {
          setLoading(false);
          getToastError(error.response.data.message);
        }
      }
    } else {
      handleUpdate(null);
    }
  };

  const handleUpdate = async (fileId) => {
    setLoading(true);
    const putData = {
      ratingId: id,
      imageId: fileId,
      name,
      occupation,
      companyName,
      reviewText,
      starCount,
    };
    const responses = await ratingUpdatePut(putData);
    try {
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate(`/page-management/testimonial/list`);
      } else {
        setLoading(false);

        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Update
        </Typography>
        <InputLabel sx={{ mt: 2, mb: 1 }}>Image</InputLabel>
        <Grid container>
          <Grid item xs={12}>
            {file ? <img style={{ height: "30vh" }} src={image} /> : ""}
          </Grid>
          <Grid item xs={12}>
            {file ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={() => handleUpload()}
                >
                  <CloudUpload mr={2} /> Change
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  color="error"
                  component="span"
                  onClick={() => handleRemove()}
                >
                  <Delete mr={2} /> Remove
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={() => handleUpload()}
              >
                <CloudUpload mr={2} /> Upload
              </Button>
            )}
          </Grid>
        </Grid>

        <input
          type="file"
          id="file"
          accept="image/*"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={handleChangeFile}
          onClick={(event) => {
            event.target.value = null;
          }}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              id="name"
              name="name"
              label="Name"
              required
              variant="outlined"
              value={name}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              margin="normal"
              id="occupation"
              name="occupation"
              label="Occupation"
              variant="outlined"
              value={occupation}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              id="companyName"
              name="companyName"
              label="Company Name"
              variant="outlined"
              value={companyName}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography component="legend">Rating*</Typography>
            <Rating
              name="simple-controlled"
              value={starCount}
              size="large"
              precision={0.5}
              onChange={(event, newValue) => {
                setStarCount(newValue);
              }}
            />
          </Grid>
        </Grid>

        <TextField
          fullWidth
          margin="normal"
          id="reviewText"
          name="reviewText"
          label="Review Text"
          variant="outlined"
          required
          multiline
          rows={3}
          value={reviewText}
          onChange={handleChange}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={
            name === "" ||
            occupation === "" ||
            reviewText === "" ||
            !starCount ||
            starCount === 0 ||
            loading
          }
        >
          Update
        </LoadingButton>
        <Button
          sx={{ mt: 3, ml: 1 }}
          onClick={() => navigate(`/page-management/testimonial/list`)}
        >
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function TestimonialUpdate() {
  return (
    <React.Fragment>
      <Helmet title="Testimonial Update" />

      <Typography variant="h3" gutterBottom display="inline">
        Testimonial Update
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to={`/page-management/testimonial/list`}>
          Testimonials
        </Link>
        <Typography> Update</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <UpdateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TestimonialUpdate;
