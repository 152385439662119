import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  Alert,
  CircularProgress,
  TextField,
  Card,
  CardContent,
  Autocomplete,
  Menu,
} from "@mui/material";
import { Add as AddIcon, Delete, Edit, Search } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { authorAllGet, authorByIdDelete } from "../../../requests/author";
import { blogAllGet } from "../../../requests/blog";
import { ConfirmationDialog } from "../../../components/dialogs/ConfirmationDialog";
import { getToastError, getToastSuccess } from "../../../utils/toasts";
import { useSelector } from "react-redux";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [blogUUID, setBlogUUID] = useState("");
  const [authors, setAuthors] = useState([]);

  const [name, setName] = useState("");
  const [anchorName, setAnchorName] = useState(null);
  const openName = Boolean(anchorName);

  const [email, setEmail] = useState("");
  const [anchorEmail, setAnchorEmail] = useState(null);
  const openEmail = Boolean(anchorEmail);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [authorId, setAuthorId] = useState(0);

  useEffect(() => {
    if (location.search && location.search.length > 0) {
      if (location.search.split("=").length === 2) {
        setBlogUUID(location.search.split("=")[1]);
      }
    }
    getAllBlogs();
  }, []);

  useEffect(() => {
    if (blogUUID.length > 0) {
      let blogIndex = blogs.findIndex((blog) => blog.uuid === blogUUID);
      if (blogIndex !== -1) {
        setSelectedBlog(blogs[blogIndex]);
      }
    }
  }, [blogs, blogUUID]);

  useEffect(() => {
    if (blogUUID !== "") {
      getAllAuthors();
    }
  }, [blogUUID, pageNo, pageSize, name, email]);

  const getAllBlogs = async () => {
    setLoading(true);
    const responses = await blogAllGet();
    try {
      setLoading(false);

      if (responses.success) {
        let blog = responses.data;
        setBlogs(blog);
        if (blog.length > 0 && blogUUID.length === 0 && !location.search) {
          setBlogUUID(blog[0].uuid);
        }
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllAuthors = async () => {
    setLoading(true);
    const responses = await authorAllGet({
      pageNo,
      pageSize,
      name: name.length > 0 ? name : null,
      email: email.length > 0 ? email : null,
      blogUUID,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setAuthors(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "name":
        setAnchorName(event.currentTarget);
        break;
      case "email":
        setAnchorEmail(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "name":
        setAnchorName(null);
        break;
      case "email":
        setAnchorEmail(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (authorId) => {
    setConfirmationOpen(true);
    setAuthorId(authorId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setAuthorId(0);
  };

  const deleteAuthor = async () => {
    setLoading(true);
    const responses = await authorByIdDelete(authorId, blogUUID);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllAuthors();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id, uuid) => {
    navigate(`/blog/author/update/${id}/${uuid}`);
  };

  return (
    <div>
      <Card mb={6}>
        <CardContent pb={1}>
          <Grid container>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                disablePortal
                value={
                  selectedBlog && Object.keys(selectedBlog).length > 0
                    ? selectedBlog
                    : null
                }
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedBlog(newValue);
                    setBlogUUID(newValue.uuid);
                  } else {
                    setSelectedBlog({});
                    setBlogUUID("");
                    setAuthors([]);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={blogs}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    size="small"
                    label="Filter by Blog"
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Paper>
          <TableContainer>
            <Grid
              container
              display={loading ? "flex" : "none"}
              justifyContent="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    Sl. No.
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Name{" "}
                    <IconButton onClick={(e) => handleClick(e, "name")}>
                      <Search
                        fontSize="small"
                        color={name.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorName}
                    open={openName}
                    onClose={() => handleCloseMenu("name")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="name"
                      name="name"
                      value={name}
                      onChange={handleChange}
                      placeholder="Search Name"
                    />
                  </Menu>
                  <TableCell align="left" padding="normal">
                    Email
                    <IconButton onClick={(e) => handleClick(e, "email")}>
                      <Search
                        fontSize="small"
                        color={email.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEmail}
                    open={openEmail}
                    onClose={() => handleCloseMenu("email")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      placeholder="Search Email"
                    />
                  </Menu>
                  <TableCell align="left" padding="normal">
                    Image
                  </TableCell>
                  {privileges.includes("BLOG_UPDATE") ||
                  privileges.includes("BLOG_DELETE") ? (
                    <TableCell align="left" padding="normal">
                      Actions
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {authors && authors.length > 0 ? (
                  authors.map((author, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={author.id}
                      >
                        <TableCell align="left">
                          {pageNo * pageSize + index + 1}.
                        </TableCell>
                        <TableCell align="left">{author.name}</TableCell>
                        <TableCell align="left">{author.email}</TableCell>
                        <TableCell align="left" sx={{ borderRadius: "5px" }}>
                          {author.image ? (
                            <img
                              style={{
                                height: "150px",
                                width: "150px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                              src={author.image.fileUrl}
                            />
                          ) : (
                            "No Image"
                          )}
                        </TableCell>
                        <TableCell padding="none" align="left">
                          <Box mr={2}>
                            {privileges.includes("BLOG_UPDATE") && (
                              <IconButton
                                aria-label="details"
                                size="large"
                                onClick={() =>
                                  goEditPage(author.id, author.blogUUID)
                                }
                              >
                                <Edit color="primary" />
                              </IconButton>
                            )}
                            {privileges.includes("BLOG_DELETE") && (
                              <IconButton
                                aria-label="delete"
                                size="large"
                                onClick={() => handleDelete(author.id)}
                              >
                                <Delete sx={{ color: "#d93232" }} />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      No Authors Found for this Blog!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={pageSize}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>
      <ConfirmationDialog
        title="Delete Author"
        text="Are you sure that you want to delete this author?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteAuthor}
      />
    </div>
  );
}

function AuthorList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Authors" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Authors
          </Typography>
        </Grid>
        {privileges.includes("BLOG_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/blog/author/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Author
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Authors</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AuthorList;
