import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import parse from "html-react-parser";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { sentEmailByIdGet } from "../../requests/sent-email";
import { getToastError } from "../../utils/toasts";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function ViewForm() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [mailBody, setMailBody] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getEmailHistory();
  }, []);

  const getEmailHistory = async () => {
    setLoading(true);
    const responses = await sentEmailByIdGet(id);
    try {
      setLoading(false);

      if (responses.success) {
        let emails = responses.data;
        setSubject(emails.subject);
        setEmail(emails.email);
        setMailBody(emails.body);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Grid
          container
          display={loading ? "flex" : "none"}
          justifyContent="center"
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"}>
          <Grid item>
            <Typography variant="h3" color="primary">
              <b>{subject}</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid container mt={10}>
          <Grid item>
            <Typography variant="h6">To:</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" ml={2}>
              <Link href={`mailto:${email}`}> {email}</Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid container mt={10}>
          <Grid item>{parse(mailBody)}</Grid>
        </Grid>
        <Button sx={{ mt: 3 }} onClick={() => navigate("/sent-email/list")}>
          Back
        </Button>
      </CardContent>
    </Card>
  );
}

function SentEmailView() {
  return (
    <React.Fragment>
      <Helmet subject="Send Email" />

      <Typography variant="h3" gutterBottom display="inline">
        View Email History
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/sent-email/list">
          Sent Emails
        </Link>
        <Typography>View Email History</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ViewForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SentEmailView;
