import axios from "axios";
import { basePath, headers } from "../utils/http";

export const blogAllGet = async () => {
  try {
    const response = await axios.get(`${basePath()}/blog/all`, headers());
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const blogCreatePost = async (data) => {
  try {
    const response = await axios.post(
      `${basePath()}/blog/admin/create`,
      data,
      headers()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const blogUpdatePut = async (data) => {
  try {
    const response = await axios.put(
      `${basePath()}/blog/admin/update`,
      data,
      headers()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const blogByUuidGet = async (uuid) => {
  try {
    const response = await axios.get(
      `${basePath()}/blog/id/${uuid}`,
      headers()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const blogByIdDelete = async (blogUUID) => {
  try {
    const response = await axios.delete(
      `${basePath()}/blog/admin/delete/${blogUUID}`,
      headers()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};
