import axios from "axios";
import { basePath, headers } from "../utils/http";

export const transactionAllGet = async (params) => {
  try {
    const response = await axios.get(`${basePath()}/api/transaction/all`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const allProductsGet = async () => {
  try {
    const response = await axios.get(
      `${basePath()}/product/all-wills-with-tag-and-name`,
      headers()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};
export const willAllGet = async (params) => {
  try {
    const response = await axios.get(`${basePath()}/user-wills/all`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const allIslamicBequestsGet = async (params) => {
  try {
    const response = await axios.get(`${basePath()}/bequest-islamic-will/all`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const programAllGet = async (params) => {
  try {
    const response = await axios.get(`${basePath()}/program/all`, {
      params,
    });
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};


export const allStockDonationGet = async (params) => {
  try {
    const response = await axios.get(
      `${basePath()}/api/stocks/donation/get/all`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};


export const allHealthDirectiveGet = async (params) => {
  try {
    const response = await axios.get(`${basePath()}/api/health-care/get/all`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

