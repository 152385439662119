import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  CircularProgress,
  Menu,
  TextField,
  MenuItem,
  Breadcrumbs,
} from "@mui/material";
import { Add as AddIcon, Delete, Edit, Search } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { useSelector } from "react-redux";
import { couponAllGet, couponByIdDelete } from "../../requests/coupon";
import moment from "moment";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState([]);

  const [couponCode, setCouponCode] = useState("");
  const [anchorCouponCode, setAnchorCouponCode] = useState(null);
  const openCouponCode = Boolean(anchorCouponCode);

  const [discountType, setDiscountType] = useState("");
  const [anchorDiscountType, setAnchorDiscountType] = useState(null);
  const openDiscountType = Boolean(anchorDiscountType);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [couponId, setPackageId] = useState(0);

  useEffect(() => {
    getAllCoupons();
  }, [couponCode, discountType, pageNo, pageSize]);

  const getAllCoupons = async () => {
    setLoading(true);
    const responses = await couponAllGet({
      pageNo,
      pageSize,
      couponCode: couponCode.length > 0 ? couponCode : null,
      discountType: discountType.length > 0 ? discountType : null,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setCoupons(responses.data.content);
        setTotalItems(responses.data.totalElements);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "couponCode":
        setAnchorCouponCode(event.currentTarget);
        break;
      case "discountType":
        setAnchorDiscountType(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "couponCode":
        setAnchorCouponCode(null);
        break;
      case "discountType":
        setAnchorDiscountType(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "couponCode":
        setCouponCode(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (couponId) => {
    setConfirmationOpen(true);
    setPackageId(couponId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setPackageId(0);
  };

  const deleteCoupon = async () => {
    setLoading(true);
    const responses = await couponByIdDelete(couponId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllCoupons();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id) => {
    navigate(`/coupon/update/${id}`);
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  Sl. No.
                </TableCell>
                <TableCell align="left" padding="normal">
                  {" "}
                  Code
                  <IconButton onClick={(e) => handleClick(e, "couponCode")}>
                    <Search
                      fontSize="small"
                      color={couponCode.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorCouponCode}
                  open={openCouponCode}
                  onClose={() => handleCloseMenu("couponCode")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="couponCode"
                    name="couponCode"
                    value={couponCode}
                    onChange={handleChange}
                    placeholder="Search Code"
                  />
                </Menu>

                <TableCell align="left" padding="normal">
                  Discount Amount
                </TableCell>
                <TableCell align="left" padding="normal">
                  {" "}
                  Discount Type
                  <IconButton onClick={(e) => handleClick(e, "discountType")}>
                    <Search
                      fontSize="small"
                      color={discountType.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorDiscountType}
                  open={openDiscountType}
                  onClose={() => handleCloseMenu("discountType")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem value={null} disabled>
                    Search discount type
                  </MenuItem>

                  <MenuItem
                    value={""}
                    sx={{
                      backgroundColor: discountType == "" ? "#219B89" : "",
                      color: discountType == "" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorDiscountType(null);
                      setDiscountType("");
                    }}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    value={"FIXED"}
                    sx={{
                      backgroundColor: discountType == "FIXED" ? "#219B89" : "",
                      color: discountType == "FIXED" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorDiscountType(null);
                      setDiscountType("FIXED");
                    }}
                  >
                    Fixed
                  </MenuItem>
                  <MenuItem
                    value={"PERCENTAGE"}
                    sx={{
                      backgroundColor:
                        discountType == "PERCENTAGE" ? "#219B89" : "",
                      color: discountType == "PERCENTAGE" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorDiscountType(null);
                      setDiscountType("PERCENTAGE");
                    }}
                  >
                    Percentage
                  </MenuItem>
                </Menu>
                <TableCell align="left" padding="normal">
                  Expiration Date
                </TableCell>
                <TableCell align="left" padding="normal">
                  Max Time can be Used
                </TableCell>
                <TableCell align="left" padding="normal">
                  Already used time
                </TableCell>

                {privileges.includes("COUPON_UPDATE") ||
                privileges.includes("COUPON_DELETE") ? (
                  <TableCell align="left" padding="normal">
                    Actions
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {coupons && coupons.length > 0 ? (
                coupons.map((coupon, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={coupon.id}
                    >
                      <TableCell align="left">
                        {pageNo * pageSize + index + 1}.
                      </TableCell>
                      <TableCell align="left">{coupon.couponCode}</TableCell>
                      <TableCell align="left">
                        {coupon.discountAmount}
                      </TableCell>
                      <TableCell align="left">{coupon.discountType}</TableCell>
                      <TableCell align="left">
                        {moment
                          .unix(coupon.expirationDate)
                          .format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell align="left">
                        {coupon.maxTimesCanBeUsed}
                      </TableCell>
                      <TableCell align="left">{coupon.usedCount}</TableCell>

                      <TableCell padding="none" align="left">
                        <Box mr={2}>
                          {privileges.includes("COUPON_UPDATE") && (
                            <IconButton
                              aria-label="details"
                              size="large"
                              onClick={() => goEditPage(coupon.id)}
                            >
                              <Edit color="primary" />
                            </IconButton>
                          )}
                          {privileges.includes("COUPON_DELETE") && (
                            <IconButton
                              aria-label="delete"
                              size="large"
                              onClick={() => handleDelete(coupon.id)}
                            >
                              <Delete sx={{ color: "#d93232" }} />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>No Coupons Found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        title="Delete Coupon"
        text="Are you sure that you want to delete this coupon?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteCoupon}
      />
    </div>
  );
}

function CouponList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Coupons" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Coupons
          </Typography>
        </Grid>
        {privileges.includes("COUPON_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/coupon/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Coupon
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Coupons</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CouponList;
