import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  CircularProgress,
  TextField,
  Menu,
  Breadcrumbs,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete,
  Edit,
  RemoveRedEye,
  Search,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { ConfirmationDialog } from "../../../components/dialogs/ConfirmationDialog";
import { pageAllGet, pageByIdDelete } from "../../../requests/page";
import { getToastError, getToastSuccess } from "../../../utils/toasts";
import { useSelector } from "react-redux";
import { ratingAllGet, ratingByIdDelete } from "../../../requests/testimonial";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const [testimonials, setTestimonials] = useState([]);

  const [name, setName] = useState("");
  const [anchorName, setAnchorName] = useState(null);
  const openName = Boolean(anchorName);

  const [occupation, setOccupation] = useState("");
  const [anchorOccupation, setAnchorOccupation] = useState(null);
  const openOccupation = Boolean(anchorOccupation);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [testimonialId, setTestimonialId] = useState(0);

  useEffect(() => {
    getAllTestimonials();
  }, [pageNo, pageSize, name, occupation]);

  const getAllTestimonials = async () => {
    setLoading(true);
    const responses = await ratingAllGet({
      pageNo,
      pageSize,
      name: name.length > 0 ? name : null,
      occupation: occupation.length > 0 ? occupation : null,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setTestimonials(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "name":
        setAnchorName(event.currentTarget);
        break;
      case "occupation":
        setAnchorOccupation(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "name":
        setAnchorName(null);
        break;
      case "occupation":
        setAnchorOccupation(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "occupation":
        setOccupation(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (pageId) => {
    setConfirmationOpen(true);
    setTestimonialId(pageId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setTestimonialId(0);
  };

  const deleteTestimonial = async () => {
    setLoading(true);
    const responses = await ratingByIdDelete(testimonialId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllTestimonials();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id) => {
    navigate(`/page-management/testimonial/update/${id}`);
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  Sl. No.
                </TableCell>
                <TableCell align="left" padding="normal">
                  Name
                  <IconButton onClick={(e) => handleClick(e, "name")}>
                    <Search
                      fontSize="small"
                      color={name.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorName}
                  open={openName}
                  onClose={() => handleCloseMenu("name")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    placeholder="Search Name"
                  />
                </Menu>
                <TableCell align="left" padding="normal">
                  Company Name
                </TableCell>
                <TableCell align="left" padding="normal">
                  Occupation
                  <IconButton onClick={(e) => handleClick(e, "occupation")}>
                    <Search
                      fontSize="small"
                      color={occupation.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorOccupation}
                  open={openOccupation}
                  onClose={() => handleCloseMenu("occupation")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="occupation"
                    name="occupation"
                    value={occupation}
                    onChange={handleChange}
                    placeholder="Search Occupation"
                  />
                </Menu>
                {privileges.includes("RATING_UPDATE") ||
                privileges.includes("RATING_DELETE") ? (
                  <TableCell align="center" padding="normal" width={"20%"}>
                    Actions
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {testimonials && testimonials.length > 0 ? (
                testimonials.map((testimonial, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={testimonial.id}
                    >
                      <TableCell align="left">
                        {pageNo * pageSize + index + 1}.
                      </TableCell>
                      <TableCell align="left">{testimonial.name}</TableCell>
                      <TableCell align="left">
                        {testimonial.companyName}
                      </TableCell>
                      <TableCell align="left">
                        {testimonial.occupation}
                      </TableCell>
                      {privileges.includes("RATING_UPDATE") ||
                      privileges.includes("RATING_DELETE") ? (
                        <TableCell padding="none" align="center">
                          <Box mr={2}>
                            {privileges.includes("RATING_UPDATE") && (
                              <IconButton
                                aria-label="details"
                                size="large"
                                onClick={() => goEditPage(testimonial.id)}
                              >
                                <Edit color="primary" />
                              </IconButton>
                            )}
                            {privileges.includes("RATING_DELETE") && (
                              <IconButton
                                aria-label="delete"
                                size="large"
                                onClick={() => handleDelete(testimonial.id)}
                              >
                                <Delete sx={{ color: "#d93232" }} />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No Testimonial Found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        title="Delete Testimonial"
        text="Are you sure that you want to delete this testimonial?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteTestimonial}
      />
    </div>
  );
}

function TestimonialList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Testimonials" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Testimonials
          </Typography>
        </Grid>
        {privileges.includes("RATING_CREATE") && (
          <Grid item>
            <div>
              <Link
                component={NavLink}
                to="/page-management/testimonial/create"
              >
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Testimonial
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Testimonials</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TestimonialList;
