import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  Alert,
  CircularProgress,
  Chip,
  Menu,
  TextField,
  MenuItem,
  Breadcrumbs,
} from "@mui/material";
import { Add as AddIcon, Delete, Edit, Search } from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  organizationTypeAllGet,
  organizationTypeByIdDelete,
} from "../../requests/organizationType";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { useSelector } from "react-redux";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const [types, setTypes] = useState([]);

  const [name, setName] = useState("");
  const [anchorName, setAnchorName] = useState(null);
  const openName = Boolean(anchorName);

  const [isActive, setIsActive] = useState("");
  const [anchorIsActive, setAnchorIsActive] = useState(null);
  const openIsActive = Boolean(anchorIsActive);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [typeId, setTypeId] = useState(0);

  useEffect(() => {
    getAllTypes();
  }, [name, isActive]);

  const getAllTypes = async () => {
    setLoading(true);
    const responses = await organizationTypeAllGet({
      name: name.length > 0 ? name : null,
      isActive: isActive.length > 0 ? isActive : null,
    });
    try {
      setLoading(false);

      if (responses.success) {
        responses.data.sort((a, b) => (a.id < b.id ? 1 : -1));
        setTypes(responses.data);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "name":
        setAnchorName(event.currentTarget);
        break;
      case "isActive":
        setAnchorIsActive(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "name":
        setAnchorName(null);
        break;
      case "isActive":
        setAnchorIsActive(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (typeId) => {
    setConfirmationOpen(true);
    setTypeId(typeId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setTypeId(0);
  };

  const deleteType = async () => {
    setLoading(true);
    const responses = await organizationTypeByIdDelete(typeId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllTypes();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id) => {
    navigate(`/organization/type/update/${id}`);
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  Sl. No.
                </TableCell>
                <TableCell align="left" padding="normal">
                  {" "}
                  Name
                  <IconButton onClick={(e) => handleClick(e, "name")}>
                    <Search
                      fontSize="small"
                      color={name.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorName}
                  open={openName}
                  onClose={() => handleCloseMenu("name")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    placeholder="Search Name"
                  />
                </Menu>
                <TableCell align="left" padding="normal">
                  {" "}
                  Active?
                  <IconButton onClick={(e) => handleClick(e, "isActive")}>
                    <Search
                      fontSize="small"
                      color={isActive.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorIsActive}
                  open={openIsActive}
                  onClose={() => handleCloseMenu("isActive")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem value={null} disabled>
                    Search is active or not
                  </MenuItem>

                  <MenuItem
                    value={""}
                    sx={{
                      backgroundColor: isActive == "" ? "#219B89" : "",
                      color: isActive == "" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorIsActive(null);
                      setIsActive("");
                    }}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    value={true}
                    sx={{
                      backgroundColor: isActive == "true" ? "#219B89" : "",
                      color: isActive == "true" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorIsActive(null);
                      setIsActive("true");
                    }}
                  >
                    Yes
                  </MenuItem>
                  <MenuItem
                    value={false}
                    sx={{
                      backgroundColor: isActive == "false" ? "#219B89" : "",
                      color: isActive == "false" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorIsActive(null);
                      setIsActive("false");
                    }}
                  >
                    No
                  </MenuItem>
                </Menu>
                <TableCell align="left" padding="normal">
                  Image
                </TableCell>
                {privileges.includes("ORGANIZATIONTYPE_UPDATE") ||
                privileges.includes("ORGANIZATIONTYPE_DELETE") ? (
                  <TableCell align="left" padding="normal">
                    Actions
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {types && types.length > 0 ? (
                types
                  .slice(pageNo * pageSize, pageNo * pageSize + pageSize)
                  .map((type, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={type.id}
                      >
                        <TableCell align="left">
                          {pageNo * pageSize + index + 1}.
                        </TableCell>
                        <TableCell align="left">{type.name}</TableCell>
                        <TableCell align="left">
                          {type.active ? (
                            <Chip label="Yes" color="success" />
                          ) : (
                            <Chip label="No" color="error" />
                          )}
                        </TableCell>
                        <TableCell align="left" sx={{ borderRadius: "5px" }}>
                          {type.iconFile ? (
                            <img
                              style={{
                                height: "150px",
                                width: "150px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                              src={type.iconFile.fileUrl}
                            />
                          ) : (
                            "No Image"
                          )}
                        </TableCell>
                        <TableCell padding="none" align="left">
                          <Box mr={2}>
                            {privileges.includes("ORGANIZATIONTYPE_UPDATE") && (
                              <IconButton
                                aria-label="details"
                                size="large"
                                onClick={() => goEditPage(type.id)}
                              >
                                <Edit color="primary" />
                              </IconButton>
                            )}
                            {privileges.includes("ORGANIZATIONTYPE_DELETE") && (
                              <IconButton
                                aria-label="delete"
                                size="large"
                                onClick={() => handleDelete(type.id)}
                              >
                                <Delete sx={{ color: "#d93232" }} />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    No Organization Types Found!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={types.length}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        title="Delete Organization Type"
        text="Are you sure that you want to delete this type?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteType}
      />
    </div>
  );
}

function OrganizationTypeList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Organization Types" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Organization Types
          </Typography>
        </Grid>
        {privileges.includes("ORGANIZATIONTYPE_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/organization/type/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Type
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Organization Types</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default OrganizationTypeList;
