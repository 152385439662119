import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  InputLabel,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload, Delete } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { sectionCreatePost } from "../../../requests/section";
import { fileUploadPagePost } from "../../../requests/file";
import { getToastError, getToastSuccess } from "../../../utils/toasts";
import { pageByIdGet, pageUpdatePut } from "../../../requests/page";
import "../../../styles/quil.css";
// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
const fonts = ["Sans Serif", "Oregano", "Inter"];
Font.whitelist = fonts;
Quill.register(Font, true);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function CreateForm() {
  const navigate = useNavigate();
  const { pageId } = useParams();
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [order, setOrder] = useState(0);
  const [tag, setTag] = useState("");
  const [title, setTitle] = useState("");
  const [prefix, setPrefix] = useState("");
  const [layout, setLayout] = useState("");
  const [buttonText1, setButtonText1] = useState("");
  const [buttonLink1, setButtonLink1] = useState("");
  const [buttonText2, setButtonText2] = useState("");
  const [buttonLink2, setButtonLink2] = useState("");

  //page details
  const [selectedSections, setSelectedSections] = useState([]);

  const [pageDescription, setPageDescription] = useState("");
  const [pageSlug, setPageSlug] = useState("");
  const [pageTag, setPageTag] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [pageSubTitle, setPageSubTitle] = useState("");

  const inputFile = useRef(null);
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);

  const [loading, setLoading] = useState(false);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"], // toggled buttons

      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ size: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [
        {
          color: [
            "#0B695E",
            "#219B89",
            "#84EFAF",
            "#C1F4D5",
            "#0C4EAD",
            "#00243D",
            "#C7FFDE",
            "#043438",
            "#97C6FF",
            "#4292E2",
            "#F7F9FC",
            "#E4E4E4",
            "#838B90",
            "#586269",
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
          ],
        },
      ], // dropdown with defaults from theme
      [
        {
          font: Font.whitelist,
        },
      ],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "color",
  ];

  useEffect(() => {
    getPageDetails();
  }, []);

  const getPageDetails = async () => {
    setLoading(true);
    const responses = await pageByIdGet(pageId);
    try {
      setLoading(false);

      if (responses.success) {
        const post = responses.data;
        setPageTitle(post.title);
        setPageSubTitle(post.subTitle);
        setPageDescription(post.description);
        setPageSlug(post.slug);
        setPageTag(post.tag);
        let sections = post.sections.map((sec) => sec.id);
        setSelectedSections(sections);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "prefix":
        setPrefix(value);
        break;
      case "tag":
        setTag(value);
        break;
      case "order":
        setOrder(value);
        break;
      case "layout":
        setLayout(value);
        break;
      case "content":
        setContent(value);
        break;
      case "buttonText1":
        setButtonText1(value);
        break;
      case "buttonLink1":
        setButtonLink1(value);
        break;
      case "buttonText2":
        setButtonText2(value);
        break;
      case "buttonLink2":
        setButtonLink2(value);
        break;
      default:
        break;
    }
  };

  const handleChangeEditor = (value) => {
    setDescription(value);
  };

  const handleChangeFile = (e) => {
    if (e.target.files[0] != null) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    inputFile.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImage("");
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (file) {
      let formData = new FormData();
      formData.append("file", file);
      const response = await fileUploadPagePost(formData, "SECTION_IMAGE");

      try {
        setLoading(false);

        if (response.success) {
          handleCreate(response.data.id);
        } else {
          getToastError(response.message);
        }
      } catch (error) {
        setLoading(false);
        getToastError(error.response.data.message);
      }
    } else {
      handleCreate(null);
    }
  };

  const handleCreate = async (id) => {
    setLoading(true);
    const postData = {
      imageFileItemId: id,
      content,
      description,
      title,
      tag,
      prefix,
      order,
      layout,
      buttonText1,
      buttonLink1,
      buttonText2,
      buttonLink2,
    };
    const responses = await sectionCreatePost(postData);
    try {
      if (responses.success) {
        getToastSuccess(responses.message);
        handleUpdatePage(responses.data.id);
      } else {
        setLoading(false);
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleUpdatePage = async (sectionId) => {
    // var sectionIdArray = selectedSections.map(function (sec) {
    //   return sec.id;
    // });
    let selectedSection = [...selectedSections];
    selectedSection.push(sectionId);
    const putData = {
      pageId,
      sectionIds: selectedSection,
      description: pageDescription,
      title: pageTitle,
      subTitle: pageSubTitle,
      tag: pageTag,
      slug: pageSlug,
    };
    const responses = await pageUpdatePut(putData);
    try {
      setLoading(false);
      if (responses.success) {
        navigate(`/page-management/section/list/${pageId}`);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Create
        </Typography>
        <Grid justifyContent="space-between" container spacing={2}>
          {pageTitle && (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                gutterBottom
                display="inline"
                color="secondary"
              >
                Page Title: {pageTitle}
              </Typography>
            </Grid>
          )}
          {pageTag && (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                gutterBottom
                display="inline"
                color="secondary"
              >
                Page Tag: {pageTag}
              </Typography>
            </Grid>
          )}
        </Grid>
        <InputLabel sx={{ mt: 2, mb: 1 }}>Image</InputLabel>
        <Grid container>
          <Grid item xs={12}>
            {file ? <img style={{ height: "30vh" }} src={image} /> : ""}
          </Grid>
          <Grid item xs={12}>
            {file ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={() => handleUpload()}
                >
                  <CloudUpload mr={2} /> Change
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  color="error"
                  component="span"
                  onClick={() => handleRemove()}
                >
                  <Delete mr={2} /> Remove
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={() => handleUpload()}
              >
                <CloudUpload mr={2} /> Upload
              </Button>
            )}
          </Grid>
        </Grid>

        <input
          type="file"
          id="file"
          accept="image/*"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={handleChangeFile}
          onClick={(event) => {
            event.target.value = null;
          }}
        />

        <TextField
          fullWidth
          margin="normal"
          id="title"
          name="title"
          label="Title"
          required
          variant="outlined"
          value={title}
          onChange={handleChange}
        />

        <Grid container spacing={2}>
          {/* <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              id="prefix"
              name="prefix"
              label="Prefix"
              variant="outlined"
              value={prefix}
              onChange={handleChange}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              margin="normal"
              id="tag"
              name="tag"
              label="Tag"
              variant="outlined"
              value={tag}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              margin="normal"
              id="order"
              name="order"
              label="Order"
              variant="outlined"
              value={order}
              error={order < 0}
              helperText={order < 0 ? "Order cannot be negative" : ""}
              onChange={handleChange}
            />
          </Grid>
          {/* 
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              id="layout"
              name="layout"
              label="Layout"
              variant="outlined"
              value={layout}
              onChange={handleChange}
            />
          </Grid> */}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              id="buttonText1"
              name="buttonText1"
              label="Button Text 1"
              variant="outlined"
              value={buttonText1}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              id="buttonLink1"
              name="buttonLink1"
              label="Button Link 1"
              variant="outlined"
              value={buttonLink1}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              id="buttonText2"
              name="buttonText2"
              label="Button Text 2"
              variant="outlined"
              value={buttonText2}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              id="buttonLink2"
              name="buttonLink2"
              label="Button Link 2"
              variant="outlined"
              value={buttonLink2}
              onChange={handleChange}
            />
          </Grid>

          <TextField
            fullWidth
            margin="normal"
            id="content"
            name="content"
            label="Short Description"
            variant="outlined"
            value={content}
            onChange={handleChange}
          />
        </Grid>
        <InputLabel sx={{ mt: 2, mb: 1 }}>Long Description</InputLabel>
        <ReactQuill
          className="ql-editor"
          id={"quill"}
          value={description}
          modules={modules}
          formats={formats}
          placeholder="Write Description..."
          onChange={handleChangeEditor}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={title === "" || order < 0 || tag === ""}
        >
          Create
        </LoadingButton>
        <Button
          sx={{ mt: 3, ml: 1 }}
          onClick={() => navigate(`/page-management/section/list/${pageId}`)}
        >
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function SectionCreate() {
  const { pageId } = useParams();
  return (
    <React.Fragment>
      <Helmet title="Section Create" />

      <Typography variant="h3" gutterBottom display="inline">
        Section Create
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link
          component={NavLink}
          to={`/page-management/section/list/${pageId}`}
        >
          Sections
        </Link>
        <Typography> Create</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CreateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SectionCreate;
