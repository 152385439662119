import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { getToastError, getToastSuccess } from "../../../utils/toasts";
import { glossaryByIdGet, glossaryUpdatePut } from "../../../requests/glossary";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function UpdateForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getGlossaryDetails();
  }, []);

  const getGlossaryDetails = async () => {
    setLoading(true);
    const responses = await glossaryByIdGet(id);
    try {
      setLoading(false);

      if (responses.success) {
        const glossary = responses.data;
        setDescription(glossary.description);
        setName(glossary.name);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "description":
        setDescription(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const postData = {
      id,
      description,
      name: name.charAt(0).toUpperCase() + name.slice(1),
    };
    const responses = await glossaryUpdatePut(postData);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate("/glossary/list");
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Update
        </Typography>

        <TextField
          fullWidth
          margin="normal"
          id="name"
          name="name"
          label="Name"
          required
          variant="outlined"
          value={name}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          multiline
          rows={3}
          required
          margin="normal"
          id="description"
          name="description"
          label="Description"
          variant="outlined"
          // error={description.length > 255}
          // helperText={
          //   description.length > 255
          //     ? "Limit exceeds(Maximum 255 character"
          //     : "Character limit: Maximum 255"
          // }
          value={description}
          onChange={handleChange}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={name === "" || description.length === 0}
        >
          Update
        </LoadingButton>
        <Button
          sx={{ mt: 3, ml: 1 }}
          onClick={() => navigate("/glossary/list")}
        >
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function GlossaryUpdate() {
  return (
    <React.Fragment>
      <Helmet title="Glossary Update" />

      <Typography variant="h3" gutterBottom display="inline">
        Glossary Update
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/glossary/list">
          Glossaries
        </Link>
        <Typography> Update</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <UpdateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default GlossaryUpdate;
