import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import "../../../styles/quil.css";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  InputLabel,
  Alert,
  Autocomplete,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload, Delete } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { subTitleValidate } from "../../../validation/validation";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { filePost } from "../../../requests/file";
import {
  categoryAllGet,
  categoryByIdGet,
  categoryUpdatePost,
  categoryUpdatePut,
} from "../../../requests/category";
import { LoadingButton } from "@mui/lab";
import { blogAllGet } from "../../../requests/blog";
import { getToastError, getToastSuccess } from "../../../utils/toasts";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function UpdateForm() {
  const navigate = useNavigate();
  const { id, uuid } = useParams();
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [inputValueCategory, setInputValueCategory] = useState("");
  const [parentCategoryId, setParentCategoryId] = useState(0);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const inputFile = useRef(null);
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllBlogs();
    getCategoryDetails();
  }, []);

  useEffect(() => {
    if (uuid !== "") {
      getAllCategories();
    }
  }, [uuid, inputValueCategory]);

  useEffect(() => {
    if (categories.length > 0 && parentCategoryId !== 0) {
      let categoryIndex = categories.findIndex(
        (cat) => cat.id === parentCategoryId
      );
      if (categoryIndex !== -1) {
        setSelectedCategory(categories[categoryIndex]);
      }
    }
  }, [parentCategoryId, categories]);

  const getAllBlogs = async () => {
    setLoading(true);
    const responses = await blogAllGet();
    try {
      setLoading(false);

      if (responses.success) {
        setBlogs(responses.data);
        let blogIndex = responses.data.findIndex((blog) => blog.uuid === uuid);
        if (blogIndex !== -1) {
          setSelectedBlog(responses.data[blogIndex]);
        }
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllCategories = async () => {
    setLoading(true);
    const responses = await categoryAllGet({
      pageNo: 0,
      pageSize: 500,
      title: inputValueCategory.length > 0 ? inputValueCategory : null,
      blogUUID: uuid,
    });
    try {
      setLoading(false);

      if (responses.success) {
        setCategories(responses.data.content);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getCategoryDetails = async () => {
    setLoading(true);
    const responses = await categoryByIdGet(id, uuid);
    try {
      setLoading(false);

      if (responses.success) {
        const category = responses.data;
        setTitle(category.title);
        setSubTitle(category.subTitle);
        setDescription(category.description);
        if (category.parentCategoryId) {
          setParentCategoryId(category.parentCategoryId);
        }
        if (category.image) {
          setFile(category.image);
          setImage(category.image.fileUrl);
        }
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "subTitle":
        setSubTitle(value);
        break;
      default:
        break;
    }
  };

  const handleChangeEditor = (value) => {
    setDescription(value);
  };

  const handleChangeFile = (e) => {
    if (e.target.files[0] != null) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    inputFile.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImage("");
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (file) {
      if (file.id) {
        handleUpdate(file.id);
      } else {
        let formData = new FormData();
        formData.append("file", file);
        const response = await filePost(formData, "CATEGORY_IMAGE", uuid);

        try {
          setLoading(false);
          if (response.success) {
            handleUpdate(response.data.id);
          } else {
            getToastError(response.message);
          }
        } catch (error) {
          setLoading(false);
          getToastError(error.response.data.message);
        }
      }
    } else {
      handleUpdate(null);
    }
  };

  const handleUpdate = async (fileId) => {
    setLoading(true);
    const postData = {
      categoryId: id,
      parentCategoryId: parentCategoryId === 0 ? null : parentCategoryId,
      blogUUID: uuid,
      description,
      imageFileId: fileId,
      title,
      subTitle,
    };
    const responses = await categoryUpdatePut(postData);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate({
          pathname: `/blog/category/list`,
          search: `?blogUUID=${uuid}`,
        });
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Update
        </Typography>
        <InputLabel sx={{ mt: 2, mb: 1 }}>Image</InputLabel>
        <Grid container>
          <Grid item xs={12}>
            {file ? <img style={{ height: "30vh" }} src={image} /> : ""}
          </Grid>
          <Grid item xs={12}>
            {file ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={() => handleUpload()}
                >
                  <CloudUpload mr={2} /> Change
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  color="error"
                  component="span"
                  onClick={() => handleRemove()}
                >
                  <Delete mr={2} /> Remove
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={() => handleUpload()}
              >
                <CloudUpload mr={2} /> Upload
              </Button>
            )}
          </Grid>
        </Grid>

        <input
          type="file"
          id="file"
          accept="image/*"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={handleChangeFile}
          onClick={(event) => {
            event.target.value = null;
          }}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={blogs}
              value={selectedBlog}
              disabled
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label="Blog*"
                  placeholder="Select Blog"
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6}>
            <Autocomplete
              disabled
              // disabled={uuid.length === 0}
              disablePortal
              id="combo-box-demo"
              value={parentCategoryId !== 0 ? selectedCategory : null}
              // onChange={(event, newValue) => {
              //   if(newValue){
              //     setSelectedCategory(newValue);
              //     setParentCategoryId(newValue.id);
              //   }
              //   else{
              //     setSelectedCategory({});
              //     setParentCategoryId(0);
              //   }
              // }}
              // onInputChange={(event, newInputValue) => {
              //   setInputValueCategory(newInputValue);
              // }}
              options={categories}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label="Select Category"
                />
              )}
            />
          </Grid> */}
        </Grid>

        <TextField
          fullWidth
          margin="normal"
          id="title"
          name="title"
          label="Title*"
          variant="outlined"
          value={title}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          margin="normal"
          id="subTitle"
          name="subTitle"
          label="Sub-Title*"
          variant="outlined"
          value={subTitle}
          onChange={handleChange}
        />

        <InputLabel sx={{ mt: 2, mb: 1 }}>Description</InputLabel>
        <ReactQuill
          className="ql-editor"
          value={description}
          placeholder="Write Description..."
          onChange={handleChangeEditor}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={title === "" || subTitle === "" || uuid === ""}
        >
          Update
        </LoadingButton>
        <Button
          sx={{ mt: 3, ml: 1 }}
          onClick={() =>
            navigate({
              pathname: `/blog/category/list`,
              search: `?blogUUID=${uuid}`,
            })
          }
        >
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function CategoryUpdate() {
  return (
    <React.Fragment>
      <Helmet title="Category Update" />

      <Typography variant="h3" gutterBottom display="inline">
        Category Update
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/blog/category/list">
          Categories
        </Link>
        <Typography> Update</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <UpdateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CategoryUpdate;
