import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  Alert,
  CircularProgress,
  TextField,
  Card,
  CardContent,
  Autocomplete,
  Menu,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
} from "@mui/material";
import { Add as AddIcon, Delete, Edit, Search } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { scholarAllGet, scholarByIdDelete } from "../../requests/scholar";
import { nonProfitAllGet } from "../../requests/nonProfit";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { useSelector } from "react-redux";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();

  const [scholarOf, setScholarOf] = useState("All");
  const [nonProfits, setNonProfits] = useState([]);
  const [selectedNonProfit, setSelectedNonProfit] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [nonProfitId, setNonProfitId] = useState(-1);

  const [scholars, setScholars] = useState([]);

  const [name, setName] = useState("");
  const [anchorName, setAnchorName] = useState(null);
  const openName = Boolean(anchorName);

  const [email, setEmail] = useState("");
  const [anchorEmail, setAnchorEmail] = useState(null);
  const openEmail = Boolean(anchorEmail);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [scholarId, setScholarId] = useState(0);

  useEffect(() => {
    getAllNonProfits();
  }, []);

  useEffect(() => {
    getAllScholars();
  }, [pageNo, pageSize, name, email, nonProfitId]);

  const getAllNonProfits = async () => {
    setLoading(true);
    const responses = await nonProfitAllGet({ pageNo: 0, pageSize: 500 });
    try {
      setLoading(false);

      if (responses.success) {
        setNonProfits(responses.data.content);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllScholars = async () => {
    setLoading(true);
    const responses = await scholarAllGet({
      pageNo,
      pageSize,
      name: name.length > 0 ? name : null,
      email: email.length > 0 ? email : null,
      nonProfitId: nonProfitId,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setScholars(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "name":
        setAnchorName(event.currentTarget);
        break;
      case "email":
        setAnchorEmail(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "name":
        setAnchorName(null);
        break;
      case "email":
        setAnchorEmail(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "scholarOf":
        setScholarOf(value);
        if (value === "All") {
          setNonProfitId(-1);
        } else if (value === "Admin") {
          setNonProfitId(0);
        }
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (scholarId) => {
    setConfirmationOpen(true);
    setScholarId(scholarId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setScholarId(0);
  };

  const deleteScholar = async () => {
    setLoading(true);
    const responses = await scholarByIdDelete(scholarId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllScholars();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id) => {
    navigate(`/scholar/update/${id}`);
  };

  return (
    <div>
      <Card mb={6}>
        <CardContent pb={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <InputLabel id="scholarOf">Scholars Of</InputLabel>
              <Select
                fullWidth
                labelId="scholarOf"
                id="scholarOf"
                name="scholarOf"
                value={scholarOf}
                label="Scholar Of"
                size="small"
                margin="normal"
                onChange={handleChange}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Non-Profit">Non-Profit</MenuItem>
              </Select>
            </Grid>
            {scholarOf === "Non-Profit" && (
              <Grid item xs={12} sm={6} md={4} lg={3} sx={{ mt: 1 }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedNonProfit(newValue);
                      setNonProfitId(newValue.id);
                    } else {
                      setSelectedNonProfit({});
                      setNonProfitId(-1);
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  options={nonProfits}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      size="small"
                      label="Filter By Non-Profit"
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
        <Paper>
          <TableContainer>
            <Grid
              container
              display={loading ? "flex" : "none"}
              justifyContent="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    Sl. No.
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Name{" "}
                    <IconButton onClick={(e) => handleClick(e, "name")}>
                      <Search
                        fontSize="small"
                        color={name.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorName}
                    open={openName}
                    onClose={() => handleCloseMenu("name")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="name"
                      name="name"
                      value={name}
                      onChange={handleChange}
                      placeholder="Search Name"
                    />
                  </Menu>
                  <TableCell align="left" padding="normal">
                    Email
                    <IconButton onClick={(e) => handleClick(e, "email")}>
                      <Search
                        fontSize="small"
                        color={email.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEmail}
                    open={openEmail}
                    onClose={() => handleCloseMenu("email")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      placeholder="Search Email"
                    />
                  </Menu>
                  <TableCell align="left" padding="normal">
                    Image
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Created By
                  </TableCell>
                  {privileges.includes("SCHOLAR_UPDATE") ||
                  privileges.includes("SCHOLAR_DELETE") ? (
                    <TableCell align="left" padding="normal">
                      Actions
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {scholars && scholars.length > 0 ? (
                  scholars.map((scholar, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={scholar.id}
                      >
                        <TableCell align="left">
                          {pageNo * pageSize + index + 1}.
                        </TableCell>
                        <TableCell align="left">{scholar.name}</TableCell>
                        <TableCell align="left">{scholar.email}</TableCell>
                        <TableCell align="left" sx={{ borderRadius: "5px" }}>
                          {scholar.image ? (
                            <img
                              style={{
                                height: "150px",
                                width: "150px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                              src={scholar.image.fileUrl}
                            />
                          ) : (
                            "No Image"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {scholar.nonProfitId ? "Nonprofit" : "Admin"}
                        </TableCell>
                        <TableCell padding="none" align="left">
                          <Box mr={2}>
                            {privileges.includes("SCHOLAR_UPDATE") && (
                              <IconButton
                                aria-label="details"
                                size="large"
                                onClick={() => goEditPage(scholar.id)}
                              >
                                <Edit color="primary" />
                              </IconButton>
                            )}
                            {privileges.includes("SCHOLAR_UPDATE") && (
                              <IconButton
                                aria-label="delete"
                                size="large"
                                onClick={() => handleDelete(scholar.id)}
                              >
                                <Delete sx={{ color: "#d93232" }} />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>No Scholars Found!</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={pageSize}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>
      <ConfirmationDialog
        title="Delete Scholar"
        text="Are you sure that you want to delete this scholar?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteScholar}
      />
    </div>
  );
}

function ScholarList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Scholars" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Scholars
          </Typography>
        </Grid>
        {privileges.includes("SCHOLAR_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/scholar/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Scholar
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Scholars</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ScholarList;
