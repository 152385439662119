import axios from "axios";
import { basePath, headers } from "../utils/http";

export const postAllGet = async (params) => {
    try {
      const response = await axios.get(`${basePath()}/blog/post/all`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const postByIdGet = async (id, blogUUID) => {
    try {
      const response = await axios.get(`${basePath()}/blog/post/id/${id}?blogUUID=${blogUUID}`, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

export const postCreatePost = async (data) => {
  try {
    const response = await axios.post(`${basePath()}/blog/post/admin/create`, data, headers());
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
  }
};

export const postUpdatePut = async (data) => {
  try {
    const response = await axios.put(`${basePath()}/blog/post/admin/update`, data, headers());
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
  }
};


export const postByIdDelete = async (id, blogUUID) => {
  try {
    const response = await axios.delete(`${basePath()}/blog/post/admin/${id}?blogUUID=${blogUUID}`, headers());
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
  }
};