import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  CircularProgress,
  Chip,
  TextField,
  CardContent,
  Card,
  Breadcrumbs,
} from "@mui/material";
import { Add as AddIcon, Delete, Edit } from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  verificationDocAllGet,
  verificationDocByIdDelete,
} from "../../requests/varificationDoc";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { useSelector } from "react-redux";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const [docs, setDocs] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const [searchedDocs, setSearchedDocs] = useState([]);

  const [name, setName] = useState("");

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [docId, setDocId] = useState(0);

  useEffect(() => {
    getAllDocs();
  }, []);

  const getAllDocs = async () => {
    setLoading(true);
    const responses = await verificationDocAllGet();
    try {
      setLoading(false);

      if (responses.success) {
        responses.data.sort((a, b) => (a.id < b.id ? 1 : -1));
        setDocs(responses.data);
        setSearchedDocs(responses.data);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        if (value) {
          let searchResult = docs.filter((doc) =>
            doc.name.toLowerCase().includes(value.toLowerCase())
          );
          setSearchedDocs(searchResult);
        } else {
          setSearchedDocs(docs);
        }
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };
  const handleDelete = (productId) => {
    setConfirmationOpen(true);
    setDocId(productId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setDocId(0);
  };

  const deleteDoc = async () => {
    setLoading(true);
    const responses = await verificationDocByIdDelete(docId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        getAllDocs();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id) => {
    navigate(`/verification-docs/update/${id}`);
  };

  return (
    <div>
      <Card mb={6}>
        <CardContent pb={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                size="small"
                id="name"
                name="name"
                value={name}
                onChange={handleChange}
                placeholder="Search by Name"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Paper>
          <TableContainer>
            <Grid
              container
              display={loading ? "flex" : "none"}
              justifyContent="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    Sl. No.
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Name{" "}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Is Required?
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Has Expiration Date?
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Is Active?
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Icon
                  </TableCell>
                  {privileges.includes("VERIFICATIONDOC_UPDATE") ||
                  privileges.includes("VERIFICATIONDOC_DELETE") ? (
                    <TableCell align="left" padding="normal">
                      Actions
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {searchedDocs && searchedDocs.length > 0 ? (
                  searchedDocs
                    .slice(pageNo * pageSize, pageNo * pageSize + pageSize)
                    .map((doc, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={doc.id}
                        >
                          <TableCell align="left">
                            {pageNo * pageSize + index + 1}.
                          </TableCell>
                          <TableCell align="left">{doc.name}</TableCell>
                          <TableCell align="left">
                            {doc.required ? (
                              <Chip label="Yes" color="success" />
                            ) : (
                              <Chip label="No" color="error" />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {doc.expirationNeeded ? (
                              <Chip label="Yes" color="success" />
                            ) : (
                              <Chip label="No" color="error" />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {doc.active ? (
                              <Chip label="Yes" color="success" />
                            ) : (
                              <Chip label="No" color="error" />
                            )}
                          </TableCell>
                          <TableCell align="left" sx={{ borderRadius: "5px" }}>
                            {doc.iconFile ? (
                              <img
                                style={{
                                  height: "150px",
                                  width: "150px",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                }}
                                src={doc.iconFile.fileUrl}
                              />
                            ) : (
                              "No Icon"
                            )}
                          </TableCell>
                          <TableCell padding="none" align="left">
                            <Box mr={2}>
                              {privileges.includes(
                                "VERIFICATIONDOC_UPDATE"
                              ) && (
                                <IconButton
                                  aria-label="details"
                                  size="large"
                                  onClick={() => goEditPage(doc.id)}
                                >
                                  <Edit color="primary" />
                                </IconButton>
                              )}
                              {privileges.includes(
                                "VERIFICATIONDOC_DELETE"
                              ) && (
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  onClick={() => handleDelete(doc.id)}
                                >
                                  <Delete sx={{ color: "#d93232" }} />
                                </IconButton>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      No Verification Docs Found!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={searchedDocs.length}
            rowsPerPage={pageSize}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>
      <ConfirmationDialog
        title="Delete Verification Doc"
        text="Are you sure that you want to delete this verification doc?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteDoc}
      />
    </div>
  );
}

function VerificationDocsList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Verification Docs" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Verification Docs
          </Typography>
        </Grid>
        {privileges.includes("VERIFICATIONDOC_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/verification-docs/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Verification Doc
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Verification Docs</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default VerificationDocsList;
