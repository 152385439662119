import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import authSlice from "./slices/auth";
import counterReducer from "./slices/counter";

// export const store = configureStore({
//   reducer: {
//     counter: counterReducer,
//     auth: authSlice.reducer
//   },
// });

const reducers = combineReducers({
  counter: counterReducer,
  auth: authSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
