import React, { useEffect, useState } from "react";
import {
  AssuredWorkload,
  ContactSupport,
  Dashboard,
  History,
  ManageAccounts,
  School,
  ShoppingBag,
  Subscriptions,
  Task,
} from "@mui/icons-material";
import {
  Users,
  Feather,
  File,
  Settings,
  User,
  FileText,
  HelpCircle,
} from "react-feather";
import { myDetailsGet } from "../../requests/user";
import DiscountIcon from "@mui/icons-material/Discount";

function DashboardItems() {
  const [privileges, setPrivileges] = useState([]);
  useEffect(() => {
    getCurrentUser();
  }, []);
  const getCurrentUser = async () => {
    const response = await myDetailsGet();
    if (response.success) {
      let user = response.data;
      if (user.roles && user.roles.length > 0) {
        let privilegeAll = user.roles[0].privileges;
        let privileges = privilegeAll.map((pri) => pri.name);
        setPrivileges(privileges);
      }
    }
  };
  useEffect(() => {}, [privileges]);

  const dashboardSection = [
    {
      href: "/private",
      icon: Dashboard,
      title: "Dashboard",
    },
  ];

  const profileSection = [
    {
      href: "/auth",
      icon: User,
      title: "Profile",
      children: [
        {
          href: "/profile",
          title: "Manage Personal Info",
        },
        {
          href: "/auth/change-password",
          title: "Change Password",
        },
      ],
    },
  ];

  const roleSection = [
    {
      href: "/role",
      icon: ManageAccounts,
      title: "Role Management",
      children: [
        {
          href: "/role/list",
          title: "Roles",
        },
      ],
    },
  ];

  const userSection = [
    {
      href: "/user",
      icon: Users,
      title: "User Management",
      children: [
        {
          href: "/user/list/ADMIN",
          title: "Admins",
        },
        {
          href: "/user/list/NON_PROFIT",
          title: "Non-Profit Users",
        },
        {
          href: "/user/list/USER",
          title: "Users",
        },
      ],
    },
  ];

  const pageSection = [
    {
      href: "/page-management",
      icon: Settings,
      title: "Page Management",
      children: [
        {
          href: "/page-management/page/list",
          title: "Pages",
          hidden: !privileges.includes("CMS_READ"),
        },
        {
          href: "/page-management/testimonial/list",
          title: "Testimonials",
        },
        {
          href: "/glossary/list",
          title: "Glossaries",
        },
      ],
    },
  ];

  const legalDocSection = [
    {
      href: "/legal-docs",
      icon: FileText,
      title: "Document",
      children: [
        {
          href: "/legal-docs/list",
          title: "Legal Documents",
        },
      ],
    },
  ];

  const subscriptionPackagesSection = [
    {
      href: "/subscription-packages",
      icon: Subscriptions,
      title: "Subscriptions",
      children: [
        {
          href: "/subscription-packages/list",
          title: "Packages",
        },
        {
          href: "/coupon/list",
          title: "Coupons",
        },
      ],
    },
  ];

  const promoPackagesSection = [
    {
      href: "/promos",
      icon: DiscountIcon,
      title: "Manage Promos",
      children: [
        {
          href: "/promos/list",
          title: "Promos",
        },
      ],
    },
  ];

  const historySection = [
    {
      href: "/history",
      icon: History,
      title: "History",
      children: [
        {
          href: "/transaction-history",
          title: "Transactions",
          hidden: !privileges.includes("HISTORY_TRANSACTION_READ"),
        },
        {
          href: "/will-history",
          title: "Wills",
          hidden: !privileges.includes("HISTORY_WILL_READ"),
        },
        {
          href: "/bequest-history",
          title: "Bequests",
          hidden: !privileges.includes("HISTORY_BEQUEST_READ"),
        },
        {
          href: "/healthcare-directive-history",
          title: "Advance Healthcare Directives",
          hidden: !privileges.includes("HISTORY_HEALTH_CARE_READ"),
        },
        {
          href: "/donate-stock-history",
          title: "Donate Stock",
          hidden: !privileges.includes("HISTORY_STOCK_READ"),
        },
      ],
    },
  ];

  const verificationDocSection = [
    {
      href: "/verification-docs",
      icon: Task,
      title: "Verification Doc",
      children: [
        {
          href: "/verification-docs/list",
          title: "Configuration",
          hidden: !privileges.includes("VERIFICATIONDOC_READ"),
        },
        {
          href: "/verification-docs/all",
          title: "Non-Profit Uploaded Docs",
          hidden: !privileges.includes("UPLOADEDDOC_UPDATE"),
        },
      ],
    },
  ];

  const blogSection = [
    {
      href: "/blog",
      icon: Feather,
      title: "Blog Section",
      children: [
        {
          href: "/blog/list",
          title: "Blogs",
        },
        {
          href: "/blog/author/list",
          title: "Authors",
        },
        {
          href: "/blog/category/list",
          title: "Categories",
        },
        {
          href: "/blog/post/list",
          title: "Posts",
        },
      ],
    },
  ];
  const productSection = [
    {
      href: "/product",
      icon: ShoppingBag,
      title: "Product",
      children: [
        {
          href: "/product/list",
          title: "Products",
        },
      ],
    },
  ];

  const scholarSection = [
    {
      href: "/scholar",
      icon: School,
      title: "Manage Scholar",
      children: [
        {
          href: "/scholar/list",
          title: "Scholars",
        },
      ],
    },
  ];

  const organizationSection = [
    {
      href: "/non-profit-organization",
      icon: AssuredWorkload,
      title: "Organization",
      children: [
        {
          href: "/organization/type/list",
          title: "Organization Types",
        },
        {
          href: "/organization/non-profit/list",
          title: "Non-Profit Organizations",
        },
      ],
    },
  ];

  const inquirySection = [
    {
      href: "/inquiry",
      icon: HelpCircle,
      title: "Help Center",
      children: [
        {
          href: "/inquiry/list",
          title: "Inquiries",
        },
      ],
    },
  ];

  const marketingSection = [
    {
      href: "/communication",
      icon: ContactSupport,
      title: "Communication Center",
      children: [
        {
          href: "/contact/list",
          title: "Contacts",
        },
        {
          href: "/sent-email/list",
          title: "Sent-Emails",
        },
      ],
    },
  ];
  const navItems = [
    {
      title: "Dashboard",
      pages: dashboardSection,
      hidden: false,
    },
    {
      title: "Configuration",
      pages: profileSection,
      hidden: false,
    },
    {
      pages: roleSection,
      hidden: !privileges.includes("ROLE_READ"),
    },
    {
      pages: userSection,
      hidden: !privileges.includes("USER_READ"),
    },
    {
      pages: pageSection,
      hidden: !privileges.includes("CMS_READ"),
    },
    {
      pages: legalDocSection,
      hidden: !privileges.includes("LEGAL_DOC_READ"),
    },
    {
      pages: subscriptionPackagesSection,
      hidden: false,
    },
    {
      pages: promoPackagesSection,
      hidden: false,
    },
    {
      pages: historySection,
      hidden:
        !privileges.includes("HISTORY_WILL_READ") &&
        !privileges.includes("HISTORY_TRANSACTION_READ") &&
        !privileges.includes("HISTORY_BEQUEST_READ") &&
        !privileges.includes("HISTORY_HEALTH_CARE_READ") &&
        !privileges.includes("HISTORY_STOCK_READ"),
    },
    {
      title: "Contents",
      pages: verificationDocSection,
      hidden:
        !privileges.includes("VERIFICATIONDOC_READ") &&
        !privileges.includes("UPLOADEDDOC_UPDATE"),
    },
    {
      title:
        !privileges.includes("VERIFICATIONDOC_READ") &&
        !privileges.includes("UPLOADEDDOC_UPDATE")
          ? "Contents"
          : "",
      pages: blogSection,
      hidden: !privileges.includes("BLOG_READ"),
    },
    {
      title:
        !privileges.includes("VERIFICATIONDOC_READ") &&
        !privileges.includes("UPLOADEDDOC_UPDATE") &&
        !privileges.includes("BLOG_READ")
          ? "Contents"
          : "",
      pages: organizationSection,
      hidden: false,
    },
    {
      pages: productSection,
      hidden: false,
    },
    {
      pages: scholarSection,
      hidden: false,
    },
    {
      pages: inquirySection,
      hidden: !privileges.includes("INQUIRY_ALL"),
    },
    {
      pages: marketingSection,
      hidden: !privileges.includes("MARKETING_ALL"),
    },
  ];
  return navItems;
}
export default DashboardItems;
