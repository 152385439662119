import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  Alert,
  CircularProgress,
  TextField,
  Card,
  CardContent,
  Autocomplete,
  Menu,
  Chip,
  MenuItem,
} from "@mui/material";
import { Add as AddIcon, Edit, Search } from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  nonProfitAllGet,
  nonProfitUpdateStatusByAdminPatch,
} from "../../requests/nonProfit";
import { organizationTypeAllGet } from "../../requests/organizationType";
import NonProfitAdminUpdate from "../../components/non-profit/NonProfitAdminUpdate";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import moment from "moment";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable() {
  const navigate = useNavigate();

  const [nonProfits, setNonProfits] = useState([]);

  const [name, setName] = useState("");
  const [anchorName, setAnchorName] = useState(null);
  const openName = Boolean(anchorName);

  const [types, setTypes] = useState([]);
  const [selectedOrganizationType, setSelectedOrganizationType] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [organizationTypeId, setOrganizationTypeId] = useState(0);

  const [isFeatured, setIsFeatured] = useState("");
  const [anchorIsFeatured, setAnchorIsFeatured] = useState(null);
  const openIsFeatured = Boolean(anchorIsFeatured);

  const [isActive, setIsActive] = useState("");
  const [anchorIsActive, setAnchorIsActive] = useState(null);
  const openIsActive = Boolean(anchorIsActive);

  const [isVerified, setIsVerified] = useState("");
  const [anchorIsVerified, setAnchorIsVerified] = useState(null);
  const openIsVerified = Boolean(anchorIsVerified);

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [nonProfitId, setNonProfitId] = useState(0);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllTypes();
  }, []);

  useEffect(() => {
    getAllNonProfits();
  }, [
    pageNo,
    pageSize,
    name,
    isActive,
    isFeatured,
    isVerified,
    organizationTypeId,
  ]);

  const getAllTypes = async () => {
    setLoading(true);
    const responses = await organizationTypeAllGet({
      name: inputValue.length > 0 ? inputValue : null,
    });
    try {
      setLoading(false);

      if (responses.success) {
        setTypes(responses.data);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllNonProfits = async () => {
    setLoading(true);
    const responses = await nonProfitAllGet({
      pageNo,
      pageSize,
      name: name.length > 0 ? name : null,
      isFeatured: isFeatured.length > 0 ? isFeatured : null,
      isActive: isActive.length > 0 ? isActive : null,
      isVerified: isVerified.length > 0 ? isVerified : null,
      organizationTypeId: organizationTypeId !== 0 ? organizationTypeId : null,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setNonProfits(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "name":
        setAnchorName(event.currentTarget);
        break;
      case "isFeatured":
        setAnchorIsFeatured(event.currentTarget);
        break;
      case "isActive":
        setAnchorIsActive(event.currentTarget);
        break;
      case "isVerified":
        setAnchorIsVerified(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "name":
        setAnchorName(null);
        break;
      case "isFeatured":
        setAnchorIsFeatured(null);
        break;
      case "isActive":
        setAnchorIsActive(null);
        break;
      case "isVerified":
        setAnchorIsVerified(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "isFeatured":
        setIsFeatured(value);
        break;
      case "isActive":
        setIsActive(value);
        break;
      case "isVerified":
        setIsVerified(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleClose = () => {
    setUpdateDialogOpen(false);
    setNonProfitId(0);
  };

  const goEditPage = (nonProfit) => {
    setNonProfitId(nonProfit.id);
    setUpdateDialogOpen(true);

    // navigate(`/organization/non-profit/updatez/${id}`);
  };

  const updateNonProfitStatus = async (data) => {
    const responses = await nonProfitUpdateStatusByAdminPatch(data);
    try {
      if (responses.success) {
        getToastSuccess(responses.message);
        setUpdateDialogOpen(false);
        getAllNonProfits();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };

  return (
    <div>
      <Card mb={6}>
        <CardContent pb={1}>
          <Grid container>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedOrganizationType(newValue);
                    setOrganizationTypeId(newValue.id);
                  } else {
                    setSelectedOrganizationType({});
                    setOrganizationTypeId(0);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={types}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    size="small"
                    label="Filter by Organization Type"
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Paper>
          <TableContainer>
            <Grid
              container
              display={loading ? "flex" : "none"}
              justifyContent="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    Sl. No.
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Name
                    <IconButton onClick={(e) => handleClick(e, "name")}>
                      <Search
                        fontSize="small"
                        color={name.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorName}
                    open={openName}
                    onClose={() => handleCloseMenu("name")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="name"
                      name="name"
                      value={name}
                      onChange={handleChange}
                      placeholder="Search Name"
                    />
                  </Menu>
                  <TableCell align="left" padding="normal">
                    Type
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Creation Date
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Featured?
                    <IconButton onClick={(e) => handleClick(e, "isFeatured")}>
                      <Search
                        fontSize="small"
                        color={isFeatured.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorIsFeatured}
                    open={openIsFeatured}
                    onClose={() => handleCloseMenu("isFeatured")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem value={null} disabled>
                      Search is featured or not
                    </MenuItem>

                    <MenuItem
                      value={""}
                      sx={{
                        backgroundColor: isFeatured == "" ? "#219B89" : "",
                        color: isFeatured == "" ? "#fff" : "",
                        "&:hover": {
                          backgroundColor: "#219B89",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        setAnchorIsFeatured(null);
                        setIsFeatured("");
                      }}
                    >
                      All
                    </MenuItem>
                    <MenuItem
                      value={true}
                      sx={{
                        backgroundColor: isFeatured == "true" ? "#219B89" : "",
                        color: isFeatured == "true" ? "#fff" : "",
                        "&:hover": {
                          backgroundColor: "#219B89",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        setAnchorIsFeatured(null);
                        setIsFeatured("true");
                      }}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      value={false}
                      sx={{
                        backgroundColor: isFeatured == "false" ? "#219B89" : "",
                        color: isFeatured == "false" ? "#fff" : "",
                        "&:hover": {
                          backgroundColor: "#219B89",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        setAnchorIsFeatured(null);
                        setIsFeatured("false");
                      }}
                    >
                      No
                    </MenuItem>
                  </Menu>
                  <TableCell align="left" padding="normal">
                    Active?
                    <IconButton onClick={(e) => handleClick(e, "isActive")}>
                      <Search
                        fontSize="small"
                        color={isActive.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorIsActive}
                    open={openIsActive}
                    onClose={() => handleCloseMenu("isActive")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem value={null} disabled>
                      Search is active or not
                    </MenuItem>

                    <MenuItem
                      value={""}
                      sx={{
                        backgroundColor: isActive == "" ? "#219B89" : "",
                        color: isActive == "" ? "#fff" : "",
                        "&:hover": {
                          backgroundColor: "#219B89",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        setAnchorIsActive(null);
                        setIsActive("");
                      }}
                    >
                      All
                    </MenuItem>
                    <MenuItem
                      value={true}
                      sx={{
                        backgroundColor: isActive == "true" ? "#219B89" : "",
                        color: isActive == "true" ? "#fff" : "",
                        "&:hover": {
                          backgroundColor: "#219B89",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        setAnchorIsActive(null);
                        setIsActive("true");
                      }}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      value={false}
                      sx={{
                        backgroundColor: isActive == "false" ? "#219B89" : "",
                        color: isActive == "false" ? "#fff" : "",
                        "&:hover": {
                          backgroundColor: "#219B89",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        setAnchorIsActive(null);
                        setIsActive("false");
                      }}
                    >
                      No
                    </MenuItem>
                  </Menu>
                  <TableCell align="left" padding="normal">
                    Verified?
                    <IconButton onClick={(e) => handleClick(e, "isVerified")}>
                      <Search
                        fontSize="small"
                        color={isVerified.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorIsVerified}
                    open={openIsVerified}
                    onClose={() => handleCloseMenu("isVerified")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem value={null} disabled>
                      Search is verified or not
                    </MenuItem>

                    <MenuItem
                      value={""}
                      sx={{
                        backgroundColor: isVerified == "" ? "#219B89" : "",
                        color: isVerified == "" ? "#fff" : "",
                        "&:hover": {
                          backgroundColor: "#219B89",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        setAnchorIsVerified(null);
                        setIsVerified("");
                      }}
                    >
                      All
                    </MenuItem>
                    <MenuItem
                      value={true}
                      sx={{
                        backgroundColor: isVerified == "true" ? "#219B89" : "",
                        color: isVerified == "true" ? "#fff" : "",
                        "&:hover": {
                          backgroundColor: "#219B89",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        setAnchorIsVerified(null);
                        setIsVerified("true");
                      }}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      value={false}
                      sx={{
                        backgroundColor: isVerified == "false" ? "#219B89" : "",
                        color: isVerified == "false" ? "#fff" : "",
                        "&:hover": {
                          backgroundColor: "#219B89",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        setAnchorIsVerified(null);
                        setIsVerified("false");
                      }}
                    >
                      No
                    </MenuItem>
                  </Menu>
                  <TableCell align="left" padding="normal">
                    Image
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {nonProfits && nonProfits.length > 0 ? (
                  nonProfits.map((nonProfit, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={nonProfit.id}
                      >
                        <TableCell align="left">
                          {pageNo * pageSize + index + 1}.
                        </TableCell>
                        <TableCell align="left">{nonProfit.name}</TableCell>
                        <TableCell align="left">
                          {nonProfit.types.map((type) => {
                            return (
                              <Chip
                                sx={{ ml: 1, mt: 1 }}
                                label={type.name}
                                color="secondary"
                              />
                            );
                          })}
                        </TableCell>
                        <TableCell align="left">
                          {nonProfit.creationDateTimeStamp !== 0
                            ? `${moment
                                .unix(nonProfit.creationDateTimeStamp)
                                .format("Do MMMM YYYY")}`
                            : "No date found"}
                        </TableCell>
                        <TableCell align="left">
                          {nonProfit.featured ? (
                            <Chip label="Yes" color="success" />
                          ) : (
                            <Chip label="No" color="error" />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {nonProfit.active ? (
                            <Chip label="Yes" color="success" />
                          ) : (
                            <Chip label="No" color="error" />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {nonProfit.verified ? (
                            <Chip label="Yes" color="success" />
                          ) : (
                            <Chip label="No" color="error" />
                          )}
                        </TableCell>
                        <TableCell align="left" sx={{ borderRadius: "5px" }}>
                          {nonProfit.iconFile ? (
                            <img
                              style={{
                                height: "150px",
                                width: "150px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                              src={nonProfit.iconFile.fileUrl}
                            />
                          ) : (
                            "No Image"
                          )}
                        </TableCell>
                        <TableCell padding="none" align="left">
                          <Box mr={2}>
                            <IconButton
                              aria-label="details"
                              size="large"
                              onClick={() => goEditPage(nonProfit)}
                            >
                              <Edit color="primary" />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={9}>No Non-Profits Found!</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={pageSize}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>
      <NonProfitAdminUpdate
        open={updateDialogOpen}
        nonProfits={nonProfits}
        nonProfitId={nonProfitId}
        handleClose={handleClose}
        updateNonProfitStatus={updateNonProfitStatus}
      />
    </div>
  );
}

function NonProfitList() {
  return (
    <React.Fragment>
      <Helmet title="Non-Profit" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Non-Profit Organizations
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/private">
              Dashboard
            </Link>
            <Typography> Non-Profit Organizations</Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid item>
          <div>
          <Link component={NavLink} to="/organization/non-profit/create">
            <Button variant="contained" color="primary">
              <AddIcon />
              New Non-Profit
            </Button>
            </Link>
          </div>
        </Grid> */}
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default NonProfitList;
