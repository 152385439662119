import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

// import useAuth from "../../hooks/useAuth";
import { login, logout } from "../../redux/slices/auth";
import { myDetailsGet } from "../../requests/user";

let auth = {};

if (process.browser) {
  window.onbeforeunload = () => {
    localStorage.setItem("auth", JSON.stringify(auth));
  };
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
    // window.onscroll = function () {
    //   scrollFunction();
    // };
  }, []);

  const checkAuth = async () => {
    let token = localStorage.getItem("token");
    if (token != null && token.trim() != "") {
      const response = await myDetailsGet();

      try {
        if (response.success) {
          await dispatch(
            login({
              token: localStorage.getItem("token"),
              refreshToken: localStorage.getItem("refreshToken"),
              user: response.data,
              isLogged: true,
            })
          );
        } else {
          localStorage.clear();
          await dispatch(logout());
          navigate("/");
        }
      } catch (error) {
        navigate("/");
        await dispatch(logout());
        localStorage.clear();
        console.log("error", error);
      }
    }
  };

  if (!localStorage.getItem("token")) {
    return <Navigate to="/" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
