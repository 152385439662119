import axios from "axios";
import { basePath, headers } from "../utils/http";

export const loginPost = async (data) => {
    try {
      const response = await axios.post(`${basePath()}/auth/signin`, data);
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const signupAdminPost = async (data) => {
    try {
      const response = await axios.post(`${basePath()}/auth/admin/signup`, data, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };
  
  export const signUpPost = async (data) => {
    try {
      const response = await axios.post(`${basePath()}/auth/signup`, data);
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const resetPasswordPost = async (data) => {
    try {
      const response = await axios.post(`${basePath()}/auth/reset-password`, data);
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };


  export const resetPasswordVerifyPost = async (data) => {
    try {
      const response = await axios.post(`${basePath()}/auth/change-forgotten-password`, data);
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const changePasswordPost = async (data) => {
    try {
      const response = await axios.post(`${basePath()}/auth/changepassword`, data, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const sendOtpEmailPost = async (data) => {
    try {
      const response = await axios.post(`${basePath()}/auth/send-verification-email`, data);
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const verifyEmailPost = async (data) => {
    try {
      const response = await axios.post(`${basePath()}/auth/verify-email`, data, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };
  
  