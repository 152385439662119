import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Avatar, Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import { useNavigate } from "react-router-dom";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

function SignIn() {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/private");
    }
  }, []);

  return (
    <React.Fragment>
      {!localStorage.getItem("token") && (
        <>
          <Brand />
          <Wrapper>
            <Helmet title="Sign In" />
            {/* <BigAvatar alt="Lucy" src="/static/img/avatars/avatar-1.jpg" /> */}

            <Typography component="h1" variant="h3" align="center" gutterBottom>
              Sign In
            </Typography>
            <Typography component="h2" variant="body1" align="center">
              Sign in to your account to continue
            </Typography>

            <SignInComponent />
          </Wrapper>
        </>
      )}
    </React.Fragment>
  );
}

export default SignIn;
