import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Paper, Typography,  CardContent,
  Breadcrumbs,
  Card,
  CircularProgress,
  Divider,
  LinearProgress as MuiLinearProgress,
  Alert} from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";

import EmailVerifyComponent from "../../components/auth/EmailVerify";
import { useNavigate, useParams } from "react-router-dom";

import { spacing } from "@mui/system";
import { verifyEmailPost } from "../../requests/auth";
import { getToastSuccess } from "../../utils/toasts";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
const LinearProgress = styled(MuiLinearProgress)(spacing);

function EmailVerify() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    setTimeout(() => {
      onVerify();
    }, 5000);
  
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
    
  const onVerify = async() => {
    const postData = {
        tokenOtp: token,
    }
    const response = await verifyEmailPost(postData)
    try {
      if (response.success) {
        getToastSuccess(response.message);
        navigate("/");
      }
      else{
        const message = response.message;

        setError(message);
      }
    } catch (error) {
      const message = error.response.data.message || "Something went wrong";

      setError(message );
    }
  }
  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title="Verify Account" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Verify Account
        </Typography>
        { success.length > 0 ?
  <Alert mt={2} mb={1} severity="success">
 {success}
</Alert> :
        error.length > 0 ? 
 <Alert mt={2} mb={1} severity="error">
    {error}
  </Alert> 
          : <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Verifying
            </Typography>
            <Paper mt={3}>
              <LinearProgress my={2} variant="determinate" value={progress} />
         
            </Paper>
          </CardContent>
        </Card>
  }
        {/* <Typography component="h2" variant="body1" align="center">
          An OTP has been sent to your email. Please check and verify.
        </Typography> */}
        {/* <EmailVerifyComponent token={token}/> */}
      </Wrapper>
    </React.Fragment>
  );
}

export default EmailVerify;
