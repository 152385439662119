import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Add as AddIcon, Delete, Edit } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { blogAllGet, blogByIdDelete } from "../../../requests/blog";
import { getToastError, getToastSuccess } from "../../../utils/toasts";
import { ConfirmationDialog } from "../../../components/dialogs/ConfirmationDialog";
import { useSelector } from "react-redux";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [blogId, setBlogId] = useState(0);

  useEffect(() => {
    getAllBlogs();
  }, []);

  const getAllBlogs = async () => {
    setLoading(true);
    const responses = await blogAllGet();
    try {
      setLoading(false);

      if (responses.success) {
        responses.data.sort((a, b) => (a.id < b.id ? 1 : -1));
        setBlogs(responses.data);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (blogId) => {
    setConfirmationOpen(true);
    setBlogId(blogId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setBlogId(0);
  };

  const deleteBlog = async () => {
    setLoading(true);
    const responses = await blogByIdDelete(blogId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllBlogs();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (uuid, tag) => {
    if (tag) {
      navigate({
        pathname: `/blog/update/${uuid}`,
        search: `?tagName=${tag}`,
      });
    } else {
      navigate(`/blog/update/${uuid}`);
    }
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  Sl. No.
                </TableCell>
                <TableCell align="left" padding="normal">
                  {" "}
                  Title
                </TableCell>
                <TableCell align="left" padding="normal">
                  Sub-Title
                </TableCell>
                <TableCell align="left" padding="normal">
                  Tag
                </TableCell>
                {privileges.includes("BLOG_UPDATE") ||
                privileges.includes("BLOG_DELETE") ? (
                  <TableCell align="left" padding="normal">
                    Actions
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {blogs && blogs.length > 0 ? (
                blogs
                  .slice(pageNo * pageSize, pageNo * pageSize + pageSize)
                  .map((blog, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={blog.id}
                      >
                        <TableCell align="left">
                          {pageNo * pageSize + index + 1}.
                        </TableCell>
                        <TableCell align="left">{blog.title}</TableCell>
                        <TableCell align="left">{blog.subtitle}</TableCell>
                        <TableCell align="left">{blog.tag}</TableCell>
                        <TableCell padding="none" align="left">
                          <Box mr={2}>
                            {privileges.includes("BLOG_UPDATE") && (
                              <IconButton
                                aria-label="details"
                                size="large"
                                onClick={() => goEditPage(blog.uuid, blog.tag)}
                              >
                                <Edit color="primary" />
                              </IconButton>
                            )}
                            {privileges.includes("BLOG_DELETE") && (
                              <IconButton
                                aria-label="delete"
                                size="large"
                                onClick={() => handleDelete(blog.uuid)}
                              >
                                <Delete sx={{ color: "#d93232" }} />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>No Blogs Found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={blogs.length}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ConfirmationDialog
          title="Delete Blog"
          text="Are you sure that you want to delete this blog?"
          open={confirmationOpen}
          handleClose={handleClose}
          handleSubmit={deleteBlog}
        />
      </Paper>
    </div>
  );
}

function BlogList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Blogs" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Blogs
          </Typography>
        </Grid>
        {privileges.includes("BLOG_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/blog/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Blog
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Blogs</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default BlogList;
