import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Eye } from "react-feather";

import {
  Card as MuiCard,
  CardHeader,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { RemoveRedEye } from "@mui/icons-material";

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;


const DashboardTable = ({uploadedDocs, nonProfits, getNonProfitNameById}) => {

  const navigate = useNavigate();


 
  return(
  <Card mb={6}>
    <CardHeader
      action={
        <Stack direction={"row"} gap={2} sx={{cursor: "pointer"}} onClick={()=>navigate("/verification-docs/all")}>
      
          <RemoveRedEye color={"primary"} size="small" sx={{  verticalAlign: "middle", mt: 1}}/>
       
        <Typography color={"primary"} variant="subtitle1" ><b>View All</b></Typography>

        </Stack>
      }
      title="Verification Document Summary"
    />
    <Paper>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>
                    Sl. No.
                  </TableCell>
                  <TableCell>
                    {" "}
                    Non-Profit
                  </TableCell>
                  <TableCell>
                    Created By
                  </TableCell>
                  <TableCell>
                    Document Name
                  </TableCell>
                  <TableCell>
                    Expiration Date
                  </TableCell>
                  <TableCell>
                    Status
                  </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uploadedDocs.map((doc, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="doc">
                  {index+1}
                </TableCell>
                <TableCell>
                          {getNonProfitNameById(doc.nonprofitId)}
                        </TableCell>
                        <TableCell>
                          {doc.submissions && doc.submissions.length > 0
                            ? doc.submissions[0].createdBy
                            : ""}
                        </TableCell>
                        <TableCell>
                          {doc.verificationDoc && doc.verificationDoc.name
                            ? doc.verificationDoc.name
                            : ""}
                        </TableCell>
                        <TableCell>
                          {doc.verificationDoc &&
                          doc.verificationDoc.expirationNeeded
                            ? doc.submissions &&
                              doc.submissions[0].expiryDateTime !== 0
                              ? `${moment
                                  .unix(doc.submissions[0].expiryDateTime)
                                  .format("Do MMMM YYYY")}`
                              : "Has no expiry date"
                            : "Has no expiry date"}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={doc.verificationStatus}
                              color={doc.verificationStatus.toUpperCase() ===
                                  "PENDING" ? "warning" : "error"}
                                
                          />
                        </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </Paper>
  </Card>
  )
                          };

export default DashboardTable;
