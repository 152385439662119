import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Alert,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import styled from "styled-components/macro";

const Centered = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export default function NonProfitAdminUpdate(props) {
  const { open, nonProfits, nonProfitId } = props;
  const [active, setActive] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [text, setText] = useState("");
  const [variant, setVariant] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (nonProfits.length > 0) {
      let nonProfitIndex = nonProfits.findIndex(
        (non) => non.id === nonProfitId
      );
      if (nonProfitIndex !== -1) {
        setActive(nonProfits[nonProfitIndex].active);
        setFeatured(nonProfits[nonProfitIndex].featured);
      }
    }
  }, [nonProfitId]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "active":
        setActive(value);
        break;
      case "featured":
        setFeatured(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    // console.log();
    const postData = {
      active,
      featured,
      nonProfitId,
    };
    props.updateNonProfitStatus(postData);
  };

  return (
    <Dialog
      key={nonProfitId}
      fullScreen={fullScreen}
      open={open}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Configure Non Profit"}
      </DialogTitle>

      <DialogContent sx={{ minWidth: "350px" }}>
        {text.length > 0 ? (
          <Alert mt={2} mb={3} severity={variant}>
            {text}
          </Alert>
        ) : (
          ""
        )}
        <Centered>
          <FormControl sx={{ mt: 2 }}>
            {/* <InputLabel id="nonProfitId-label" >Non-Profit</InputLabel>
        <Select
          labelId="nonProfitId-label"
          id="nonProfitId"
          value={nonProfitId}
          label="Non-Profit"
          disabled
        >
            {nonProfits.map((profit)=>{
                return(
                    <MenuItem value={profit.id}>{profit.name}</MenuItem>
                )
            })}
        </Select> */}

            <FormLabel id="active" sx={{ marginTop: "10px" }}>
              Is Active?
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="active"
              name="active"
              value={active}
              onChange={handleChange}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>

            <FormLabel id="featured" sx={{ marginTop: "10px" }}>
              Is Featured?
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="featured"
              name="featured"
              value={featured}
              onChange={handleChange}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Centered>
      </DialogContent>

      <DialogActions>
        <Button
          mr={2}
          variant="contained"
          size={"small"}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
        <Button variant="text" size={"small"} onClick={props.handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
