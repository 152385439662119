import * as React from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

// import useAuth from "../../hooks/useAuth";
import { login, logout } from "../../redux/slices/auth";
import { useDispatch } from "react-redux";
import { supportTicektSessionDelete } from "../../requests/supportTicket";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const supportTickcetToken = localStorage.getItem("supportTicketSessionToken")
  // const { signOut } = useAuth();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const redirectToProfile = () => {
    navigate("/profile");
  };

  const handleSignOut = async () => {
    supportTicektSessionDelete(supportTickcetToken)
    .then( res => {
      
    })
    await dispatch(logout());
    localStorage.clear();
    navigate("/");
  };

  // const clearReduxStoreAndLocalStorage = async () => {
  //   localStorage.clear();
  //   await dispatch(
  //     login({
  //       token: "",
  //       refreshToken: "",
  //       user: {},
  //       isLogged: false,
  //     })
  //   );
  // };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {/* <MenuItem onClick={redirectToProfile}>Profile</MenuItem> */}
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
