import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  Menu,
  TextField,
  Chip,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { getToastError } from "../../utils/toasts";
import moment from "moment";
import { allHealthDirectiveGet, allProductsGet } from "../../requests/history";
import { NavLink, useNavigate } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable() {
  const navigate = useNavigate();
  const [healthcares, setHealthcares] = useState([]);

  const [willTag, setWillTag] = useState("all");
  const [products, setProducts] = useState([]);

  const [email, setEmail] = useState("");
  const [anchorEmail, setAnchorEmail] = useState(null);
  const openEmail = Boolean(anchorEmail);

  const [name, setName] = useState("");
  const [anchorName, setAnchorName] = useState(null);
  const openName = Boolean(anchorName);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    getAllHealthcares();
  }, [pageNo, pageSize, email, name]);

  const getAllProducts = async () => {
    setLoading(true);
    const responses = await allProductsGet();
    try {
      setLoading(false);

      if (responses.success) {
        setProducts(responses.data);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllHealthcares = async () => {
    setLoading(true);
    const responses = await allHealthDirectiveGet({
      page_no: pageNo,
      page_size: pageSize,
      email: email.length > 0 ? email : null,
      name: name.length > 0 ? name : null,
      sort_by: "id",
      asc_or_desc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setHealthcares(responses.data.content);
        setTotalItems(responses.data.totalElements);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "email":
        setAnchorEmail(event.currentTarget);
        break;
      case "name":
        setAnchorName(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "email":
        setAnchorEmail(null);
        break;
      case "name":
        setAnchorName(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "name":
        setName(value);
        break;

      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  return (
    <div>
      <Card mb={6}>
        <Paper>
          <TableContainer>
            <Grid
              container
              display={loading ? "flex" : "none"}
              justifyContent="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    Sl. No.
                  </TableCell>

                  <TableCell align="left" padding="normal">
                    {" "}
                    Name
                    <IconButton onClick={(e) => handleClick(e, "name")}>
                      <Search
                        fontSize="small"
                        color={name.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorName}
                    open={openName}
                    onClose={() => handleCloseMenu("name")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="name"
                      name="name"
                      value={name}
                      onChange={handleChange}
                      placeholder="Search Name"
                    />
                  </Menu>
                  <TableCell align="left" padding="normal">
                    {" "}
                    Email
                    <IconButton onClick={(e) => handleClick(e, "email")}>
                      <Search
                        fontSize="small"
                        color={email.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEmail}
                    open={openEmail}
                    onClose={() => handleCloseMenu("email")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      placeholder="Search Email"
                    />
                  </Menu>
                  <TableCell align="left" padding="normal">
                    Gender
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Creation Date
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {" "}
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {healthcares && healthcares.length > 0 ? (
                  healthcares.map((healthcare, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={healthcare.id}
                      >
                        <TableCell align="left">
                          {pageNo * pageSize + index + 1}.
                        </TableCell>
                        <TableCell align="left">{healthcare.name}</TableCell>
                        <TableCell align="left">{healthcare.email}</TableCell>
                        <TableCell align="left">{healthcare.gender}</TableCell>
                        <TableCell align="left">
                          {healthcare.creationDateTimeStamp !== 0
                            ? moment
                                .unix(healthcare.creationDateTimeStamp)
                                .format("Do MMMM YYYY, h:mm a")
                            : "No Date"}
                        </TableCell>
                        <TableCell align="left">
                          <Chip color="success" label="Completed" />
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      No Advance Healthcare Directive History Found!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={pageSize}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>
    </div>
  );
}

function HealthCareHistoryList() {
  return (
    <React.Fragment>
      <Helmet title="Will History" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Advance Healthcare Directive History
          </Typography>
        </Grid>
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Advance Healthcare Directive History</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default HealthCareHistoryList;
