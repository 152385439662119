import axios from "axios";
import { basePath, headers } from "../utils/http";

export const notificationAllGet = async (params) => {
  try {
    const response = await axios.get(`${basePath()}/api/notification/all`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const notificationMarkAsReadPost = async () => {
  try {
    const response = await axios.post(
      `${basePath()}/api/notification/mark-as-read`,
      null,
      headers()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const notificationUnreadCountGet = async () => {
  try {
    const response = await axios.get(
      `${basePath()}/api/notification/unread-counter`,
      headers()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};
