import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Link,
  CircularProgress,
  Breadcrumbs,
} from "@mui/material";
import parse from "html-react-parser";
import { spacing } from "@mui/system";
import { legalDocsAllGet } from "../../requests/legalDocs";
import { getToastError } from "../../utils/toasts";
import { useSelector } from "react-redux";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable() {
  const navigate = useNavigate();
  const [docs, setDocs] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllDocs();
  }, []);

  const getAllDocs = async () => {
    setLoading(true);
    const responses = await legalDocsAllGet();
    try {
      setLoading(false);

      if (responses.success) {
        setDocs(responses.data);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableBody>
              {docs && Object.keys(docs).length > 0 ? (
                <>
                  <TableRow>
                    <TableCell style={{ width: "30%" }} align="left">
                      <b>About Us</b>
                    </TableCell>
                    <TableCell align="left">
                      {docs.aboutUs
                        ? docs.aboutUs.length > 250
                          ? parse(docs.aboutUs.substring(0, 250) + "...")
                          : parse(docs.aboutUs)
                        : "Not added yet"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "30%" }} align="left">
                      <b>Contact Us</b>
                    </TableCell>
                    <TableCell align="left">
                      {docs.contactUs
                        ? docs.contactUs.length > 250
                          ? parse(docs.contactUs.substring(0, 250) + "...")
                          : parse(docs.contactUs)
                        : "Not added yet"}
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell style={{ width: "30%" }} align="left">
                      <b>FAQ</b>
                    </TableCell>
                    <TableCell align="left">
                      {docs.faq
                        ? docs.faq.length > 250
                          ? parse(docs.faq.substring(0, 250) + "...")
                          : parse(docs.faq)
                        : "Not added yet"}
                    </TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell style={{ width: "30%" }} align="left">
                      <b>Legal</b>
                    </TableCell>
                    <TableCell align="left">
                      {docs.legal
                        ? docs.legal.length > 250
                          ? parse(docs.legal.substring(0, 250) + "...")
                          : parse(docs.legal)
                        : "Not added yet"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "30%" }} align="left">
                      <b>Privacy Policy</b>
                    </TableCell>
                    <TableCell align="left">
                      {docs.privacyPolicy
                        ? docs.privacyPolicy.length > 250
                          ? parse(docs.privacyPolicy.substring(0, 250) + "...")
                          : parse(docs.privacyPolicy)
                        : "Not added yet"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: "30%" }} align="left">
                      <b>Terms and Conditions</b>
                    </TableCell>
                    <TableCell align="left">
                      {docs.termsAndConditions
                        ? docs.termsAndConditions.length > 250
                          ? parse(
                              docs.termsAndConditions.substring(0, 250) + "..."
                            )
                          : parse(docs.termsAndConditions)
                        : "Not added yet"}
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>No Legal Docs Found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

function LegalDocList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title=" Legal Documents" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Legal Documents
          </Typography>
        </Grid>
        {privileges.includes("LEGAL_DOC_UPDATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/legal-docs/update">
                <Button variant="contained" color="primary">
                  Update Legal Document
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Legal Document</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default LegalDocList;
