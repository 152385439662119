import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import styled from "styled-components/macro";

const Centered = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export default function VerificationDocApproveReject(props) {
  const { open, expirationTime, submissionId } = props;
  const [approved, setApproved] = useState(true);
  const [adminText, setAdminText] = useState("");
  const [text, setText] = useState("");
  const [variant, setVariant] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setApproved(true);
    setAdminText("");
    setText("");
    setVariant("");
  }, [open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "approved":
        setApproved(value);
        break;
      case "adminText":
        setAdminText(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    const postData = {
      adminText,
      approved,
      expirationTime,
      submissionId,
    };
    props.handleSubmit(postData);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Approve/Reject Verification Doc"}
      </DialogTitle>

      <DialogContent sx={{ minWidth: "350px" }}>
        {text.length > 0 ? (
          <Alert mt={2} mb={3} severity={variant}>
            {text}
          </Alert>
        ) : (
          ""
        )}
        <Centered>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                margin="normal"
                id="adminText"
                multiline
                rows={3}
                name="adminText"
                label="Remarks"
                variant="outlined"
                value={adminText}
                onChange={handleChange}
                error={adminText.length > 255}
                helperText={
                  adminText.length > 255
                    ? "Limit exceeds(Maximum 255 character"
                    : "Character limit: Maximum 255"
                }
              />
            </Grid>

            <Grid item xs={12} style={{ mt: 4 }}>
              <InputLabel id="approved-label">Select Action</InputLabel>
              <Select
                fullWidth
                labelId="approved-label"
                id="approved"
                name="approved"
                value={approved}
                label="Select Action"
                onChange={handleChange}
              >
                <MenuItem value={true}>Approve</MenuItem>
                <MenuItem value={false}>Reject</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Centered>
      </DialogContent>

      <DialogActions>
        <Button
          mr={2}
          variant="contained"
          size={"small"}
          disabled={adminText.length === 0 || adminText.length > 255}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
        <Button variant="text" size={"small"} onClick={props.handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
