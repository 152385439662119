import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  Grid,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { sendOtpEmailPost, verifyEmailPost } from "../../requests/auth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function EmailVerify(props) {
    const { email } = props; 
    const navigate = useNavigate();
    const [otp, setOtp] = useState (new Array(4).fill(""));
    const [finalOtp, setFinalOtp] = useState("");
    const [error, setError] = useState("");

  useEffect(() => {
    getFinalOtp()
}, [otp]);


const handleChange = (element, index) => {
    if (isNaN(element.value))
      return false;

    let updatedOtp = [...otp];
    updatedOtp[index] = element.value;

    setOtp(updatedOtp);
    if(element.value){
      changeFocus(index)
    }
    else{
      changePreviousFieldFocus(index)
    }
};
const changePreviousFieldFocus = (index) => {
  if(index > 0)
 { 
   let ind = index-1
  document.getElementById("otp"+ind).focus();
}
else{
  getFinalOtp();
}
}
const changeFocus = (index) => {
  if(index < otp.length-1)
 { 
   let ind = index+1
  document.getElementById("otp"+ind).focus();
}
else{
  getFinalOtp();
}
}

const getFinalOtp = () => {
  let finalOtp = otp.join("");
  setFinalOtp(finalOtp);
} 

  const onVerify = async(setErrors, setStatus, setSubmitting) => {
    const postData = {
        tokenOtp: finalOtp,
    }
    const response = await verifyEmailPost(postData)
    try {
      if (response.success) {
        navigate("/private");
      }
      else{
        const message = response.message;

        setStatus({ success: false });
        setErrors({ submit: message });
        setSubmitting(false);
      }
    } catch (error) {
      const message = error.response.data.message || "Something went wrong";

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  }

  const onResendOtp = async() => {
    const postData = {
        userEmail: email,
    }
    const response = await sendOtpEmailPost(postData);
    try {
        const message = response.message;

        setError(message);
        if(response.success){
            setTimeout(() => {
                setError("");
              }, 3000);
        }

    } catch (error) {
      const message = error.response.data.message || "Something went wrong";

       setError(message);
    }
  }

  return (
    <Formik
      initialValues={{
        submit: false,
      }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        onVerify(setErrors, setStatus, setSubmitting)
      }}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
                  {error.length > 0 && (
            <Alert mt={2} mb={1} severity="warning">
              {error}
            </Alert>
          )}
     <Grid container>
      
      {otp.map((data, index) => {
                      return (
                        <Grid key={index} item xs={3}>
    <TextField
    sx={{ width: "70%", marginRight: "10px", paddingLeft: "12px", height: "40px", textAlign: "center"}}
    type="text"
    name="otp"
    id={"otp"+index}  
    value={data}
    onChange={e => handleChange(e.target, index)}
    onBlur={handleBlur}
    variant="standard"
    inputProps={{ maxLength: 1 }}
    /> 
       </Grid>
    );
  })}
   
    </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={finalOtp.length < 4 || isSubmitting}
          >
            Verify
          </Button>

          <Button
            type="button"
            fullWidth
            variant="text"
            color="primary"
            disabled={isSubmitting}
            onClick={()=>onResendOtp()}
          >
            Resend Otp
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default EmailVerify;
