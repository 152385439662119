import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  CircularProgress,
  Chip,
  Breadcrumbs,
  Stack,
} from "@mui/material";
import { Add as AddIcon, Delete, Edit } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { roleAllGet, roleByIdDelete } from "../../requests/role";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { useSelector } from "react-redux";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();

  const privilegeGroup = [
    "ROLE",
    "USER",
    "CMS",
    "RATING",
    "GLOSSARY",
    "LEGAL_DOC",
    "SUBSCRIPTION_PACKAGE",
    "COUPON",
    "PROMO",
    "HISTORY",
    "VERIFICATIONDOC",
    "UPLOADEDDOC",
    "BLOG",
    "ORGANIZATIONTYPE",
    "PRODUCT",
    "SCHOLAR",
    "INQUIRY_ALL",
    "MARKETING_ALL",
    "FORM_MANAGE",
  ];

  const [roles, setRoles] = useState([]);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [roleId, setRoleId] = useState(0);

  useEffect(() => {
    getAllRoles();
  }, []);

  const getAllRoles = async () => {
    setLoading(true);
    const responses = await roleAllGet({ roleType: "ADMIN" });
    try {
      setLoading(false);

      if (responses.success) {
        responses.data.sort((a, b) => (a.id < b.id ? 1 : -1));
        setRoles(responses.data);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (roleId) => {
    setConfirmationOpen(true);
    setRoleId(roleId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setRoleId(0);
  };

  const deleteRole = async () => {
    setLoading(true);
    const responses = await roleByIdDelete(roleId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllRoles();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id) => {
    navigate(`/role/update/${id}`);
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  Sl. No.
                </TableCell>
                <TableCell align="left" padding="normal">
                  {" "}
                  Name
                </TableCell>
                <TableCell align="left" padding="normal">
                  {" "}
                  Type
                </TableCell>
                <TableCell align="left" padding="normal">
                  {" "}
                  Privileges
                </TableCell>
                {privileges.includes("ROLE_UPDATE") ||
                privileges.includes("ROLE_DELETE") ? (
                  <TableCell align="left" padding="normal">
                    Actions
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {roles && roles.length > 0 ? (
                roles
                  .slice(pageNo * pageSize, pageNo * pageSize + pageSize)
                  .map((role, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={role.id}
                      >
                        <TableCell align="left">
                          {pageNo * pageSize + index + 1}.
                        </TableCell>
                        <TableCell align="left">{role.roleName}</TableCell>
                        <TableCell align="left">{role.roleType}</TableCell>
                        <TableCell align="left">
                          <div>
                            {privilegeGroup.map((pri, index) => (
                              <div style={{ display: "flex" }} key={index}>
                                {role.privileges.findIndex((privilege) =>
                                  privilege.name.match(pri)
                                ) !== -1 ? (
                                  <>
                                    <Chip
                                      color="primary"
                                      sx={{ m: 1 }}
                                      label={
                                        pri === "VERIFICATIONDOC"
                                          ? "VERIFICATION DOC (CONFIGURATION)"
                                          : pri === "SUBSCRIPTION_PACKAGE"
                                          ? "SUBSCRIPTION PACKAGE"
                                          : pri === "UPLOADEDDOC"
                                          ? "NON-PROFIT UPLOADED DOCS"
                                          : pri === "ORGANIZATIONTYPE"
                                          ? "ORGANIZATION TYPE"
                                          : pri === "MARKETING_ALL"
                                          ? "COMMUNICATION CENTER"
                                          : pri === "INQUIRY_ALL"
                                          ? "INQUIRIES"
                                          : pri === "RATING"
                                          ? "TESTIMONIAL"
                                          : pri === "CMS"
                                          ? "PAGES AND SECTIONS"
                                          : pri === "LEGAL_DOC"
                                          ? "LEGAL DOCUMENTS"
                                          : pri === "PROMO"
                                          ? "MANAGE PROMOS"
                                          : pri === "FORM_MANAGE"
                                          ? "MANAGE FORMS"
                                          : pri
                                      }
                                    />
                                    {role.privileges
                                      ?.filter((item) => item.name.match(pri))
                                      .map((item, index) => (
                                        <div key={index}>
                                          <Chip
                                            sx={{ m: 1 }}
                                            label={
                                              item.name === "MARKETING_ALL" ||
                                              item.name === "INQUIRY_ALL" ||
                                              item.name === "FORM_MANAGE_ALL"
                                                ? "ALL"
                                                : pri === "HISTORY"
                                                ? item.name
                                                    .replace(pri + "_", "")
                                                    .replace(/_/g, " ")
                                                : item.name.replace(
                                                    pri + "_",
                                                    ""
                                                  )
                                            }
                                            key={index}
                                          />
                                        </div>
                                      ))}
                                  </>
                                ) : null}
                              </div>
                            ))}
                          </div>
                        </TableCell>
                        <TableCell padding="none" align="left">
                          <Stack mr={2} direction="row">
                            {privileges.includes("ROLE_UPDATE") &&
                              role.roleName.toLowerCase() !== "super admin" && (
                                <IconButton
                                  aria-label="details"
                                  size="large"
                                  onClick={() => goEditPage(role.id)}
                                >
                                  <Edit color="primary" />
                                </IconButton>
                              )}
                            {privileges.includes("ROLE_DELETE") &&
                              role.roleName.toLowerCase() !== "super admin" && (
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  onClick={() => handleDelete(role.id)}
                                >
                                  <Delete sx={{ color: "#d93232" }} />
                                </IconButton>
                              )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>No Roles Found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={roles.length}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        title="Delete Role"
        text="Are you sure that you want to delete this role?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteRole}
      />
    </div>
  );
}

function RoleList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Roles" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Roles
          </Typography>
        </Grid>
        {privileges.includes("ROLE_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/role/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Role
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography>Roles</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default RoleList;
