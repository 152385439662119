import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button,
  CardContent,
  Fade,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  ListItemText as MuiListItemText,
  Paper as MuiPaper,
  Typography,
} from "@mui/material";
import {
  Drafts as DraftsIcon,
  MoveToInbox as InboxIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ListItemText = styled(MuiListItemText)(spacing);

// function SimpleMenu() {
//   const [anchorEl, setAnchorEl] = React.useState(null);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <Card mb={6}>
//       <CardContent>
//         <Typography variant="h6" gutterBottom>
//           Simple menu
//         </Typography>
//         <Typography variant="body2" gutterBottom>
//           Simple menu open over the anchor element by default
//         </Typography>
//         <Paper mt={3}>
//           <Button
//             aria-owns={anchorEl ? "simple-menu" : undefined}
//             aria-haspopup="true"
//             onClick={handleClick}
//             variant="contained"
//             color="secondary"
//           >
//             Open Menu
//           </Button>
//           <Menu
//             id="simple-menu"
//             anchorEl={anchorEl}
//             open={Boolean(anchorEl)}
//             onClose={handleClose}
//           >
//             <MenuItem onClick={handleClose}>Profile</MenuItem>
//             <MenuItem onClick={handleClose}>My account</MenuItem>
//             <MenuItem onClick={handleClose}>Logout</MenuItem>
//           </Menu>
//         </Paper>
//       </CardContent>
//     </Card>
//   );
// }

function TransitionMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Transition Menu
        </Typography>
        <Typography variant="body2" gutterBottom>
          Simple menu with different transition (fade)
        </Typography>
        <Paper mt={3}>
          <Button
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            Open Menu
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Menu>
        </Paper>
      </CardContent>
    </Card>
  );
}

// function IconMenu() {
//   const [anchorEl, setAnchorEl] = React.useState(null);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <Card mb={6}>
//       <CardContent>
//         <Typography variant="h6" gutterBottom>
//           Icon Menu
//         </Typography>
//         <Typography variant="body2" gutterBottom>
//           Simple menu with icons
//         </Typography>
//         <Paper mt={3}>
//           <Button
//             aria-owns={anchorEl ? "icon-menu" : undefined}
//             aria-haspopup="true"
//             onClick={handleClick}
//             variant="contained"
//             color="secondary"
//           >
//             Open Menu
//           </Button>
//           <Menu
//             id="icon-menu"
//             anchorEl={anchorEl}
//             open={Boolean(anchorEl)}
//             onClose={handleClose}
//           >
//             <MenuItem>
//               <ListItemIcon>
//                 <SendIcon />
//               </ListItemIcon>
//               <ListItemText inset primary="Sent mail" pl={0} />
//             </MenuItem>
//             <MenuItem>
//               <ListItemIcon>
//                 <DraftsIcon />
//               </ListItemIcon>
//               <ListItemText inset primary="Drafts" pl={0} />
//             </MenuItem>
//             <MenuItem>
//               <ListItemIcon>
//                 <InboxIcon />
//               </ListItemIcon>
//               <ListItemText inset primary="Inbox" pl={0} />
//             </MenuItem>
//           </Menu>
//         </Paper>
//       </CardContent>
//     </Card>
//   );
// }

const options = [
  "Show some love to Material-UI",
  "Show all notification content",
  "Hide sensitive notification content",
  "Hide all notification content",
];

function SelectedMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Selected menu
        </Typography>
        <Typography variant="body2" gutterBottom>
          Selected menus attempt to vertically align the currently selected menu
          item with the anchor element.
        </Typography>
        <Paper mt={3}>
          <List component="nav" aria-label="Device settings">
            <ListItem
              button
              aria-haspopup="true"
              aria-controls="lock-menu"
              aria-label="when device is locked"
              onClick={handleClickListItem}
            >
              <ListItemText
                primary="When device is locked"
                secondary={options[selectedIndex]}
              />
            </ListItem>
          </List>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {options.map((option, index) => (
              <MenuItem
                key={option}
                disabled={index === 0}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Paper>
      </CardContent>
    </Card>
  );
}

function Menus() {
  return (
    <React.Fragment>
      <Helmet title="Menus" />
      <Typography variant="h3" gutterBottom display="inline">
        Menus
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Components
        </Link>
        <Typography>Menus</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          {/* <SimpleMenu /> */}
          <TransitionMenu />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <IconMenu /> */}
          <SelectedMenu />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Menus;
