import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  Menu,
  TextField,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { getToastError } from "../../utils/toasts";
import moment from "moment";
import { transactionAllGet } from "../../requests/history";
import { NavLink, useNavigate } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable() {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);

  const [userName, setUserName] = useState("");
  const [anchorUserName, setAnchorUserName] = useState(null);
  const openUserName = Boolean(anchorUserName);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllTransactions();
  }, [userName, pageNo, pageSize]);

  const getAllTransactions = async () => {
    setLoading(true);
    const responses = await transactionAllGet({
      pageNo,
      pageSize,
      userName: userName.length > 0 ? userName : null,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setTransactions(responses.data.content);
        setTotalItems(responses.data.totalElements);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "userName":
        setAnchorUserName(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "userName":
        setAnchorUserName(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "userName":
        setUserName(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  Sl. No.
                </TableCell>
                <TableCell align="left" padding="normal">
                  {" "}
                  Username
                  <IconButton onClick={(e) => handleClick(e, "userName")}>
                    <Search
                      fontSize="small"
                      color={userName.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorUserName}
                  open={openUserName}
                  onClose={() => handleCloseMenu("userName")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="userName"
                    name="userName"
                    value={userName}
                    onChange={handleChange}
                    placeholder="Search Username"
                  />
                </Menu>

                <TableCell align="left" padding="normal">
                  Transaction Type
                </TableCell>
                <TableCell align="left" padding="normal">
                  Payment Date
                </TableCell>
                <TableCell align="left" padding="normal">
                  {" "}
                  Actual Amount
                </TableCell>

                <TableCell align="left" padding="normal">
                  Discount Amount
                </TableCell>
                <TableCell align="left" padding="normal">
                  Total Amount
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {transactions && transactions.length > 0 ? (
                transactions.map((transaction, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={transaction.id}
                    >
                      <TableCell align="left">
                        {pageNo * pageSize + index + 1}.
                      </TableCell>
                      <TableCell align="left">{transaction.userName}</TableCell>
                      <TableCell align="left">
                        {transaction.transactionType}
                      </TableCell>
                      <TableCell align="left">
                        {moment
                          .unix(transaction.creationDateTimeStamp)
                          .format("MM/DD/YYYY, h:mm a")}
                      </TableCell>
                      <TableCell align="left">
                        {transaction.actualAmount}
                      </TableCell>
                      <TableCell align="left">
                        {transaction.discountAmount}
                      </TableCell>
                      <TableCell align="left">
                        {transaction.totalAmount}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    No Transaction History Found!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function TransactionHistoryList() {
  return (
    <React.Fragment>
      <Helmet title="Transaction History" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Transaction History
          </Typography>
        </Grid>
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Transaction History</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TransactionHistoryList;
