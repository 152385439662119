import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import "../../styles/quil.css";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  InputLabel,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload, Delete } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { fileCommonPost } from "../../requests/file";
import { scholarCreatePost } from "../../requests/scholar";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { emailValidate } from "../../validation/validation";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function CreateForm() {
  const navigate = useNavigate();

  const [bio, setBio] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [hasErrorMail, setHasErrorMail] = useState(false);
  const [errorMailText, setErrorMailText] = useState("");
  const inputFile = useRef(null);
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;

      case "email":
        let checkError = emailValidate.test(value.trim());
        let error = checkError ? "" : "Please provide a valid email";

        setErrorMailText(error);
        setHasErrorMail(!checkError);
        setEmail(value);
        break;

      default:
        break;
    }
  };

  const handleChangeEditor = (value) => {
    setBio(value);
  };

  const handleChangeFile = (e) => {
    if (e.target.files[0] != null) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    inputFile.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImage("");
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (file) {
      let formData = new FormData();
      formData.append("file", file);
      const response = await fileCommonPost(formData, "SCHOLAR");

      try {
        setLoading(false);
        if (response.success) {
          handleCreate(response.data.id);
        } else {
          getToastError(response.message);
        }
      } catch (error) {
        setLoading(false);
        getToastError(error.response.data.message);
      }
    } else {
      handleCreate(null);
    }
  };

  const handleCreate = async (id) => {
    setLoading(true);
    const postData = {
      bio,
      imageFileId: id,
      name,
      email,
    };
    const responses = await scholarCreatePost(postData);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate("/scholar/list");
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Create
        </Typography>

        <InputLabel sx={{ mt: 2, mb: 1 }}>Image</InputLabel>
        <Grid container>
          <Grid item xs={12}>
            {file ? <img style={{ height: "30vh" }} src={image} /> : ""}
          </Grid>
          <Grid item xs={12}>
            {file ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={() => handleUpload()}
                >
                  <CloudUpload mr={2} /> Change
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  color="error"
                  component="span"
                  onClick={() => handleRemove()}
                >
                  <Delete mr={2} /> Remove
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={() => handleUpload()}
              >
                <CloudUpload mr={2} /> Upload
              </Button>
            )}
          </Grid>
        </Grid>

        <input
          type="file"
          id="file"
          accept="image/*"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={handleChangeFile}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              id="name"
              name="name"
              label="Name"
              required
              variant="outlined"
              value={name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              id="email"
              name="email"
              label="Email"
              required
              variant="outlined"
              value={email}
              error={hasErrorMail}
              helperText={errorMailText}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <InputLabel sx={{ mt: 2, mb: 1 }}>Bio</InputLabel>
        <ReactQuill
          className="ql-editor"
          value={bio}
          placeholder="Write bio here..."
          onChange={handleChangeEditor}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={name.length < 5 || email === "" || hasErrorMail}
        >
          Create
        </LoadingButton>
        <Button sx={{ mt: 3, ml: 1 }} onClick={() => navigate("/scholar/list")}>
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function ScholarCreate() {
  return (
    <React.Fragment>
      <Helmet title="Scholar Create" />

      <Typography variant="h3" gutterBottom display="inline">
        Scholar Create
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/scholar/list">
          Scholars
        </Link>
        <Typography> Create</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CreateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ScholarCreate;
