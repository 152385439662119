import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

// import useAuth from "../../hooks/useAuth";
import { resetPasswordPost } from "../../requests/auth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ResetPassword() {
  const navigate = useNavigate();
  // const { resetPassword } = useAuth();

  const onResetPassword = async (
    values,
    setErrors,
    setStatus,
    setSubmitting
  ) => {
    const postData = {
      userEmail: values.email,
    };
    const response = await resetPasswordPost(postData);
    try {
      const message = response.message;

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    } catch (error) {
      const message = error.message || "Something went wrong";

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        onResetPassword(values, setErrors, setStatus, setSubmitting);
        // try {
        //   resetPassword(values.email);
        //   navigate("/");
        // } catch (error) {
        //   const message = error.message || "Something went wrong";

        //   setStatus({ success: false });
        //   setErrors({ submit: message });
        //   setSubmitting(false);
        // }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Reset password
          </Button>
          <NavLink to="/" style={{ textDecoration: "none" }}>
            <Button
              sx={{ mt: 2 }}
              type="button"
              fullWidth
              variant="outlined"
              color="primary"
            >
              Back to Sign In Page
            </Button>
          </NavLink>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
