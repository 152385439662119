import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  CircularProgress,
  TextField,
  Menu,
  Stack,
  Breadcrumbs,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete,
  Edit,
  RemoveRedEye,
  Search,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { ConfirmationDialog } from "../../../components/dialogs/ConfirmationDialog";
import { pageAllGet, pageByIdDelete } from "../../../requests/page";
import { getToastError, getToastSuccess } from "../../../utils/toasts";
import { useSelector } from "react-redux";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const [pages, setPages] = useState([]);

  const [title, setTitle] = useState("");
  const [anchorTitle, setAnchorTitle] = useState(null);
  const openTitle = Boolean(anchorTitle);

  const [slug, setSlug] = useState("");
  const [anchorSlug, setAnchorSlug] = useState(null);
  const openSlug = Boolean(anchorSlug);

  const [tag, setTag] = useState("");
  const [anchorTag, setAnchorTag] = useState(null);
  const openTag = Boolean(anchorTag);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [pageId, setPageId] = useState(0);

  useEffect(() => {
    getAllPages();
  }, [pageNo, pageSize, title, slug, tag]);

  const getAllPages = async () => {
    setLoading(true);
    const responses = await pageAllGet({
      pageNo,
      pageSize,
      title: title.length > 0 ? title : null,
      slug: slug.length > 0 ? slug : null,
      tag: tag.length > 0 ? tag : null,
      sortBy: "title",
      ascOrDesc: "ASCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setPages(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "title":
        setAnchorTitle(event.currentTarget);
        break;
      case "slug":
        setAnchorSlug(event.currentTarget);
        break;
      case "tag":
        setAnchorTag(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "title":
        setAnchorTitle(null);
        break;
      case "slug":
        setAnchorSlug(null);
        break;
      case "tag":
        setAnchorTag(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "slug":
        setSlug(value);
        break;
      case "tag":
        setTag(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (pageId) => {
    setConfirmationOpen(true);
    setPageId(pageId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setPageId(0);
  };

  const deletePage = async () => {
    setLoading(true);
    const responses = await pageByIdDelete(pageId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        if (pageNo !== 0) {
          setPageNo(0);
        } else {
          getAllPages();
        }
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id) => {
    navigate(`/page-management/page/update/${id}`);
  };

  const goToSectionPage = (id) => {
    navigate(`/page-management/section/list/${id}`);
  };
  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  Sl. No.
                </TableCell>
                <TableCell align="left" padding="normal">
                  Title
                  <IconButton onClick={(e) => handleClick(e, "title")}>
                    <Search
                      fontSize="small"
                      color={title.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorTitle}
                  open={openTitle}
                  onClose={() => handleCloseMenu("title")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="title"
                    name="title"
                    value={title}
                    onChange={handleChange}
                    placeholder="Search Title"
                  />
                </Menu>
                <TableCell align="left" padding="normal">
                  Sub-Title
                </TableCell>
                <TableCell align="left" padding="normal" width={150}>
                  Page URL
                  <IconButton onClick={(e) => handleClick(e, "slug")}>
                    <Search
                      fontSize="small"
                      color={slug.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorSlug}
                  open={openSlug}
                  onClose={() => handleCloseMenu("slug")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="slug"
                    name="slug"
                    value={slug}
                    onChange={handleChange}
                    placeholder="Search Slug"
                  />
                </Menu>
                <TableCell align="left" padding="normal">
                  Tag
                  <IconButton onClick={(e) => handleClick(e, "tag")}>
                    <Search
                      fontSize="small"
                      color={tag.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorTag}
                  open={openTag}
                  onClose={() => handleCloseMenu("tag")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="tag"
                    name="tag"
                    value={tag}
                    onChange={handleChange}
                    placeholder="Search Tag"
                  />
                </Menu>
                <TableCell align="center" padding="normal" width={"20%"}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {pages && pages.length > 0 ? (
                pages.map((page, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={page.id}>
                      <TableCell align="left">
                        {pageNo * pageSize + index + 1}.
                      </TableCell>
                      <TableCell align="left">{page.title}</TableCell>
                      <TableCell align="left">{page.subTitle}</TableCell>
                      <TableCell align="left">{page.slug}</TableCell>
                      <TableCell align="left">{page.tag}</TableCell>
                      <TableCell padding="none" align="center">
                        <Stack direction="row" m={2}>
                          {privileges.includes("CMS_UPDATE") && (
                            <IconButton
                              aria-label="details"
                              size="large"
                              onClick={() => goEditPage(page.id)}
                            >
                              <Edit color="primary" />
                            </IconButton>
                          )}
                          {privileges.includes("CMS_DELETE") && (
                            <IconButton
                              aria-label="delete"
                              size="large"
                              onClick={() => handleDelete(page.id)}
                            >
                              <Delete sx={{ color: "#d93232" }} />
                            </IconButton>
                          )}
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => goToSectionPage(page.id)}
                          >
                            <RemoveRedEye sx={{ mr: 1 }} />
                            Sections
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No Pages Found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        title="Delete Page"
        text="Are you sure that you want to delete this page?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deletePage}
      />
    </div>
  );
}

function PageList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Pages" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Pages
          </Typography>
        </Grid>
        {privileges.includes("CMS_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/page-management/page/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Page
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography>Pages</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PageList;
