import axios from "axios";
import { basePath, headers } from "../utils/http";

export const productAllGet = async (params) => {
    try {
      const response = await axios.get(`${basePath()}/product/all`, 
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        }
      });
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const productByIdGet = async (id) => {
    try {
      const response = await axios.get(`${basePath()}/product/id/${id}`, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

export const productCreatePost = async (data) => {
  try {
    const response = await axios.post(`${basePath()}/product`, data, headers());
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
  }
};

export const productUpdatePut = async (data) => {
  try {
    const response = await axios.put(`${basePath()}/product`, data, headers());
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
  }
};


export const productByIdDelete = async (id) => {
  try {
    const response = await axios.delete(`${basePath()}/product/id/${id}`, headers());
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
  }
};