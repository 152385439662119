import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { spacing } from "@mui/system";
import { LoadingButton, LocalizationProvider } from "@mui/lab";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { couponCreatePost } from "../../requests/coupon";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

function CreateForm() {
  const navigate = useNavigate();

  const today = new Date();
  const [couponCode, setCouponCode] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [discountType, setDiscountType] = useState("FIXED");
  const [expirationDate, setExpirationDate] = useState(null);
  const [maxTimesCanBeUsed, setMaxTimesCanBeUsed] = useState("");

  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "couponCode":
        setCouponCode(value);
        break;
      case "discountAmount":
        setDiscountAmount(value);
        break;
      case "discountType":
        setDiscountType(value);
        break;
      case "maxTimesCanBeUsed":
        setMaxTimesCanBeUsed(value);
        break;
      default:
        break;
    }
  };

  const handleDateChange = (newValue) => {
    setExpirationDate(newValue);
  };

  const handleCreate = async () => {
    setLoading(true);
    const postData = {
      couponCode,
      discountAmount,
      discountType,
      expirationDate: moment(expirationDate).unix(),
      maxTimesCanBeUsed,
    };
    const responses = await couponCreatePost(postData);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate("/coupon/list");
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Create
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              id="couponCode"
              name="couponCode"
              label="Coupon Code"
              required
              variant="outlined"
              value={couponCode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="expirationDate"
                error
                label="Expiration Date"
                inputFormat="MM/dd/yyyy"
                value={expirationDate}
                minDate={today}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              type="number"
              margin="normal"
              id="maxTimesCanBeUsed"
              name="maxTimesCanBeUsed"
              label="Max time can be used"
              variant="outlined"
              value={maxTimesCanBeUsed}
              onChange={handleChange}
              error={maxTimesCanBeUsed && maxTimesCanBeUsed <= 0}
              helperText={
                maxTimesCanBeUsed && maxTimesCanBeUsed <= 0
                  ? "Max time can be used cannot be less than 0"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              type={"number"}
              margin="normal"
              id="discountAmount"
              name="discountAmount"
              label="Discount Amount"
              variant="outlined"
              value={discountAmount}
              onChange={handleChange}
              error={discountAmount && discountAmount <= 0}
              helperText={
                discountAmount && discountAmount <= 0
                  ? "Discount amount cannot be less than 0"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="demo-simple-select-label-discountType">
                Discount Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-discountType"
                id="discountType"
                name="discountType"
                value={discountType}
                label="Discount Type"
                onChange={handleChange}
              >
                <MenuItem value={"FIXED"}>Fixed</MenuItem>
                <MenuItem value={"PERCENTAGE"}>Percentage</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleCreate}
          disabled={
            couponCode === "" ||
            discountAmount === "" ||
            discountAmount <= 0 ||
            maxTimesCanBeUsed === "" ||
            maxTimesCanBeUsed <= 0 ||
            expirationDate <= today
          }
        >
          Create
        </LoadingButton>
        <Button sx={{ mt: 3, ml: 1 }} onClick={() => navigate("/coupon/list")}>
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function CouponCreate() {
  return (
    <React.Fragment>
      <Helmet title="Coupon Create" />

      <Typography variant="h3" gutterBottom display="inline">
        Coupon Create
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/coupon/list">
          Coupons
        </Link>
        <Typography> Create</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CreateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CouponCreate;
