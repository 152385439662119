import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

// import useAuth from "../../hooks/useAuth";
import { changePasswordPost } from "../../requests/auth";
import { useSelector } from "react-redux";
import { getToastSuccess } from "../../utils/toasts";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ChangePassword() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.user);

  const onChangePassword = async (
    values,
    setErrors,
    setStatus,
    setSubmitting
  ) => {
    if (userData && userData.email) {
      const postData = {
        email: userData.email,
        newPassword: values.password,
        previousPassword: values.currentPassword,
      };
      const response = await changePasswordPost(postData);
      try {
        if (response.success) {
          getToastSuccess(response.message);
          navigate("/profile");
        } else {
          const message = response.message;

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      } catch (error) {
        const message = error.response.data.message || "Something went wrong";

        setStatus({ success: false });
        setErrors({ submit: message });
        setSubmitting(false);
      }
    } else {
      const message = "Please Login first";

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        currentPassword: "",
        password: "",
        confirmPassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        currentPassword: Yup.string()
          .max(255)
          .required("Current Password is required"),
        password: Yup.string()
          .matches(
            /^(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/,
            "Password must contain at least one digit and a special character (!@#$%&)"
          )
          .required("Password is required"),
        confirmPassword: Yup.string()
          .required("Confirm password is required")
          .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              "Both password need to be the same"
            ),
          }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        onChangePassword(values, setErrors, setStatus, setSubmitting);
      }}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        handleChange,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="password"
            name="currentPassword"
            label="Current password"
            value={values.currentPassword}
            error={Boolean(touched.currentPassword && errors.currentPassword)}
            fullWidth
            helperText={touched.currentPassword && errors.currentPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="password"
            label="New Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Change
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ChangePassword;
