import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import "../../../styles/quil.css";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  InputLabel,
  Alert,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload, Delete } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { blogFilePost } from "../../../requests/file";
import { LoadingButton } from "@mui/lab";
import { blogByUuidGet, blogUpdatePut } from "../../../requests/blog";
import { getToastError, getToastSuccess } from "../../../utils/toasts";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function UpdateForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { uuid } = useParams();
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [tag, setTag] = useState("");
  const inputFile = useRef(null);
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.search && location.search.length > 0) {
      if (location.search.split("=").length === 2) {
        setTag(location.search.split("=")[1]);
      }
    }
    getBlogDetails();
  }, []);

  const getBlogDetails = async () => {
    setLoading(true);
    const responses = await blogByUuidGet(uuid);
    try {
      setLoading(false);

      if (responses.success) {
        const blog = responses.data;
        setDescription(blog.description);
        setTitle(blog.title);
        setSubTitle(blog.subTitle);
        if (blog.coverImg) {
          setFile(blog.coverImg);
          setImage(blog.coverImg.fileUrl);
        }
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "subTitle":
        setSubTitle(value);
        break;
      case "tag":
        setTag(value);
        break;
      default:
        break;
    }
  };

  const handleChangeEditor = (value) => {
    setDescription(value);
  };

  const handleChangeFile = (e) => {
    if (e.target.files[0] != null) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    inputFile.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImage("");
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (file) {
      if (file.id) {
        handleUpdate(file.id);
      } else {
        let formData = new FormData();
        formData.append("file", file);
        const response = await blogFilePost(formData, "BLOG_COVER_IMAGE");

        try {
          setLoading(false);
          if (response.success) {
            handleUpdate(response.data.id);
          } else {
            getToastError(response.message);
          }
        } catch (error) {
          setLoading(false);
          getToastError(error.response.data.message);
        }
      }
    } else {
      handleUpdate(null);
    }
  };

  const handleUpdate = async (id) => {
    setLoading(true);
    const postData = {
      uuid,
      description,
      imageFileId: id,
      title,
      subTitle,
      tag,
    };
    const responses = await blogUpdatePut(postData);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate("/blog/list");
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Update
        </Typography>

        <InputLabel sx={{ mt: 2, mb: 1 }}>Image</InputLabel>
        <Grid container>
          <Grid item xs={12}>
            {file ? <img style={{ height: "30vh" }} src={image} /> : ""}
          </Grid>
          <Grid item xs={12}>
            {file ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={() => handleUpload()}
                >
                  <CloudUpload mr={2} /> Change
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  color="error"
                  component="span"
                  onClick={() => handleRemove()}
                >
                  <Delete mr={2} /> Remove
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={() => handleUpload()}
              >
                <CloudUpload mr={2} /> Upload
              </Button>
            )}
          </Grid>
        </Grid>

        <input
          type="file"
          id="file"
          accept="image/*"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={handleChangeFile}
          onClick={(event) => {
            event.target.value = null;
          }}
        />

        <TextField
          fullWidth
          margin="normal"
          id="title"
          name="title"
          label="Title*"
          variant="outlined"
          value={title}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          margin="normal"
          id="subTitle"
          name="subTitle"
          label="Sub-Title*"
          variant="outlined"
          value={subTitle}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          id="tag"
          name="tag"
          label="Tag"
          variant="outlined"
          disabled
          value={tag}
          onChange={handleChange}
        />

        <InputLabel sx={{ mt: 2, mb: 1 }}>Description</InputLabel>
        <ReactQuill
          className="ql-editor"
          value={description}
          placeholder="Write Description..."
          onChange={handleChangeEditor}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={title === "" || subTitle === ""}
        >
          Update
        </LoadingButton>
        <Button sx={{ mt: 3, ml: 1 }} onClick={() => navigate("/blog/list")}>
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function BlogUpdate() {
  return (
    <React.Fragment>
      <Helmet title="Blog Update" />

      <Typography variant="h3" gutterBottom display="inline">
        Blog Update
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/blog/list">
          Blogs
        </Link>
        <Typography> Update</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <UpdateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default BlogUpdate;
