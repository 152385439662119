import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  Button,
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  Select,
  InputLabel,
  MenuItem,
  Autocomplete,
  createFilterOptions,
  Chip,
  Checkbox,
  FormHelperText,
  FormGroup,
} from "@mui/material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { emailValidate } from "../../validation/validation";
import { promoByIdGet, promoUpdatePut } from "../../requests/promo";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { stringEachWordFirstLetterUpperCase } from "../../formatted";
import { discountTypes, promoTypes } from "../../constant";
import { productAllGet } from "../../requests/product";
import { Add, Remove } from "@mui/icons-material";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

function UpdateForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [code, setCode] = useState("");
  const [expirationDate, setExpirationDate] = useState(null);
  const [promoType, setPromoType] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [active, setActive] = useState(true);
  const [activeReferral, setActiveReferral] = useState(false);

  const filter = createFilterOptions();
  const [emailOptions, setEmailOptions] = useState([]);
  const [emails, setEmails] = useState([]);
  const [inputValueEmail, setInputValueEmail] = useState("");

  const [hasErrorEmail, setHasErrorEmail] = useState(false);
  const [errorTextEmail, setErrorTextEmail] = useState("");

  const [productOptions, setProductOptions] = useState([]);

  const [promoProducts, setPromoProducts] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserProducts();
    getPromoDetails();
  }, []);

  const getPromoDetails = async () => {
    setLoading(true);
    const responses = await promoByIdGet(id);
    try {
      setLoading(false);

      if (responses.success) {
        const promo = responses.data;
        setCode(promo.code);
        setShortDescription(promo.shortDescription);
        setPromoType(promo.promoType);
        setActive(promo.active);
        if (promo?.activeReferral) {
          setActiveReferral(promo.activeReferral);
        }
        if (promo.privateUsersEmail) {
          setEmails(promo.privateUsersEmail);
        }
        setPromoProducts(promo.promoProducts);
        if (promo.expirationDate) {
          let convertedExpirationDate = new Date(
            moment.unix(promo.expirationDate)
          ).toString();
          setExpirationDate(convertedExpirationDate);
        }
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };
  const getUserProducts = async () => {
    const responses = await productAllGet({
      pageSize: 30,
      roleType: "USER",
      sortBy: "name",
      ascOrDesc: "ASCENDING",
    });
    try {
      if (responses.success) {
        setProductOptions(responses.data.content);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    switch (name) {
      case "code":
        setCode(value);
        break;
      case "promoType":
        if (value !== "PRIVATE") {
          setEmails([]);
          setEmailOptions([]);
          setHasErrorEmail(false);
          setErrorTextEmail("");
        }
        setPromoType(value);
        break;
      case "shortDescription":
        setShortDescription(value);
        break;
      case "active":
        setActive(value);
        break;
      case "activeReferral":
        setActiveReferral(checked);
        break;
      default:
        break;
    }
  };

  const handleDateChange = (newValue) => {
    setExpirationDate(newValue);
  };

  const handleEmailChange = (event, newValue) => {
    setEmails(newValue);
    let count = 0;
    newValue.map((email) => {
      if (!emailValidate.test(email)) {
        count++;
      }
    });
    if (count > 0) {
      setHasErrorEmail(true);
      setErrorTextEmail("Please add valid mails");
    } else {
      setHasErrorEmail(false);
      setErrorTextEmail("");
    }
  };

  const handleAddProducts = () => {
    let addProduct = [
      ...promoProducts,
      {
        productTag: "",
        offerType: "",
        discountAmount: "",
        maximumUsages: "",
        maximumAvailable: "",
      },
    ];
    setPromoProducts(addProduct);
  };

  const handleRemoveProducts = (index) => {
    let removeProduct = [...promoProducts];
    removeProduct.splice(index, 1);
    setPromoProducts(removeProduct);
  };

  const handleChangeProduct = (event, index) => {
    const { name, value } = event.target;
    let selectedProducts = [...promoProducts];

    selectedProducts[index][name] = value;

    setPromoProducts(selectedProducts);
  };

  const discountAmountError = (offerType, discountAmount) => {
    if (discountAmount) {
      if (offerType === "DISCOUNT") {
        if (discountAmount <= 0 || discountAmount > 100) return true;
        else {
          return false;
        }
      } else {
        if (discountAmount <= 0) return true;
        else return false;
      }
    } else return false;
  };

  const handleDisableSubmit = () => {
    if (loading) {
      return true;
    } else if (!code || !expirationDate || !promoType || hasErrorEmail) {
      return true;
    } else if (promoType === "PRIVATE" && emails.length === 0) {
      return true;
    } else if (promoProducts.length > 0) {
      let count = 0;
      promoProducts.map((prod) => {
        if (!checkRequiredFields(prod)) {
          count++;
        }
      });
      if (count > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkIsEmpty = (obj) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] != "") return false;
    }
    return true;
  };

  const checkRequiredFields = (obj) => {
    let count = 0;
    for (var key in obj) {
      if (
        key === "productTag" ||
        key === "offerType" ||
        key === "discountAmount" ||
        key === "maximumUsages"
      ) {
        if (obj[key] == null || obj[key] == "") {
          count++;
        } else if (
          key === "discountAmount" &&
          obj["discountAmount"] &&
          obj["offerType"] === "DISCOUNT"
        ) {
          if (discountAmountError(obj["offerType"], obj["discountAmount"])) {
            count++;
          }
        } else if (key === "discountAmount" || key === "maximumUsages") {
          if (obj[key] <= 0) {
            count++;
          }
        }
      } else if (
        key === "maximumAvailable" &&
        obj[key] != "" &&
        obj[key] != null &&
        obj[key] <= 0
      ) {
        count++;
      }
    }
    if (count > 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleUpdate = async () => {
    let convertedExpirationDate = expirationDate.$d
      ? expirationDate.$d.toUTCString()
      : expirationDate;
    let filteredPromoProducts = [];
    if (promoProducts.length > 0) {
      filteredPromoProducts = promoProducts.filter(
        (prod) => !checkIsEmpty(prod)
      );
    }
    setLoading(true);
    const postData = {
      id,
      active,
      expirationDate: moment(convertedExpirationDate).unix(),
      shortDescription,
      promoType,
      code,
      promoProducts: filteredPromoProducts,
      privateUsersEmail: emails,
      activeReferral: promoType === "REFERRAL_PROGRAM" ? activeReferral : false,
    };
    const responses = await promoUpdatePut(postData);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate("/promos/list");
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Update
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              id="code"
              name="code"
              label="Code"
              required
              variant="outlined"
              value={code}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                id="expirationDate"
                error
                renderInput={(props) => (
                  <TextField
                    {...props}
                    required
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                )}
                label="Expiration Date and Time"
                value={expirationDate}
                onChange={handleDateChange}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              multiline
              id="shortDescription"
              name="shortDescription"
              label="Short Description"
              variant="outlined"
              value={shortDescription}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl required m={2} margin="normal" sx={{ width: "100%" }}>
              <InputLabel id="promoType">Promo Type</InputLabel>
              <Select
                required
                fullWidth
                labelId="promoType"
                id="promoType"
                value={promoType}
                name="promoType"
                label="Promo Type"
                onChange={handleChange}
              >
                {promoTypes.map((type, index) => {
                  return (
                    <MenuItem key={index} value={type}>
                      {stringEachWordFirstLetterUpperCase(type)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal" sx={{ ml: 10 }}>
              <FormLabel id="demo-active">Is Active?</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-active"
                name="active"
                value={active}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {promoType === "REFERRAL_PROGRAM" && (
            <Grid item xs={12} sx={{ mb: 5 }}>
              <FormControl
                component="fieldset"
                variant="standard"
                sx={{ ml: 5 }}
              >
                <FormGroup>
                  <FormControlLabel
                    label={" Do you want to use this referral code currently? "}
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checked={activeReferral}
                        onChange={handleChange}
                        name="activeReferral"
                      />
                    }
                  />
                </FormGroup>
                <FormHelperText>
                  Caution: By clicking this other referral code will be disable.
                </FormHelperText>
              </FormControl>
            </Grid>
          )}

          {promoType === "PRIVATE" && (
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                multiple
                value={emails}
                onChange={handleEmailChange}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  if (params.inputValue !== "") {
                    filtered.push(`${params.inputValue}`);
                  }

                  return filtered;
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValueEmail(newInputValue);
                }}
                options={emailOptions}
                getOptionLabel={(option) => {
                  // e.g value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option;
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      color={emailValidate.test(option) ? "secondary" : "error"}
                      label={`${option}`}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    required
                    error={hasErrorEmail}
                    helperText={errorTextEmail}
                    label="Private User Emails"
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <InputLabel>Tag with Products</InputLabel>
          </Grid>
          {promoProducts.length === 0 ? (
            <Grid item xs={12} mt={2} mb={2}>
              <Button
                color="primary"
                variant="contained"
                sx={{ mr: 2 }}
                onClick={handleAddProducts}
              >
                Add Product
                <Add />
              </Button>
            </Grid>
          ) : (
            promoProducts.map((prod, index) => (
              <Grid
                item
                xs={12}
                sx={{
                  border: "1px dashed #cfcfcf",
                  mt: 5,
                  p: "10px 15px",
                }}
                justifyContent={"center"}
                alignItems={"center"}
                display="flex"
              >
                <Grid key={index} container spacing={2} sx={{ width: "95%" }}>
                  <Grid item xs={12}>
                    <InputLabel>{`Product ${index + 1}`}</InputLabel>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      required
                      m={2}
                      margin="normal"
                      sx={{ width: "100%" }}
                    >
                      <InputLabel id="productTag">Select Product</InputLabel>
                      <Select
                        required
                        fullWidth
                        labelId="productTag"
                        id="productTag"
                        value={prod.productTag}
                        name="productTag"
                        label="Select Product"
                        onChange={(event) => handleChangeProduct(event, index)}
                      >
                        {productOptions.map((product, index) => {
                          return (
                            <MenuItem key={index} value={product.tag}>
                              {product.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl
                      required
                      m={2}
                      margin="normal"
                      sx={{ width: "100%" }}
                    >
                      <InputLabel id="offerType">Select Offer Type</InputLabel>
                      <Select
                        required
                        fullWidth
                        labelId="offerType"
                        id="offerType"
                        value={prod.offerType}
                        name="offerType"
                        label="Select Offer Type"
                        onChange={(event) => handleChangeProduct(event, index)}
                      >
                        {discountTypes.map((type, index) => {
                          return (
                            <MenuItem key={index} value={type}>
                              {type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      required
                      type={"number"}
                      margin="normal"
                      id="discountAmount"
                      name="discountAmount"
                      label="Discount Amount"
                      variant="outlined"
                      value={prod.discountAmount}
                      onChange={(event) => handleChangeProduct(event, index)}
                      error={discountAmountError(
                        prod.offerType,
                        prod.discountAmount
                      )}
                      helperText={
                        discountAmountError(prod.offerType, prod.discountAmount)
                          ? prod.offerType === "DISCOUNT"
                            ? "Discount amount range for offer type: discount is 1-100"
                            : "Discount amount cannot be less than 0"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      required
                      type="number"
                      margin="normal"
                      id="maximumUsages"
                      name="maximumUsages"
                      label="Maximum time of usage"
                      variant="outlined"
                      value={prod.maximumUsages}
                      onChange={(event) => handleChangeProduct(event, index)}
                      error={prod.maximumUsages && prod.maximumUsages <= 0}
                      helperText={
                        prod.maximumUsages && prod.maximumUsages <= 0
                          ? "Maximum time of usage can be used cannot be less than 0"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type="number"
                      margin="normal"
                      id="maximumAvailable"
                      name="maximumAvailable"
                      label="Maximum Available"
                      variant="outlined"
                      value={prod.maximumAvailable}
                      onChange={(event) => handleChangeProduct(event, index)}
                      error={
                        prod.maximumAvailable && prod.maximumAvailable <= 0
                      }
                      helperText={
                        prod.maximumAvailable && prod.maximumAvailable <= 0
                          ? "Maximum available of usage can be used cannot be less than 0"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    justifyContent={"end"}
                    alignItems={"end"}
                    display="flex"
                  >
                    {index === promoProducts.length - 1 && (
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ mr: 2 }}
                        onClick={handleAddProducts}
                      >
                        Add <Add />
                      </Button>
                    )}
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => handleRemoveProducts(index)}
                    >
                      Remove <Remove />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
        {promoProducts.length > 0 && (
          <>
            <Grid item xs={12}>
              <FormHelperText>
                *Maximum time of usage : Maximum number of promotion codes used
                by one email address.
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <FormHelperText>
                *Maximum Available : Maximum number of promotion codes you want
                to send.
              </FormHelperText>
            </Grid>
          </>
        )}

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleUpdate}
          disabled={handleDisableSubmit()}
        >
          Update
        </LoadingButton>
        <Button sx={{ mt: 3, ml: 1 }} onClick={() => navigate("/promos/list")}>
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function PromoUpdate() {
  return (
    <React.Fragment>
      <Helmet title="Promo Update" />

      <Typography variant="h3" gutterBottom display="inline">
        Promo Update
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/promos/list">
          Promos
        </Link>
        <Typography> Update</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <UpdateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PromoUpdate;
