import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  Grid,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

// import useAuth from "../../hooks/useAuth";
import { resetPasswordVerifyPost, sendOtpEmailPost } from "../../requests/auth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ResetPasswordVerify(props) {
  const { token } = props;
  const navigate = useNavigate();

  const onResetPasswordVerify = async (
    values,
    setErrors,
    setStatus,
    setSubmitting
  ) => {
    const postData = {
      newPassword: values.password,
      otpCode: token,
    };
    const response = await resetPasswordVerifyPost(postData);
    try {
      if (response.success) {
        navigate("/");
      } else {
        const message = response.message;

        setStatus({ success: false });
        setErrors({ submit: message });
        setSubmitting(false);
      }
    } catch (error) {
      const message = error.message || "Something went wrong";

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .matches(
            /^(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/,
            "Password must contain at least one digit and a special character (!@#$%&)"
          )
          .required("Password is required"),
        confirmPassword: Yup.string()
          .required("Confirm password is required")
          .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              "Both password need to be the same"
            ),
          }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        onResetPasswordVerify(values, setErrors, setStatus, setSubmitting);
      }}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        handleChange,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ResetPasswordVerify;
