import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Alert,
  CircularProgress,
  Chip,
  Card,
  CardContent,
  Autocomplete,
  TextField,
  Breadcrumbs,
  Link,
} from "@mui/material";

import { spacing } from "@mui/system";
import {
  verificationDocAllGet,
  verificationDocAllUploadedGet,
  verificationDocApproveRejectPost,
} from "../../requests/varificationDoc";
import { nonProfitAllGet } from "../../requests/nonProfit";
import VerificationDocApproveReject from "../../components/verification-doc/VerificationDocApproveReject";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { useSelector } from "react-redux";
import moment from "moment";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const [nonProfits, setNonProfits] = useState([]);
  const [selectedNonProfit, setSelectedNonProfit] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [nonProfitId, setNonProfitId] = useState(0);

  const [verificationDocs, setVerificationDocs] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState({});
  const [inputValueDoc, setInputValueDoc] = useState("");
  const [docId, setDocId] = useState(0);

  const [docs, setDocs] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [expirationTime, setExpirationTime] = useState(0);
  const [submissionId, setSubmissionId] = useState(0);

  useEffect(() => {
    getAllNonProfits();
    getAllVerificationDocs();
  }, []);

  useEffect(() => {
    getAllDocs();
  }, [pageNo, pageSize, nonProfitId, docId]);

  const getAllDocs = async () => {
    setLoading(true);
    const responses = await verificationDocAllUploadedGet({
      pageNo,
      pageSize,
      nonProfitId: nonProfitId !== 0 ? nonProfitId : null,
      verificationDocId: docId !== 0 ? docId : null,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setDocs(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllNonProfits = async () => {
    setLoading(true);
    const responses = await nonProfitAllGet({ pageNo: 0, pageSize: 500 });
    try {
      setLoading(false);

      if (responses.success) {
        setNonProfits(responses.data.content);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllVerificationDocs = async () => {
    setLoading(true);
    const responses = await verificationDocAllGet();
    try {
      setLoading(false);

      if (responses.success) {
        setVerificationDocs(responses.data);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const getNonProfitNameById = (id) => {
    const nonProfitIndex = nonProfits.findIndex((non) => non.id == id);
    if (nonProfitIndex !== -1) {
      return nonProfits[nonProfitIndex].name;
    } else {
      return id;
    }
  };

  const handleDialogOpen = (expirationTime, submissionId) => {
    setExpirationTime(expirationTime);
    setSubmissionId(submissionId);
    setApproveDialogOpen(true);
  };

  const handleDialogClose = () => {
    setExpirationTime(0);
    setSubmissionId(0);
    setApproveDialogOpen(false);
  };

  const handleApproveReject = async (data) => {
    setLoading(true);
    const responses = await verificationDocApproveRejectPost(data);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setApproveDialogOpen(false);
        getAllDocs();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <div>
      <Card mb={6}>
        <CardContent pb={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedNonProfit(newValue);
                    setNonProfitId(newValue.id);
                  } else {
                    setSelectedNonProfit({});
                    setNonProfitId(0);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={nonProfits}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    size="small"
                    label="Filter By Non-Profit"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedDoc(newValue);
                    setDocId(newValue.id);
                  } else {
                    setSelectedDoc({});
                    setDocId(0);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValueDoc(newInputValue);
                }}
                options={verificationDocs}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    size="small"
                    label="Filter By Document Name"
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Paper>
          <TableContainer>
            <Grid
              container
              display={loading ? "flex" : "none"}
              justifyContent="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    Sl. No.
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {" "}
                    Non-Profit
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Created By
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Document Name
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Expiration Date
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    File
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Status
                  </TableCell>
                  {privileges.includes("UPLOADEDDOC_UPDATE") && (
                    <TableCell align="left" padding="normal">
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {docs && docs.length > 0 ? (
                  docs.map((doc, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={doc.id}
                      >
                        <TableCell align="left">
                          {pageNo * pageSize + index + 1}.
                        </TableCell>
                        <TableCell align="left">
                          {getNonProfitNameById(doc.nonprofitId)}
                        </TableCell>
                        <TableCell align="left">
                          {doc.submissions && doc.submissions.length > 0
                            ? doc.submissions[0].createdBy
                            : ""}
                        </TableCell>
                        <TableCell align="left">
                          {doc.verificationDoc && doc.verificationDoc.name
                            ? doc.verificationDoc.name
                            : ""}
                        </TableCell>
                        <TableCell align="left">
                          {doc.verificationDoc &&
                          doc.verificationDoc.expirationNeeded
                            ? doc.submissions &&
                              doc.submissions[0].expiryDateTime !== 0
                              ? `${moment
                                  .unix(doc.submissions[0].expiryDateTime)
                                  .format("Do MMMM YYYY")}`
                              : "Has no expiry date"
                            : "Has no expiry date"}
                        </TableCell>
                        <TableCell align="center" sx={{ borderRadius: "5px" }}>
                          {doc.submissions &&
                          doc.submissions.length > 0 &&
                          doc.submissions[0].files &&
                          doc.submissions[0].files.length > 0 ? (
                            <img
                              style={{
                                height: "150px",
                                width: "150px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                              onClick={() =>
                                window.open(
                                  doc.submissions[0].files[0].fileUrl,
                                  "_blank"
                                )
                              }
                              src={
                                doc.submissions[0].files[0].fileUrl.match(
                                  /.(jpg|jpeg|png|gif)$/i
                                )
                                  ? doc.submissions[0].files[0].fileUrl
                                  : "/static/img/placeholder/file-preview.svg"
                              }
                            />
                          ) : (
                            "No Icon"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Chip
                            label={doc.verificationStatus}
                            sx={{
                              backgroundColor:
                                doc.verificationStatus.toUpperCase() ===
                                "ACCEPTED"
                                  ? "#0B695E"
                                  : doc.verificationStatus.toUpperCase() ===
                                    "PENDING"
                                  ? "#FF9900"
                                  : doc.verificationStatus.toUpperCase() ===
                                    "REJECTED"
                                  ? "#FF0000"
                                  : "#E5F6EE",
                              color:
                                doc.verificationStatus.toUpperCase() ===
                                  "ACCEPTED" ||
                                doc.verificationStatus.toUpperCase() ===
                                  "PENDING" ||
                                doc.verificationStatus.toUpperCase() ===
                                  "REJECTED"
                                  ? "#FFF"
                                  : "#000",
                            }}
                          />
                        </TableCell>
                        <TableCell padding="none" align="left">
                          {privileges.includes("UPLOADEDDOC_UPDATE") && (
                            <Box mr={2}>
                              {doc.verificationStatus === "PENDING" ? (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() =>
                                    handleDialogOpen(
                                      doc.submissions[0].expiryDateTime,
                                      doc.submissions[0].id
                                    )
                                  }
                                >
                                  Approve/Reject
                                </Button>
                              ) : (
                                ""
                              )}
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      No Verification Docs Found!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={pageSize}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <VerificationDocApproveReject
            open={approveDialogOpen}
            expirationTime={expirationTime}
            submissionId={submissionId}
            handleClose={handleDialogClose}
            handleSubmit={handleApproveReject}
          />
        </Paper>
      </Card>
    </div>
  );
}

function PendingVerificationDocsList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Pending Verification Docs" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Non-Profit Uploaded Docs
          </Typography>
        </Grid>
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Non-Profit Uploaded Docs</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PendingVerificationDocsList;
