import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Alert,
  Avatar as MuiAvatar,
  Card,
  CardContent,
  CircularProgress,
  Grid,
} from "@mui/material";
import styled from "styled-components/macro";
import { fileCommonPost, fileCommonUpdatePut } from "../../requests/file";

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;
const Centered = styled.div`
  text-align: center;
`;

export default function UploadProfilePictureDialog(props) {
  const { open, userImage, userName, imageId } = props;
  const [text, setText] = useState("");
  const [variant, setVariant] = useState("");
  const [loading, setLoading] = useState(false);
  const inputFile = useRef(null);
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (e) => {
    if (e.target.files[0] != null) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    inputFile.current.click();
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (imageId === 0) {
      let formData = new FormData();
      formData.append("file", file);
      const response = await fileCommonPost(formData, "USER_IMAGE");

      try {
        setText(response.message);
        setLoading(false);
        if (response.success) {
          setVariant("success");
          const putData = {
            fileId: response.data.id,
          };
          props.handleSubmit(putData);
        } else {
          setVariant("error");
        }
      } catch (error) {
        setLoading(false);
        setText(error.response.data.message);
        setVariant("error");
      }
    } else {
      let formData = new FormData();
      formData.append("file", file);
      const response = await fileCommonUpdatePut(
        formData,
        "USER_IMAGE",
        imageId
      );

      try {
        setText(response.message);
        setLoading(false);
        if (response.success) {
          setVariant("success");
          const putData = {
            fileId: imageId,
          };
          props.handleSubmit(putData);
        } else {
          setVariant("error");
        }
      } catch (error) {
        setLoading(false);
        setText(error.response.data.message);
        setVariant("error");
      }
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Upload Profile Picture"}
      </DialogTitle>
      <DialogContent sx={{ minWidth: "350px" }}>
        {text.length > 0 && !loading ? (
          <Alert mt={2} mb={3} severity={variant}>
            {text}
          </Alert>
        ) : (
          ""
        )}
        <Card mb={6}>
          <CardContent>
            <Grid
              container
              display={loading ? "flex" : "none"}
              justifyContent="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
            <Centered>
              <Avatar
                alt={userName ? userName : ""}
                src={file ? image : userImage}
              />
            </Centered>
            <Centered>
              {file ? (
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Upload
                </Button>
              ) : (
                ""
              )}
            </Centered>
            <input
              type="file"
              id="file"
              accept="image/*"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={handleChange}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button
          mr={2}
          variant="outlined"
          size={"small"}
          onClick={() => handleUpload()}
        >
          {file ? "Select Another Picture" : "Select Profile Picture"}
        </Button>
        <Button variant="text" size={"small"} onClick={props.handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
