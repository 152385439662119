import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { contactUploadPost } from "../../requests/contact";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { basePath } from "../../utils/http";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function UploadForm() {
  const navigate = useNavigate();
  const inputFile = useRef(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChangeFile = (e) => {
    if (e.target.files[0] != null) {
      setFile(e.target.files[0]);
    }
  };

  const handleRemove = () => {
    setFile(null);
    document.getElementById("file").value = null;
    // inputFile.current.click();
  };

  const downloadTemplate = () => {
    setLoading(true);
    fetch(`${basePath()}/contact/download-excel-template`, {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        setLoading(false);
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `Contacts Upload Sample.xlsx`;
          link.click();
        });
      })
      .catch((error) => {
        setLoading(false);
        if (error.message) {
          getToastError(error.message);
        } else {
          getToastError("Something went wrong");
        }
      });
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (file) {
      let formData = new FormData();
      formData.append("excel-template", file);
      const response = await contactUploadPost(formData);

      try {
        setLoading(false);
        if (response.success) {
          getToastSuccess(response.message);
          navigate("/contact/list");
        } else {
          getToastError(response.message);
        }
      } catch (error) {
        setLoading(false);
        getToastError(error.response.data.message);
      }
    } else {
      getToastError("Please provide a file!");
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container sx={{ mb: 2 }}>
          <Grid item>
            <TextField
              type="file"
              inputProps={{ accept: ".xlsx, .xls, .csv" }}
              name="file"
              id="file"
              ref={inputFile}
              onChange={handleChangeFile}
            />
          </Grid>
          <Grid
            item
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            {file && (
              <>
                <Button
                  sx={{ ml: 2 }}
                  variant="contained"
                  color="error"
                  component="span"
                  onClick={() => handleRemove()}
                >
                  <Delete mr={2} /> Remove
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <Link
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={downloadTemplate}
        >
          {" "}
          <Typography> Click here to download sample file</Typography>
        </Link>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={!file}
        >
          Upload
        </LoadingButton>
        <Button sx={{ mt: 3, ml: 1 }} onClick={() => navigate("/contact/list")}>
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function ContactUpload() {
  return (
    <React.Fragment>
      <Helmet title="Contact Upload" />

      <Typography variant="h3" gutterBottom display="inline">
        Contact Upload
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/contact/list">
          Contacts
        </Link>
        <Typography> Upload</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <UploadForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ContactUpload;
