import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import "../../../styles/quil.css";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  InputLabel,
  Autocomplete,
  createFilterOptions,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import {
  CloudUpload as MuiCloudUpload,
  Delete,
  FileCopy,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { filePost } from "../../../requests/file";
import { categoryAllGet } from "../../../requests/category";
import { LoadingButton } from "@mui/lab";
import { blogAllGet } from "../../../requests/blog";
import { postCreatePost } from "../../../requests/post";
import { authorAllGet } from "../../../requests/author";
import { getToastError, getToastSuccess } from "../../../utils/toasts";
import { File } from "react-feather";
import { urlValidate } from "../../../validation/validation";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function CreateForm() {
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState({});
  const [inputValue, setInputValue] = useState("");

  const [blogUUID, setBlogUUID] = useState("");

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [inputValueCategory, setInputValueCategory] = useState("");

  const [authors, setAuthors] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [inputValueAuthor, setInputValueAuthor] = useState("");
  const [authorId, setAuthorId] = useState(0);

  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");

  const [link, setLink] = useState("");
  const [linkErrorText, setLinkErrorText] = useState("");
  const [hasLinkError, setHasLinkError] = useState(false);

  const [active, setActive] = useState(true);
  const [featured, setFeatured] = useState(true);

  const filter = createFilterOptions();
  const [keywordOptions, setKeywordOptions] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [inputValueKeyword, setInputValueKeyword] = useState("");

  const [tagOptions, setTagOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const [inputValueTag, setInputValueTag] = useState("");

  const inputFile = useRef(null);
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);

  const inputSecondFile = useRef(null);
  const [secondFilePreview, setSecondFilePreview] = useState("");
  const [secondFile, setSecondFile] = useState(null);

  const [loading, setLoading] = useState(false);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"], // toggled buttons

      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ size: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ image }],
      [
        {
          color: [
            "#0B695E",
            "#219B89",
            "#84EFAF",
            "#C1F4D5",
            "#0C4EAD",
            "#00243D",
            "#C7FFDE",
            "#043438",
            "#97C6FF",
            "#4292E2",
            "#F7F9FC",
            "#E4E4E4",
            "#838B90",
            "#586269",
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
          ],
        },
      ], // dropdown with defaults from theme
      [
        {
          font: [],
        },
      ],
    ],
  };

  const formats = [
    "header",
    "image",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "color",
  ];

  useEffect(() => {
    getAllBlogs();
  }, []);

  useEffect(() => {
    if (blogUUID !== "") {
      getAllCategories();
      getAllAuthors();
    }
  }, [blogUUID, inputValueCategory, inputValueAuthor]);

  const getAllBlogs = async () => {
    setLoading(true);
    const responses = await blogAllGet();
    try {
      setLoading(false);

      if (responses.success) {
        setBlogs(responses.data);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllAuthors = async () => {
    setLoading(true);
    const responses = await authorAllGet({
      pageNo: 0,
      pageSize: 500,
      name: inputValueAuthor.length > 0 ? inputValueAuthor : null,
      blogUUID,
    });
    try {
      setLoading(false);

      if (responses.success) {
        setAuthors(responses.data.content);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllCategories = async () => {
    setLoading(true);
    const responses = await categoryAllGet({
      pageNo: 0,
      pageSize: 500,
      title: inputValueCategory.length > 0 ? inputValueCategory : null,
      blogUUID,
    });
    try {
      setLoading(false);

      if (responses.success) {
        setCategories(responses.data.content);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "subTitle":
        setSubTitle(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "link":
        if (value.trim()) {
          let error = !urlValidate.test(value);
          let errorText = error ? "Please provide valid URL" : "";
          setHasLinkError(error);
          setLinkErrorText(errorText);
        } else {
          setHasLinkError(false);
          setLinkErrorText("");
        }
        setLink(value);
        break;
      case "active":
        setActive(value);
        break;
      case "featured":
        setFeatured(value);
        break;
      default:
        break;
    }
  };

  const handleChangeEditor = (value) => {
    setContent(value);
  };

  const handleChangeFile = (event) => {
    const { name, files } = event.target;
    switch (name) {
      case "file":
        if (files[0] != null) {
          setImage(URL.createObjectURL(files[0]));
          setFile(files[0]);
        }
        break;
      case "secondFile":
        if (files[0] != null) {
          setSecondFilePreview(URL.createObjectURL(files[0]));
          setSecondFile(files[0]);
        }
        break;
      default:
        break;
    }
  };

  const handleUpload = (fileName) => {
    switch (fileName) {
      case "file":
        inputFile.current.click();
        break;

      case "secondFile":
        inputSecondFile.current.click();
        break;

      default:
        break;
    }
  };

  const handleRemove = (fileName) => {
    switch (fileName) {
      case "file":
        setFile(null);
        setImage("");
        break;

      case "secondFile":
        setSecondFile(null);
        setSecondFilePreview("");
        break;

      default:
        break;
    }
  };

  const handleSubmit = async () => {
    let coverImageFileId = null;
    let fileId = null;
    setLoading(true);
    if (file) {
      let formData = new FormData();
      formData.append("file", file);
      const response = await filePost(formData, "POST_COVER_IMAGE", blogUUID);

      try {
        if (response.success) {
          coverImageFileId = response.data.id;
        } else {
          setLoading(false);
          getToastError(response.message);
        }
      } catch (error) {
        setLoading(false);
        getToastError(error.response.data.message);
      }
    }
    if (secondFile) {
      let formData = new FormData();
      formData.append("file", secondFile);
      const response = await filePost(formData, "POST_FILE", blogUUID);

      try {
        if (response.success) {
          fileId = response.data.id;
        } else {
          setLoading(false);
          getToastError(response.message);
        }
      } catch (error) {
        setLoading(false);
        getToastError(error.response.data.message);
      }
    }
    handleCreate(coverImageFileId, fileId);
  };

  const handleCreate = async (coverImageFileId, fileId) => {
    setLoading(true);
    var categoryIdArray = selectedCategories.map(function (cat) {
      return cat.id;
    });
    const postData = {
      blogUUID,
      authorId: authorId === 0 ? null : authorId,
      categoryIds: categoryIdArray,
      content,
      description,
      coverImageFileId,
      fileId,
      title,
      subTitle,
      link,
      tags,
      featured,
      keywords,
      active,
    };
    const responses = await postCreatePost(postData);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate({
          pathname: `/blog/post/list`,
          search: `?blogUUID=${blogUUID}`,
        });
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Create
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <InputLabel sx={{ mt: 2, mb: 1 }}>Image</InputLabel>
              </Grid>
              <Grid item xs={12}>
                {file ? <img style={{ height: "30vh" }} src={image} /> : ""}
              </Grid>
              <Grid item xs={12}>
                {file ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      onClick={() => handleUpload("file")}
                    >
                      <CloudUpload mr={2} /> Change
                    </Button>
                    <Button
                      sx={{ ml: 1 }}
                      variant="contained"
                      color="error"
                      component="span"
                      onClick={() => handleRemove("file")}
                    >
                      <Delete mr={2} /> Remove
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={() => handleUpload("file")}
                  >
                    <CloudUpload mr={2} /> Upload
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept="image/*"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={handleChangeFile}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <InputLabel sx={{ mt: 2, mb: 1 }}>File (if any)</InputLabel>
              </Grid>
              <Grid item xs={12}>
                {secondFile ? (
                  secondFile.type.includes("image") ? (
                    <img
                      style={{ height: "30vh", marginBottom: 3 }}
                      src={secondFilePreview}
                    />
                  ) : (
                    <div style={{ marginBottom: 3 }}>
                      <FileCopy
                        color="primary"
                        sx={{ verticalAlign: "middle" }}
                      />{" "}
                      {secondFile.name}
                    </div>
                  )
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                {secondFile ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      onClick={() => handleUpload("secondFile")}
                    >
                      <CloudUpload mr={2} /> Change
                    </Button>
                    <Button
                      sx={{ ml: 1 }}
                      variant="contained"
                      color="error"
                      component="span"
                      onClick={() => handleRemove("secondFile")}
                    >
                      <Delete mr={2} /> Remove
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={() => handleUpload("secondFile")}
                  >
                    <CloudUpload mr={2} /> Upload
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                <input
                  type="file"
                  id="file"
                  name="secondFile"
                  ref={inputSecondFile}
                  style={{ display: "none" }}
                  onChange={handleChangeFile}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(event, newValue) => {
                if (newValue) {
                  if (newValue.uuid !== blogUUID) {
                    setSelectedAuthors([]);
                    setSelectedCategories([]);
                  }
                  setSelectedBlog(newValue);
                  setBlogUUID(newValue.uuid);
                } else {
                  setSelectedBlog({});
                  setBlogUUID("");
                  setSelectedAuthors([]);
                  setSelectedCategories([]);
                }
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={blogs}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label="Blog"
                  placeholder="Select Blog"
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Autocomplete
              disabled={blogUUID.length === 0}
              disablePortal
              value={selectedAuthors}
              id="combo-box-demo"
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedAuthors(newValue);
                  setAuthorId(newValue.id);
                } else {
                  setSelectedAuthors({});
                  setAuthorId(0);
                }
              }}
              onInputChange={(event, newInputValue) => {
                setInputValueAuthor(newInputValue);
              }}
              options={authors}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label="Select Author"
                  required
                />
              )}
            />
          </Grid>
        </Grid>
        <Autocomplete
          disabled={blogUUID.length === 0}
          disablePortal
          id="combo-box-demo"
          multiple
          value={selectedCategories}
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedCategories(newValue);
            } else {
              setSelectedCategories([]);
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValueCategory(newInputValue);
          }}
          options={categories}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              label="Select Category"
              required
            />
          )}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              id="title"
              name="title"
              label="Title"
              required
              variant="outlined"
              value={title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              id="subTitle"
              name="subTitle"
              label="Sub-Title"
              variant="outlined"
              value={subTitle}
              required
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              fullWidth
              margin="normal"
              id="link"
              name="link"
              label="URL"
              variant="outlined"
              value={link}
              error={hasLinkError}
              helperText={linkErrorText}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="featured-label">Is Featured?</InputLabel>
              <Select
                fullWidth
                margin="normal"
                labelId="featured-label"
                id="featured"
                name="featured"
                value={featured}
                label="Is Featured?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Featured</MenuItem>
                <MenuItem value={false}>Not-Featured</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="active-label">Is Active?</InputLabel>
              <Select
                fullWidth
                margin="normal"
                labelId="active-label"
                id="active"
                name="active"
                value={active}
                label="Is Active?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              multiple
              onChange={(event, newValue) => {
                setKeywords(newValue);
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== "") {
                  filtered.push(`${params.inputValue}`);
                }

                return filtered;
              }}
              onInputChange={(event, newInputValue) => {
                setInputValueKeyword(newInputValue);
              }}
              options={keywordOptions}
              getOptionLabel={(option) => {
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option;
              }}
              renderInput={(params) => (
                <TextField {...params} margin="normal" label="Keywords" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              multiple
              onChange={(event, newValue) => {
                setTags(newValue);
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== "") {
                  filtered.push(`${params.inputValue}`);
                }

                return filtered;
              }}
              onInputChange={(event, newInputValue) => {
                setInputValueTag(newInputValue);
              }}
              options={tagOptions}
              getOptionLabel={(option) => {
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option;
              }}
              renderInput={(params) => (
                <TextField {...params} margin="normal" label="Tags" />
              )}
            />
          </Grid>
        </Grid>

        <TextField
          fullWidth
          margin="normal"
          id="description"
          multiline
          rows={4}
          name="description"
          label="Description"
          variant="outlined"
          value={description}
          onChange={handleChange}
        />

        <InputLabel sx={{ mt: 2, mb: 1 }}>Content*</InputLabel>
        <ReactQuill
          className="ql-editor"
          value={content}
          placeholder="Write Content Here..."
          modules={modules}
          formats={formats}
          onChange={handleChangeEditor}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={
            title === "" ||
            subTitle === "" ||
            blogUUID === "" ||
            selectedCategories.length === 0 ||
            authorId === 0 ||
            content.length === 0 ||
            hasLinkError
          }
        >
          Create
        </LoadingButton>
        <Button
          sx={{ mt: 3, ml: 1 }}
          onClick={() => navigate("/blog/post/list")}
        >
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function PostCreate() {
  return (
    <React.Fragment>
      <Helmet title="Post Create" />

      <Typography variant="h3" gutterBottom display="inline">
        Post Create
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/blog/post/list">
          Posts
        </Link>
        <Typography> Create</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CreateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PostCreate;
