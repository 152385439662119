import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  Alert,
  CircularProgress,
  TextField,
  Card,
  CardContent,
  Autocomplete,
  Chip,
  Stack,
} from "@mui/material";
import { Add as AddIcon, Delete, Edit, Search } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { postAllGet, postByIdDelete } from "../../../requests/post";
import { blogAllGet } from "../../../requests/blog";
import { ConfirmationDialog } from "../../../components/dialogs/ConfirmationDialog";
import { authorAllGet } from "../../../requests/author";
import { categoryAllGet } from "../../../requests/category";
import Menu from "@mui/material/Menu";
import { getToastError, getToastSuccess } from "../../../utils/toasts";
import { useSelector } from "react-redux";
import moment from "moment";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);
function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [blogUUID, setBlogUUID] = useState("");

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [inputValueCategory, setInputValueCategory] = useState("");
  const [categoryId, setCategoryId] = useState(0);

  const [authors, setAuthors] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [inputValueAuthor, setInputValueAuthor] = useState("");
  const [authorId, setAuthorId] = useState(0);
  const [anchorAuthor, setAnchorAuthor] = useState(null);
  const openAuthor = Boolean(anchorAuthor);

  const [posts, setPosts] = useState([]);

  const [title, setTitle] = useState("");
  const [anchorTitle, setAnchorTitle] = useState(null);
  const openTitle = Boolean(anchorTitle);

  const [subTitle, setSubTitle] = useState("");
  const [anchorSubTitle, setAnchorSubTitle] = useState(null);
  const openSubTitle = Boolean(anchorSubTitle);

  const [tag, setTag] = useState("");
  const [anchorTag, setAnchorTag] = useState(null);
  const openTag = Boolean(anchorTag);

  const [keyword, setKeyword] = useState("");
  const [anchorKeyword, setAnchorKeyword] = useState(null);
  const openKeyword = Boolean(anchorKeyword);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [postId, setPostId] = useState(0);
  useEffect(() => {
    if (location.search && location.search.length > 0) {
      if (location.search.split("=").length === 2) {
        setBlogUUID(location.search.split("=")[1]);
      }
    }
    getAllBlogs();
  }, []);

  useEffect(() => {
    if (blogUUID.length > 0) {
      let blogIndex = blogs.findIndex((blog) => blog.uuid === blogUUID);
      if (blogIndex !== -1) {
        setSelectedBlog(blogs[blogIndex]);
      }
    }
  }, [blogs, blogUUID]);
  useEffect(() => {
    if (blogUUID !== "") {
      getAllCategories();
      getAllAuthors();
    }
  }, [blogUUID, inputValueCategory, inputValueAuthor]);

  useEffect(() => {
    if (blogUUID !== "") {
      getAllPosts();
    }
  }, [
    blogUUID,
    pageNo,
    pageSize,
    authorId,
    categoryId,
    title,
    subTitle,
    tag,
    keyword,
  ]);

  const getAllBlogs = async () => {
    setLoading(true);
    const responses = await blogAllGet();
    try {
      setLoading(false);

      if (responses.success) {
        let blog = responses.data;
        setBlogs(blog);
        if (blog.length > 0 && blogUUID.length === 0 && !location.search) {
          setBlogUUID(blog[0].uuid);
        }
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllAuthors = async () => {
    setLoading(true);
    const responses = await authorAllGet({
      pageNo: 0,
      pageSize: 500,
      name: inputValueAuthor.length > 0 ? inputValueAuthor : null,
      blogUUID,
    });
    try {
      setLoading(false);

      if (responses.success) {
        setAuthors(responses.data.content);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllCategories = async () => {
    setLoading(true);
    const responses = await categoryAllGet({
      pageNo: 0,
      pageSize: 500,
      title: inputValueCategory.length > 0 ? inputValueCategory : null,
      blogUUID,
    });
    try {
      setLoading(false);

      if (responses.success) {
        setCategories(responses.data.content);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllPosts = async () => {
    setLoading(true);
    const responses = await postAllGet({
      pageNo,
      pageSize,
      blogUUID,
      authorId: authorId !== 0 ? authorId : null,
      categoryId: categoryId !== 0 ? categoryId : null,
      title: title.length > 0 ? title : null,
      subTitle: subTitle.length > 0 ? subTitle : null,
      keyword: keyword.length > 0 ? keyword : null,
      tag: tag.length > 0 ? tag : null,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setPosts(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "title":
        setAnchorTitle(event.currentTarget);
        break;
      case "subTitle":
        setAnchorSubTitle(event.currentTarget);
        break;
      case "author":
        setAnchorAuthor(event.currentTarget);
        break;
      case "keyword":
        setAnchorKeyword(event.currentTarget);
        break;
      case "tag":
        setAnchorTag(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "title":
        setAnchorTitle(null);
        break;
      case "subTitle":
        setAnchorSubTitle(null);
        break;
      case "author":
        setAnchorAuthor(null);
        break;
      case "keyword":
        setAnchorKeyword(null);
        break;
      case "tag":
        setAnchorTag(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "subTitle":
        setSubTitle(value);
        break;
      case "keyword":
        setKeyword(value);
        break;
      case "tag":
        setTag(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (postId) => {
    setConfirmationOpen(true);
    setPostId(postId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setPostId(0);
  };
  const deletePost = async () => {
    setLoading(true);
    const responses = await postByIdDelete(postId, blogUUID);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllPosts();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id, uuid) => {
    navigate(`/blog/post/update/${id}/${uuid}`);
  };

  return (
    <div style={{ width: "100%", display: "table", tableLayout: "fixed" }}>
      <Card mb={6}>
        <CardContent pb={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={
                  selectedBlog && Object.keys(selectedBlog).length > 0
                    ? selectedBlog
                    : null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    if (newValue.uuid !== blogUUID) {
                      setAuthorId(0);
                      setSelectedAuthors([]);
                      setCategoryId(0);
                      setSelectedCategories([]);
                    }
                    setSelectedBlog(newValue);
                    setBlogUUID(newValue.uuid);
                  } else {
                    setSelectedBlog({});
                    setBlogUUID("");
                    setAuthorId(0);
                    setSelectedAuthors([]);
                    setCategoryId(0);
                    setSelectedCategories([]);
                    setPosts([]);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={blogs}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    size="small"
                    label="Filter By Blog"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                disabled={blogUUID.length === 0}
                disablePortal
                value={selectedAuthors}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedAuthors(newValue);
                    setAuthorId(newValue.id);
                  } else {
                    setSelectedAuthors({});
                    setAuthorId(0);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValueAuthor(newInputValue);
                }}
                options={authors}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    size="small"
                    label="Filter By Author"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                disabled={blogUUID.length === 0}
                disablePortal
                value={selectedCategories}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedCategories(newValue);
                    setCategoryId(newValue.id);
                  } else {
                    setSelectedCategories({});
                    setCategoryId(0);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValueCategory(newInputValue);
                }}
                options={categories}
                getOptionLabel={(option) => (option.title ? option.title : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    size="small"
                    label="Filter By Category"
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Paper>
          <TableContainer>
            <Grid
              container
              display={loading ? "flex" : "none"}
              justifyContent="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    Sl. No.
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Title
                    <IconButton onClick={(e) => handleClick(e, "title")}>
                      <Search
                        fontSize="small"
                        color={title.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorTitle}
                    open={openTitle}
                    onClose={() => handleCloseMenu("title")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="title"
                      name="title"
                      value={title}
                      onChange={handleChange}
                      placeholder="Search Title"
                    />
                  </Menu>
                  <TableCell
                    align="left"
                    padding="normal"
                    sx={{ width: "15%" }}
                  >
                    Sub-Title
                    <IconButton onClick={(e) => handleClick(e, "subTitle")}>
                      <Search
                        fontSize="small"
                        color={subTitle.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorSubTitle}
                    open={openSubTitle}
                    onClose={() => handleCloseMenu("subTitle")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="subTitle"
                      name="subTitle"
                      value={subTitle}
                      onChange={handleChange}
                      placeholder="Search Sub-Title"
                    />
                  </Menu>
                  <TableCell align="left" padding="normal">
                    Author{" "}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Categories{" "}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Keywords
                    <IconButton onClick={(e) => handleClick(e, "keyword")}>
                      <Search
                        fontSize="small"
                        color={keyword.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorKeyword}
                    open={openKeyword}
                    onClose={() => handleCloseMenu("keyword")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="keyword"
                      name="keyword"
                      value={keyword}
                      onChange={handleChange}
                      placeholder="Search Keyword"
                    />
                  </Menu>
                  <TableCell
                    align="left"
                    padding="normal"
                    sx={{ width: "10%" }}
                  >
                    Tags
                    <IconButton onClick={(e) => handleClick(e, "tag")}>
                      <Search
                        fontSize="small"
                        color={tag.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorTag}
                    open={openTag}
                    onClose={() => handleCloseMenu("tag")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="tag"
                      name="tag"
                      value={tag}
                      onChange={handleChange}
                      placeholder="Search Tag"
                    />
                  </Menu>
                  {/* <br/>
          <TextField size="small" placeholder="Search email"/> */}

                  <TableCell align="left" padding="normal">
                    Image
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Creation Date & Time
                  </TableCell>
                  {privileges.includes("BLOG_UPDATE") ||
                  privileges.includes("BLOG_DELETE") ? (
                    <TableCell align="left" padding="normal">
                      Actions
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {posts && posts.length > 0 ? (
                  posts.map((post, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={post.id}
                      >
                        <TableCell align="left">
                          {pageNo * pageSize + index + 1}.
                        </TableCell>
                        <TableCell align="left">{post.title}</TableCell>
                        <TableCell align="left" sx={{ width: "15%" }}>
                          {post.subTitle}
                        </TableCell>
                        <TableCell align="left">
                          {post.author && post.author.name
                            ? post.author.name
                            : "No Author"}
                        </TableCell>
                        <TableCell align="left">
                          {post.categories.map((category) => {
                            return (
                              <Chip
                                label={category.title}
                                sx={{ m: 1 }}
                                // color="primary"
                              />
                            );
                          })}
                        </TableCell>
                        <TableCell align="left">
                          {post.keywords.map((keyword) => {
                            return (
                              <Chip
                                label={keyword}
                                sx={{ m: 1 }}
                                color="primary"
                              />
                            );
                          })}
                        </TableCell>
                        <TableCell align="left">
                          {post.tags.map((tag) => {
                            return (
                              <Chip
                                label={tag}
                                sx={{ m: 1 }}
                                color="secondary"
                              />
                            );
                          })}
                        </TableCell>
                        <TableCell align="left" sx={{ borderRadius: "5px" }}>
                          {post.coverImg ? (
                            <img
                              style={{
                                height: "150px",
                                width: "150px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                              src={post.coverImg.fileUrl}
                            />
                          ) : (
                            "No Image"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {post.creationDate && post.creationDate !== 0
                            ? `${moment
                                .unix(post.creationDate)
                                .format("Do MMMM YYYY, h:mm a")}`
                            : "No date found"}
                        </TableCell>
                        <TableCell padding="none" align="left">
                          <Stack direction="row" mr={2}>
                            {privileges.includes("BLOG_UPDATE") && (
                              <IconButton
                                aria-label="details"
                                size="large"
                                onClick={() =>
                                  goEditPage(post.id, post.blogUUID)
                                }
                              >
                                <Edit color="primary" />
                              </IconButton>
                            )}
                            {privileges.includes("BLOG_DELETE") && (
                              <IconButton
                                aria-label="delete"
                                size="large"
                                onClick={() => handleDelete(post.id)}
                              >
                                <Delete sx={{ color: "#d93232" }} />
                              </IconButton>
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={11}>
                      No Posts Found for this Blog!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={pageSize}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>
      <ConfirmationDialog
        title="Delete Post"
        text="Are you sure that you want to delete this post?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deletePost}
      />
    </div>
  );
}

function PostList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Posts" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Posts
          </Typography>
        </Grid>
        {privileges.includes("BLOG_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/blog/post/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Post
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Posts</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PostList;
