import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Autocomplete, TextField } from "@mui/material";
import styled from "styled-components/macro";
import { roleAllGet } from "../../requests/role";
import { getToastError } from "../../utils/toasts";

export default function UpdateUserDetailsDialog(props) {
  const { open, userDetails } = props;
  const [userId, setUserId] = useState(0);
  const [name, setName] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    getAllRoles();
  }, []);

  useEffect(() => {
    if (userDetails && Object.keys(userDetails).length > 0) {
      setUserId(userDetails.id);
      setName(userDetails.name);
      if (userDetails.roles && userDetails.roles.length > 0) {
        setRoleId(userDetails.roles[0].id);
        setSelectedRole(userDetails.roles[0]);
      }
    }
  }, [userDetails, roles]);

  const getAllRoles = async () => {
    const responses = await roleAllGet({
      roleType: "ADMIN",
    });
    try {
      if (responses.success) {
        setRoles(responses.data);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    const postData = {
      name,
      roleId,
      userId,
    };
    props.handleSubmit(postData);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Update Admin Details"}
      </DialogTitle>

      <DialogContent sx={{ minWidth: "350px", minHeight: "380px" }}>
        <TextField
          fullWidth
          margin="normal"
          id="name"
          required
          name="name"
          label="Name"
          variant="outlined"
          value={name}
          onChange={handleChange}
        />

        <Autocomplete
          disablePortal
          fullWidth
          id="combo-box-demo"
          value={selectedRole}
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedRole(newValue);
              setRoleId(newValue.id);
            } else {
              setRoleId(0);
              setSelectedRole([]);
            }
          }}
          options={roles}
          getOptionLabel={(option) => option.roleName}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} required margin="normal" label="Role" />
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button
          mr={2}
          variant="contained"
          size={"small"}
          disabled={name === "" || roleId === 0}
          onClick={() => handleSubmit()}
        >
          Update
        </Button>
        <Button variant="text" size={"small"} onClick={props.handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
