import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignUpComponent from "../../components/auth/SignUp";
import { useNavigate } from "react-router-dom";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignUp() {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/private");
    }
  }, []);
  return (
    <React.Fragment>
      {!localStorage.getItem("token") && (
        <>
          <Brand />
          <Wrapper>
            <Helmet title="Sign Up" />

            <Typography component="h1" variant="h4" align="center" gutterBottom>
              Get started
            </Typography>
            <Typography component="h2" variant="body1" align="center">
              Start creating the best possible user experience for you customers
            </Typography>

            <SignUpComponent />
          </Wrapper>
        </>
      )}
    </React.Fragment>
  );
}

export default SignUp;
