import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  TextField,
  Card,
  CardContent,
  Autocomplete,
  IconButton,
  Menu,
  Breadcrumbs,
  Link,
  Chip,
} from "@mui/material";
import { spacing } from "@mui/system";
import { getToastError } from "../../utils/toasts";
import { allIslamicBequestsGet, programAllGet } from "../../requests/history";
import { Search } from "@mui/icons-material";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable() {
  const navigate = useNavigate();
  const [bequests, setBequests] = useState([]);

  const [programs, setPrograms] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState({});
  const [inputValueProgram, setInputValueProgram] = useState("");
  const [programId, setProgramId] = useState(0);

  const [testatorEmail, setTestatorEmail] = useState("");
  const [anchorTestatorEmail, setAnchorTestatorEmail] = useState(null);
  const openTestatorEmail = Boolean(anchorTestatorEmail);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllPrograms();
  }, [inputValueProgram]);

  useEffect(() => {
    getAllBequests();
  }, [programId, testatorEmail, pageNo, pageSize]);

  const getAllPrograms = async () => {
    const responses = await programAllGet({
      pageNo: 0,
      pageSize: 50,
    });
    try {
      if (responses.success) {
        setPrograms(responses.data.content);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };

  const getAllBequests = async () => {
    setLoading(true);
    const responses = await allIslamicBequestsGet({
      page_no: pageNo,
      page_size: pageSize,
      programId: programId > 0 ? programId : null,
      testatorEmail: testatorEmail.length > 0 ? testatorEmail : null,
      sort_by: "id",
      asc_or_desc: "desc",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setBequests(responses.data.content);
        setTotalItems(responses.data.totalElements);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.responses.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "testatorEmail":
        setAnchorTestatorEmail(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "testatorEmail":
        setAnchorTestatorEmail(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "testatorEmail":
        setTestatorEmail(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  return (
    <div>
      <Card mb={6}>
        <CardContent pb={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                fullWidth
                disablePortal
                id="combo-box-demo"
                size="small"
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedPrograms(newValue);
                    setProgramId(newValue.id);
                  } else {
                    setSelectedPrograms({});
                    setProgramId(0);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValueProgram(newInputValue);
                }}
                options={programs}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    size={"small"}
                    label="Filter By Program"
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Paper>
          <TableContainer>
            <Grid
              container
              display={loading ? "flex" : "none"}
              justifyContent="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    Sl. No.
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {" "}
                    Name
                  </TableCell>{" "}
                  <TableCell align="left" padding="normal">
                    Email
                    <IconButton
                      onClick={(e) => handleClick(e, "testatorEmail")}
                    >
                      <Search
                        fontSize="small"
                        color={testatorEmail.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorTestatorEmail}
                    open={openTestatorEmail}
                    onClose={() => handleCloseMenu("testatorEmail")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="testatorEmail"
                      name="testatorEmail"
                      value={testatorEmail}
                      onChange={handleChange}
                      placeholder="Search Email"
                    />
                  </Menu>
                  <TableCell align="left" padding="normal">
                    Nonprofit
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Program
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Percentage of Asset
                  </TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {bequests && bequests.length > 0 ? (
                  bequests.map((beq, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={beq.id}
                      >
                        <TableCell align="left">
                          {pageNo * pageSize + index + 1}.
                        </TableCell>
                        <TableCell align="left">
                          {beq?.userWills?.name}
                        </TableCell>
                        <TableCell align="left">{beq.testatorEmail}</TableCell>
                        <TableCell align="left">
                          {beq?.nonProfit?.name}
                        </TableCell>
                        <TableCell align="left">{beq?.program?.name}</TableCell>

                        <TableCell align="left">
                          {" "}
                          {beq.percentage + "%"}
                        </TableCell>
                        <TableCell align="left">
                          {beq.userWills && beq.userWills.creationDateTimeStamp
                            ? moment
                                .unix(beq.userWills.creationDateTimeStamp)
                                .format("Do MMMM YYYY, h:mm a")
                            : "No Date"}
                        </TableCell>
                        <TableCell align="center">
                          {beq.bequestStatus && (
                            <Chip
                              color={
                                beq.bequestStatus.toLowerCase() === "contacted"
                                  ? "primary"
                                  : beq.bequestStatus.toLowerCase() ===
                                    "not_yet_signed"
                                  ? "warning"
                                  : "success"
                              }
                              label={
                                beq.bequestStatus
                                  .replace(/_/g, " ")
                                  .charAt(0)
                                  .toUpperCase() +
                                beq.bequestStatus
                                  .replace(/_/g, " ")
                                  .slice(1)
                                  .toLowerCase()
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>No Bequest History Found!</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={pageSize}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>
    </div>
  );
}

function BequestHistoryList() {
  return (
    <React.Fragment>
      <Helmet title="Bequest History" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Bequest History
          </Typography>
        </Grid>
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Bequest History</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default BequestHistoryList;
