import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Alert,
  Avatar as MuiAvatar,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import styled from "styled-components/macro";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { DatePicker } from "@mui/lab";
import { useSelector } from "react-redux";
import moment from "moment";
import { myDetailsGet } from "../../requests/user";

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;
const Centered = styled.div`
  text-align: center;
`;

export default function UploadProfileDetailsDialog(props) {
  const { open } = props;
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberRegion, setPhoneNumberRegion] = useState("");
  const [text, setText] = useState("");
  const [variant, setVariant] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    getCurrentUser();
  }, []);
  const getCurrentUser = async () => {
    const response = await myDetailsGet();
    if (response.success) {
      const userData = response.data;
      setName(userData.name);
      setGender(userData.gender ?? "");
      setAddress(userData.address ?? "");
      setPhoneNumber(userData.phoneNumber ?? "");
      setDateOfBirth(userData.dateOfBirth);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "gender":
        setGender(value);
        break;
      case "address":
        setAddress(value);
        break;
      default:
        break;
    }
  };
  const handleOnChangePhone = (value, event) => {
    setPhoneNumber(value);
    setPhoneNumberRegion(event.countryCode);
    // console.log(value, event);
  };
  const handleSubmit = async () => {
    const postData = {
      name,
      address: address,
      gender: gender ? gender : null,
      phoneNumberRegion,
      phoneNumber: phoneNumber
        ? phoneNumber.split("")[0] === "+"
          ? phoneNumber
          : `+${phoneNumber}`
        : null,
      dateOfBirth: dateOfBirth ? moment(dateOfBirth).unix() : null,
    };
    props.handleSubmit(postData);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Update Profile Details"}
      </DialogTitle>

      <DialogContent sx={{ minWidth: "350px" }}>
        {text.length > 0 && !loading ? (
          <Alert mt={2} mb={3} severity={variant}>
            {text}
          </Alert>
        ) : (
          ""
        )}
        <TextField
          fullWidth
          margin="normal"
          id="name"
          required
          name="name"
          label="Name"
          variant="outlined"
          value={name}
          onChange={handleChange}
        />

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6} sm={6} md={12}>
            <DatePicker
              label="Date of Birth"
              value={dateOfBirth}
              maxDate={new Date()}
              onChange={(newValue) => {
                setDateOfBirth(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth margin="normal" />
              )}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={12}>
            <FormControl m={2} margin="normal" sx={{ width: "100%" }}>
              <InputLabel id="gender">Gender</InputLabel>
              <Select
                fullWidth
                labelId="gender"
                id="gender"
                value={gender}
                name="gender"
                label="Gender"
                onChange={handleChange}
              >
                <MenuItem value={"MALE"}>Male</MenuItem>
                <MenuItem value={"FEMALE"}>Female</MenuItem>
                <MenuItem value={"OTHER"}>Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "15px" }}>
          <PhoneInput
            inputStyle={{
              borderColor: "#7700FF",
              borderRadius: "22px",
              width: "100%",
            }}
            buttonStyle={{ borderRadius: "22px 0 0 22px" }}
            country={"us"}
            value={phoneNumber}
            name="phoneNumber"
            placeholder="Phone Number"
            onChange={(value, event) => handleOnChangePhone(value, event)}
          />
        </Grid>

        <TextField
          fullWidth
          margin="normal"
          id="address"
          multiline
          rows={2}
          name="address"
          label="Address"
          variant="outlined"
          value={address}
          onChange={handleChange}
        />
      </DialogContent>

      <DialogActions>
        <Button
          mr={2}
          variant="contained"
          size={"small"}
          disabled={name === ""}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
        <Button variant="text" size={"small"} onClick={props.handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
