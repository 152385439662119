import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  Menu,
  TextField,
  Chip,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { getToastError } from "../../utils/toasts";
import moment from "moment";
import { allProductsGet, willAllGet } from "../../requests/history";
import { NavLink, useNavigate } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable() {
  const navigate = useNavigate();
  const [wills, setWills] = useState([]);

  const [willTag, setWillTag] = useState("all");
  const [products, setProducts] = useState([]);

  const [testatorEmail, setTestatorEmail] = useState("");
  const [anchorTestatorEmail, setAnchorTestatorEmail] = useState(null);
  const openTestatorEmail = Boolean(anchorTestatorEmail);

  const [name, setName] = useState("");
  const [anchorName, setAnchorName] = useState(null);
  const openName = Boolean(anchorName);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    getAllWills();
  }, [testatorEmail, name, willTag, pageNo, pageSize]);

  const getAllProducts = async () => {
    setLoading(true);
    const responses = await allProductsGet();
    try {
      setLoading(false);

      if (responses.success) {
        setProducts(responses.data);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllWills = async () => {
    setLoading(true);
    const responses = await willAllGet({
      pageNo,
      pageSize,
      testatorEmail: testatorEmail.length > 0 ? testatorEmail : null,
      name: name.length > 0 ? name : null,
      willTag: willTag !== "all" ? willTag : null,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setWills(responses.data.content);
        setTotalItems(responses.data.totalElements);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "testatorEmail":
        setAnchorTestatorEmail(event.currentTarget);
        break;
      case "name":
        setAnchorName(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "testatorEmail":
        setAnchorTestatorEmail(null);
        break;
      case "name":
        setAnchorName(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "testatorEmail":
        setTestatorEmail(value);
        break;
      case "name":
        setName(value);
        break;
      case "willTag":
        setWillTag(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  return (
    <div>
      <Card mb={6}>
        <CardContent pb={1}>
          {/* <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Filter by Will Name
                </InputLabel>
                <Select
                  fullWidth
                  name="willTag"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={willTag}
                  label="Filter by Will Name"
                  onChange={handleChange}
                  autoFocus
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  {products.map((pro, index) => (
                    <MenuItem key={index} value={pro.tag}>
                      {pro.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid> */}
        </CardContent>
        <Paper>
          <TableContainer>
            <Grid
              container
              display={loading ? "flex" : "none"}
              justifyContent="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    Sl. No.
                  </TableCell>

                  <TableCell align="left" padding="normal">
                    {" "}
                    Name
                    <IconButton onClick={(e) => handleClick(e, "name")}>
                      <Search
                        fontSize="small"
                        color={name.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorName}
                    open={openName}
                    onClose={() => handleCloseMenu("name")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="name"
                      name="name"
                      value={name}
                      onChange={handleChange}
                      placeholder="Search Name"
                    />
                  </Menu>
                  <TableCell align="left" padding="normal">
                    {" "}
                    Email
                    <IconButton
                      onClick={(e) => handleClick(e, "testatorEmail")}
                    >
                      <Search
                        fontSize="small"
                        color={testatorEmail.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorTestatorEmail}
                    open={openTestatorEmail}
                    onClose={() => handleCloseMenu("testatorEmail")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="testatorEmail"
                      name="testatorEmail"
                      value={testatorEmail}
                      onChange={handleChange}
                      placeholder="Search Email"
                    />
                  </Menu>
                  <TableCell align="left" padding="normal">
                    Will Name
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Creation Date
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {" "}
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {wills && wills.length > 0 ? (
                  wills.map((will, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={will.id}
                      >
                        <TableCell align="left">
                          {pageNo * pageSize + index + 1}.
                        </TableCell>
                        <TableCell align="left">{will.name}</TableCell>
                        <TableCell align="left">{will.testatorEmail}</TableCell>
                        <TableCell align="left">{will.productName}</TableCell>
                        <TableCell align="left">
                          {will.creationDateTimeStamp !== 0
                            ? moment
                                .unix(will.creationDateTimeStamp)
                                .format("Do MMMM YYYY, h:mm a")
                            : "No Date"}
                        </TableCell>
                        <TableCell align="left">
                          <Chip color="success" label="Completed" />
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>No Will History Found!</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={pageSize}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>
    </div>
  );
}

function WillHistoryList() {
  return (
    <React.Fragment>
      <Helmet title="Will History" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Will History
          </Typography>
        </Grid>
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Will History</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default WillHistoryList;
