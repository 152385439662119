import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";
import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import EmailVerify from "./pages/auth/EmailVerify";
import ResetPasswordVerify from "./pages/auth/ResetPasswordVerify";

//Role Components
import RoleList from "./pages/role/RoleList";
import RoleCreate from "./pages/role/RoleCreate";
import RoleUpdate from "./pages/role/RoleUpdate";

//User Components
import UserList from "./pages/user/UserList";
import UserCreate from "./pages/user/UserCreate";

//Legal Docs Component
import LegalDocList from "./pages/legal_doc/LegalDocList";
import LegalDocUpdate from "./pages/legal_doc/LegalDocUpdate";

//Subscription Packages Component
import SubscriptionPackegesList from "./pages/subscription/SubscriptionPackegesList";
import SubscriptionPackegesCreate from "./pages/subscription/SubscriptionPackegesCreate";
import SubscriptionPackegesUpdate from "./pages/subscription/SubscriptionPackegesUpdate";

//Verification Docs Component
import VerificationDocsList from "./pages/verification-docs/VerificationDocsList";
import VerificationDocsCreate from "./pages/verification-docs/VerificationDocsCreate";
import VerificationDocsUpdate from "./pages/verification-docs/VerificationDocsUpdate";
import PendingVerificationDocsList from "./pages/pending-verification-docs/PendingVerificationDocs";

// Blog components
import BlogList from "./pages/blog/blog/BlogList";
import BlogCreate from "./pages/blog/blog/BlogCreate";
import BlogUpdate from "./pages/blog/blog/BlogUpdate";
import AuthorList from "./pages/blog/author/AuthorList";
import AuthorCreate from "./pages/blog/author/AuthorCreate";
import AuthorUpdate from "./pages/blog/author/AuthorUpdate";
import CategoryList from "./pages/blog/category/CategoryList";
import CategoryCreate from "./pages/blog/category/CategoryCreate";
import CategoryUpdate from "./pages/blog/category/CategoryUpdate";
import PostList from "./pages/blog/post/PostList";
import PostCreate from "./pages/blog/post/PostCreate";
import PostUpdate from "./pages/blog/post/PostUpdate";

//Organization Component
import OrganizationTypeList from "./pages/organizationType/OrganizationTypeList";
import OrganizationTypeCreate from "./pages/organizationType/OrganizationTypeCreate";
import OrganizationTypeUpdate from "./pages/organizationType/OrganizationTypeUpdate";
import NonProfitList from "./pages/non-profit/NonProfitList";
import NonProfitCreate from "./pages/non-profit/NonProfitCreate";
import NonProfitUpdate from "./pages/non-profit/NonProfitUpdate";

//Product Component
import ProductList from "./pages/product/ProductList";
import ProductCreate from "./pages/product/ProductCreate";
import ProductUpdate from "./pages/product/ProductUpdate";

//Scholar Component
import ScholarList from "./pages/scholar/ScholarList";
import ScholarCreate from "./pages/scholar/ScholarCreate";
import ScholarUpdate from "./pages/scholar/ScholarUpdate";

//Inquiry
import InquiryList from "./pages/inquiry/InquiryList";

//Contact Components
import ContactList from "./pages/contact/ContactList";
import ContactCreate from "./pages/contact/ContactCreate";
import ContactUpdate from "./pages/contact/ContactUpdate";
import ContactUpload from "./pages/contact/ContactUpload";

//Sent Email Components
import SentEmailList from "./pages/sent-email/SentEmailList";
import SentEmailCreate from "./pages/sent-email/SentEmailCreate";
import SentEmailView from "./pages/sent-email/SentEmailView";

//Page Management Component
import PageList from "./pages/page-management/page/PageList";
import PageCreate from "./pages/page-management/page/PageCreate";
import PageUpdate from "./pages/page-management/page/PageUpdate";
import SectionList from "./pages/page-management/section/SectionList";
import SectionCreate from "./pages/page-management/section/SectionCreate";
import SectionUpdate from "./pages/page-management/section/SectionUpdate";

// Components
import Accordion from "./pages/components/Accordion";
import Alerts from "./pages/components/Alerts";
import Avatars from "./pages/components/Avatars";
import Badges from "./pages/components/Badges";
import Buttons from "./pages/components/Buttons";
import Cards from "./pages/components/Cards";
import Chips from "./pages/components/Chips";
import Dialogs from "./pages/components/Dialogs";
import Lists from "./pages/components/Lists";
import Menus from "./pages/components/Menus";
import Pagination from "./pages/components/Pagination";
import Progress from "./pages/components/Progress";
import Snackbars from "./pages/components/Snackbars";
import Tooltips from "./pages/components/Tooltips";

// Form components
import SelectionCtrls from "./pages/forms/SelectionControls";
import Selects from "./pages/forms/Selects";
import TextFields from "./pages/forms/TextFields";

// Icon components
import MaterialIcons from "./pages/icons/MaterialIcons";

// Page components
import Blank from "./pages/pages/Blank";
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";
import Orders from "./pages/pages/Orders";
import Pricing from "./pages/pages/Pricing";
import Settings from "./pages/pages/Settings";
import Projects from "./pages/pages/Projects";
import Chat from "./pages/pages/Chat";

// Table components
import SimpleTable from "./pages/tables/SimpleTable";
import AdvancedTable from "./pages/tables/AdvancedTable";

// Documentation
import Welcome from "./pages/docs/Welcome";
import GettingStarted from "./pages/docs/GettingStarted";
import Routing from "./pages/docs/Routing";
import Auth0 from "./pages/docs/auth/Auth0";
import Cognito from "./pages/docs/auth/Cognito";
import Firebase from "./pages/docs/auth/Firebase";
import JWT from "./pages/docs/auth/JWT";
import Guards from "./pages/docs/Guards";
import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
import Deployment from "./pages/docs/Deployment";
import Theming from "./pages/docs/Theming";
import APICalls from "./pages/docs/APICalls";
import Redux from "./pages/docs/Redux";
import Internationalization from "./pages/docs/Internationalization";
import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
import Support from "./pages/docs/Support";
import Changelog from "./pages/docs/Changelog";

// Landing
import Landing from "./pages/presentation/Landing";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
import ChangePassword from "./pages/auth/ChangePassword";
import ProfileDetails from "./pages/profile/ProfileDetails";
import GlossaryList from "./pages/page-management/glossary/GlossaryList";
import GlossaryCreate from "./pages/page-management/glossary/GlossaryCreate";
import GlossaryUpdate from "./pages/page-management/glossary/GlossaryUpdate";
import TestimonialList from "./pages/page-management/testimonial/TestimonialList";
import TestimonialCreate from "./pages/page-management/testimonial/TestimonialCreate";
import TestimonialUpdate from "./pages/page-management/testimonial/TestimonialUpdate";
import CouponList from "./pages/coupon/CouponList";
import CouponCreate from "./pages/coupon/CouponCreate";
import CouponUpdate from "./pages/coupon/CouponUpdate";
import TransactionHistoryList from "./pages/history/TransactionHistoryList";
import WillHistoryList from "./pages/history/WillHistoryList";
import BequestHistoryList from "./pages/history/BequestHistoryList";
import NotificationList from "./pages/notification/NotificationList";

import HealthCareHistoryList from "./pages/history/HealthCareHistoryList";

import StockHistoryList from "./pages/history/StockHistoryList";
import PromoList from "./pages/promo/PromoList";
import PromoCreate from "./pages/promo/PromoCreate";
import PromoUpdate from "./pages/promo/PromoUpdate";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));

// Form components
const Pickers = async(() => import("./pages/forms/Pickers"));
const Editors = async(() => import("./pages/forms/Editors"));
const Formik = async(() => import("./pages/forms/Formik"));

// Icon components
const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
const Profile = async(() => import("./pages/pages/Profile"));
const Tasks = async(() => import("./pages/pages/Tasks"));
const Calendar = async(() => import("./pages/pages/Calendar"));

// Table components
const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("./pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const routes = [
  // {
  //   path: "/",
  //   element: <PresentationLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Landing />,
  //     },
  //   ],
  // },
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "default",
        element: <Default />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "saas",
        element: <SaaS />,
      },
    ],
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "chat",
        element: <Chat />,
      },
      {
        path: "blank",
        element: <Blank />,
      },
    ],
  },
  {
    path: "projects",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },
  {
    path: "invoices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <InvoiceList />,
      },
      {
        path: "detail",
        element: <InvoiceDetails />,
      },
    ],
  },
  {
    path: "orders",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Orders />,
      },
    ],
  },
  {
    path: "tasks",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Tasks />,
      },
    ],
  },
  {
    path: "calendar",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Calendar />,
      },
    ],
  },
  {
    path: "/profile",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProfileDetails />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      // {
      //   path: "sign-in",
      //   element: <SignIn />,
      // },
      // {
      //   path: "sign-up",
      //   element: <SignUp />,
      // },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      // {
      //   path: "reset-password/:token",
      //   element: <ResetPasswordVerify />,
      // },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
      // {
      //   path: "email-verify/:email",
      //   element: <EmailVerify />,
      // },
    ],
  },
  {
    path: "reset-password",
    element: <AuthLayout />,
    children: [
      {
        path: ":token",
        element: <ResetPasswordVerify />,
      },
    ],
  },
  {
    path: "confirm-account",
    element: <AuthLayout />,
    children: [
      {
        path: ":token",
        element: <EmailVerify />,
      },
    ],
  },
  {
    path: "auth",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "change-password",
        element: <ChangePassword />,
      },
    ],
  },

  {
    path: "role",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <RoleList />,
      },
      {
        path: "create",
        element: <RoleCreate />,
      },
      {
        path: "update/:id",
        element: <RoleUpdate />,
      },
    ],
  },

  {
    path: "user",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list/:roleType",
        element: <UserList />,
      },
      {
        path: "create",
        element: <UserCreate />,
      },
    ],
  },

  {
    path: "legal-docs",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <LegalDocList />,
      },
      {
        path: "update",
        element: <LegalDocUpdate />,
      },
    ],
  },

  {
    path: "subscription-packages",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <SubscriptionPackegesList />,
      },
      {
        path: "create",
        element: <SubscriptionPackegesCreate />,
      },
      {
        path: "update/:id",
        element: <SubscriptionPackegesUpdate />,
      },
    ],
  },

  {
    path: "coupon",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <CouponList />,
      },
      {
        path: "create",
        element: <CouponCreate />,
      },
      {
        path: "update/:id",
        element: <CouponUpdate />,
      },
    ],
  },

  {
    path: "promos",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <PromoList />,
      },
      {
        path: "create",
        element: <PromoCreate />,
      },
      {
        path: "update/:id",
        element: <PromoUpdate />,
      },
    ],
  },

  {
    path: "/transaction-history",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <TransactionHistoryList />,
      },
    ],
  },

  {
    path: "/will-history",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <WillHistoryList />,
      },
    ],
  },

  {
    path: "/bequest-history",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <BequestHistoryList />,
      },
    ],
  },

  {
    path: "/donate-stock-history",

    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",

        element: <StockHistoryList />,
      },
    ],
  },

  {
    path: "/healthcare-directive-history",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <HealthCareHistoryList />,
      },
    ],
  },

  {
    path: "verification-docs",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <VerificationDocsList />,
      },
      {
        path: "create",
        element: <VerificationDocsCreate />,
      },
      {
        path: "update/:id",
        element: <VerificationDocsUpdate />,
      },
      {
        path: "all",
        element: <PendingVerificationDocsList />,
      },
    ],
  },
  {
    path: "blog",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <BlogList />,
      },
      {
        path: "create",
        element: <BlogCreate />,
      },
      {
        path: "update/:uuid",
        element: <BlogUpdate />,
      },
      {
        path: "author/list",
        element: <AuthorList />,
      },
      {
        path: "author/create",
        element: <AuthorCreate />,
      },
      {
        path: "author/update/:id/:uuid",
        element: <AuthorUpdate />,
      },
      {
        path: "category/list",
        element: <CategoryList />,
      },
      {
        path: "category/create",
        element: <CategoryCreate />,
      },
      {
        path: "category/update/:id/:uuid",
        element: <CategoryUpdate />,
      },
      {
        path: "post/list",
        element: <PostList />,
      },
      {
        path: "post/create",
        element: <PostCreate />,
      },
      {
        path: "post/update/:id/:uuid",
        element: <PostUpdate />,
      },
    ],
  },

  {
    path: "organization",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "type/list",
        element: <OrganizationTypeList />,
      },
      {
        path: "type/create",
        element: <OrganizationTypeCreate />,
      },
      {
        path: "type/update/:id",
        element: <OrganizationTypeUpdate />,
      },
      {
        path: "non-profit/list",
        element: <NonProfitList />,
      },
    ],
  },

  {
    path: "product",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <ProductList />,
      },
      {
        path: "create",
        element: <ProductCreate />,
      },
      {
        path: "update/:id",
        element: <ProductUpdate />,
      },
    ],
  },

  {
    path: "scholar",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <ScholarList />,
      },
      {
        path: "create",
        element: <ScholarCreate />,
      },
      {
        path: "update/:id",
        element: <ScholarUpdate />,
      },
    ],
  },

  {
    path: "inquiry",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <InquiryList />,
      },
    ],
  },

  {
    path: "contact",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <ContactList />,
      },
      {
        path: "create",
        element: <ContactCreate />,
      },
      {
        path: "upload",
        element: <ContactUpload />,
      },
      {
        path: "update/:uuid",
        element: <ContactUpdate />,
      },
    ],
  },
  {
    path: "sent-email",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <SentEmailList />,
      },
      {
        path: "create",
        element: <SentEmailCreate />,
      },
      {
        path: "view/:id",
        element: <SentEmailView />,
      },
    ],
  },
  {
    path: "page-management",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "page/list",
        element: <PageList />,
      },
      {
        path: "page/create",
        element: <PageCreate />,
      },
      {
        path: "page/update/:id",
        element: <PageUpdate />,
      },
      {
        path: "section/list/:pageId",
        element: <SectionList />,
      },
      {
        path: "section/create/:pageId",
        element: <SectionCreate />,
      },
      {
        path: "section/update/:pageId/:id",
        element: <SectionUpdate />,
      },

      {
        path: "testimonial/list",
        element: <TestimonialList />,
      },
      {
        path: "testimonial/create",
        element: <TestimonialCreate />,
      },
      {
        path: "testimonial/update/:id",
        element: <TestimonialUpdate />,
      },
    ],
  },
  {
    path: "glossary",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <GlossaryList />,
      },
      {
        path: "create",
        element: <GlossaryCreate />,
      },
      {
        path: "update/:id",
        element: <GlossaryUpdate />,
      },
    ],
  },
  {
    path: "testimonial",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <TestimonialList />,
      },
      {
        path: "create",
        element: <TestimonialCreate />,
      },
      {
        path: "update/:id",
        element: <TestimonialUpdate />,
      },
    ],
  },
  {
    path: "notifications",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <NotificationList />,
      },
    ],
  },
  {
    path: "components",
    element: <DashboardLayout />,
    children: [
      {
        path: "accordion",
        element: <Accordion />,
      },
      {
        path: "alerts",
        element: <Alerts />,
      },
      {
        path: "avatars",
        element: <Avatars />,
      },
      {
        path: "badges",
        element: <Badges />,
      },
      {
        path: "buttons",
        element: <Buttons />,
      },
      {
        path: "cards",
        element: <Cards />,
      },
      {
        path: "chips",
        element: <Chips />,
      },
      {
        path: "dialogs",
        element: <Dialogs />,
      },
      {
        path: "lists",
        element: <Lists />,
      },
      {
        path: "menus",
        element: <Menus />,
      },
      {
        path: "pagination",
        element: <Pagination />,
      },
      {
        path: "progress",
        element: <Progress />,
      },
      {
        path: "snackbars",
        element: <Snackbars />,
      },
      {
        path: "tooltips",
        element: <Tooltips />,
      },
    ],
  },
  {
    path: "forms",
    element: <DashboardLayout />,
    children: [
      {
        path: "pickers",
        element: <Pickers />,
      },
      {
        path: "selection-controls",
        element: <SelectionCtrls />,
      },
      {
        path: "selects",
        element: <Selects />,
      },
      {
        path: "text-fields",
        element: <TextFields />,
      },
      {
        path: "editors",
        element: <Editors />,
      },
      {
        path: "formik",
        element: <Formik />,
      },
    ],
  },
  {
    path: "tables",
    element: <DashboardLayout />,
    children: [
      // {
      //   path: "simple-table",
      //   element: <SimpleTable />,
      // },
      // {
      //   path: "advanced-table",
      //   element: <AdvancedTable />,
      // },
      {
        path: "data-grid",
        element: <DataGrid />,
      },
    ],
  },
  {
    path: "icons",
    element: <DashboardLayout />,
    children: [
      {
        path: "material-icons",
        element: <MaterialIcons />,
      },
      {
        path: "feather-icons",
        element: <FeatherIcons />,
      },
    ],
  },
  {
    path: "charts",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Chartjs />,
      },
    ],
  },
  {
    path: "maps",
    element: <DashboardLayout />,
    children: [
      {
        path: "google-maps",
        element: <GoogleMaps />,
      },
      {
        path: "vector-maps",
        element: <VectorMaps />,
      },
    ],
  },
  {
    path: "documentation",
    element: <DocLayout />,
    children: [
      {
        path: "welcome",
        element: <Welcome />,
      },
      {
        path: "getting-started",
        element: <GettingStarted />,
      },
      {
        path: "routing",
        element: <Routing />,
      },
      {
        path: "auth/auth0",
        element: <Auth0 />,
      },
      {
        path: "auth/cognito",
        element: <Cognito />,
      },
      {
        path: "auth/firebase",
        element: <Firebase />,
      },
      {
        path: "auth/jwt",
        element: <JWT />,
      },
      {
        path: "guards",
        element: <Guards />,
      },
      {
        path: "environment-variables",
        element: <EnvironmentVariables />,
      },
      {
        path: "deployment",
        element: <Deployment />,
      },
      {
        path: "theming",
        element: <Theming />,
      },
      {
        path: "api-calls",
        element: <APICalls />,
      },
      {
        path: "redux",
        element: <Redux />,
      },
      {
        path: "internationalization",
        element: <Internationalization />,
      },
      {
        path: "eslint-and-prettier",
        element: <ESLintAndPrettier />,
      },
      {
        path: "migrating-to-next-js",
        element: <MigratingToNextJS />,
      },
      {
        path: "support",
        element: <Support />,
      },
    ],
  },
  {
    path: "changelog",
    element: <DocLayout />,
    children: [
      {
        path: "",
        element: <Changelog />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
