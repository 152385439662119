import axios from "axios";
import { basePath, headersForFile } from "../utils/http";

export const filePost = async (data, fileUploadType, blogUUID) => {
  try {
    const response = await axios.post(
      `${basePath()}/blog/file/admin/upload?fileUploadType=${fileUploadType}&blogUUID=${blogUUID}`,
      data,
      headersForFile()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const blogFilePost = async (data, fileUploadType) => {
  try {
    const response = await axios.post(
      `${basePath()}/blog/file/admin/blog/upload?fileUploadType=${fileUploadType}`,
      data,
      headersForFile()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const fileCommonPost = async (data, fileUploadType) => {
  try {
    const response = await axios.post(
      `${basePath()}/file/upload?fileUploadType=${fileUploadType}`,
      data,
      headersForFile()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const fileCommonUpdatePut = async (data, fileUploadType, file_id) => {
  try {
    const response = await axios.put(
      `${basePath()}/file/update?fileUploadType=${fileUploadType}&file_id=${file_id}`,
      data,
      headersForFile()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const fileUploadPagePost = async (data, fileUploadType) => {
  try {
    const response = await axios.post(
      `${basePath()}/cms/file/upload?fileUploadType=${fileUploadType}`,
      data,
      headersForFile()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};
