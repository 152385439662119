import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    token: "",
    refreshToken: "",
    isLogged: false,
  },
  reducers: {
    logout: (state) => {
      state.isLogged = false;
      state.token = "";
      state.refreshToken = "";
      state.user = {};
      storage.removeItem("persist:root");
    },
    setAuth(state, { payload }) {
      state.user = payload.user;
      state.token = payload.token;
      state.refreshToken = payload.refreshToken;
      state.isLogged = payload.isLogged;
    },
  },
});

export function login(data) {
  return async (dispatch) => {
    try {
      dispatch(authSlice.actions.setAuth(data));
    } catch (error) {
      console.log("in error", error);
    }
  };
}

export const { logout, setAuth } = authSlice.actions;

export default authSlice.reducer;
