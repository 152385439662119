import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  Alert,
  CircularProgress,
  TextField,
  Menu,
  Chip,
  MenuItem,
} from "@mui/material";
import { Add as AddIcon, Delete, Edit, Search } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { productAllGet, productByIdDelete } from "../../requests/product";
import { roleTypesAllGet } from "../../requests/role";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { useSelector } from "react-redux";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  const [name, setName] = useState("");
  const [anchorName, setAnchorName] = useState(null);
  const openName = Boolean(anchorName);

  const [roleTypeOptions, setRoleTypeOptions] = useState([]);

  const [roleType, setRoleType] = useState("");
  const [anchorRoleType, setAnchorRoleType] = useState(null);
  const openRoleType = Boolean(anchorRoleType);

  const [isFeatured, setIsFeatured] = useState("");
  const [anchorIsFeatured, setAnchorIsFeatured] = useState(null);
  const openIsFeatured = Boolean(anchorIsFeatured);

  const [isVisible, setIsVisible] = useState("");
  const [anchorIsVisible, setAnchorIsVisible] = useState(null);
  const openIsVisible = Boolean(anchorIsVisible);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [productId, setProductId] = useState(0);

  useEffect(() => {
    getAllProducts();
    getAllRoleTypes();
  }, [pageNo, pageSize, roleType, name, isFeatured, isVisible]);

  const getAllRoleTypes = async () => {
    setLoading(true);
    const responses = await roleTypesAllGet();
    try {
      setLoading(false);

      if (responses.success) {
        let roleTypes = responses.data;
        let adminIndex = roleTypes.findIndex((role) => role === "ADMIN");
        roleTypes.splice(adminIndex, 1);
        setRoleTypeOptions(roleTypes);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllProducts = async () => {
    setLoading(true);
    const responses = await productAllGet({
      pageNo,
      pageSize,
      name: name.length > 0 ? name : null,
      roleType: roleType.length > 0 ? roleType : null,
      isFeatured: isFeatured.length > 0 ? isFeatured : null,
      isForMenu: isVisible.length > 0 ? isVisible : null,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setProducts(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "name":
        setAnchorName(event.currentTarget);
        break;
      case "roleType":
        setAnchorRoleType(event.currentTarget);
        break;
      case "isFeatured":
        setAnchorIsFeatured(event.currentTarget);
        break;
      case "isVisible":
        setAnchorIsVisible(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "name":
        setAnchorName(null);
        break;
      case "roleType":
        setAnchorRoleType(null);
        break;
      case "isFeatured":
        setAnchorIsFeatured(null);
        break;
      case "isVisible":
        setAnchorIsVisible(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "roleType":
        setRoleType(value);
        break;
      case "isFeatured":
        setIsFeatured(value);
        break;
      case "isVisible":
        setIsVisible(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (productId) => {
    setConfirmationOpen(true);
    setProductId(productId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setProductId(0);
  };

  const deleteProduct = async () => {
    setLoading(true);
    const responses = await productByIdDelete(productId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllProducts();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id) => {
    navigate(`/product/update/${id}`);
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center" padding="normal">
                  Sl. No.
                </TableCell>
                <TableCell align="center" padding="normal">
                  Name
                  <IconButton onClick={(e) => handleClick(e, "name")}>
                    <Search
                      fontSize="small"
                      color={name.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorName}
                  open={openName}
                  onClose={() => handleCloseMenu("name")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    placeholder="Search Name"
                  />
                </Menu>
                <TableCell align="center" padding="normal">
                  Role Type
                  <IconButton onClick={(e) => handleClick(e, "roleType")}>
                    <Search
                      fontSize="small"
                      color={roleType.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorRoleType}
                  open={openRoleType}
                  onClose={() => handleCloseMenu("roleType")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem value={null} disabled>
                    Search Role Type
                  </MenuItem>
                  <MenuItem
                    value={""}
                    sx={{
                      backgroundColor: roleType == "" ? "#219B89" : "",
                      color: roleType == "" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorRoleType(null);
                      setRoleType("");
                    }}
                  >
                    All
                  </MenuItem>
                  {roleTypeOptions.map((type, index) => {
                    return (
                      <MenuItem
                        key={index}
                        sx={{
                          backgroundColor: roleType == type ? "#219B89" : "",
                          color: roleType == type ? "#fff" : "",
                          "&:hover": {
                            backgroundColor: "#219B89",
                            color: "#fff",
                          },
                        }}
                        onClick={() => {
                          setAnchorRoleType(null);
                          setRoleType(type);
                        }}
                        value={type}
                      >
                        {type}
                      </MenuItem>
                    );
                  })}
                </Menu>
                <TableCell align="center" padding="normal">
                  Price
                </TableCell>
                <TableCell align="center" padding="normal">
                  Featured?
                  <IconButton onClick={(e) => handleClick(e, "isFeatured")}>
                    <Search
                      fontSize="small"
                      color={isFeatured.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorIsFeatured}
                  open={openIsFeatured}
                  onClose={() => handleCloseMenu("isFeatured")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem value={null} disabled>
                    Search is featured or not
                  </MenuItem>

                  <MenuItem
                    value={""}
                    sx={{
                      backgroundColor: isFeatured == "" ? "#219B89" : "",
                      color: isFeatured == "" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorIsFeatured(null);
                      setIsFeatured("");
                    }}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    value={true}
                    sx={{
                      backgroundColor: isFeatured == "true" ? "#219B89" : "",
                      color: isFeatured == "true" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorIsFeatured(null);
                      setIsFeatured("true");
                    }}
                  >
                    Yes
                  </MenuItem>
                  <MenuItem
                    value={false}
                    sx={{
                      backgroundColor: isFeatured == "false" ? "#219B89" : "",
                      color: isFeatured == "false" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorIsFeatured(null);
                      setIsFeatured("false");
                    }}
                  >
                    No
                  </MenuItem>
                </Menu>

                <TableCell align="center" padding="normal">
                  Is Visibile?
                  <IconButton onClick={(e) => handleClick(e, "isVisible")}>
                    <Search
                      fontSize="small"
                      color={isVisible.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorIsVisible}
                  open={openIsVisible}
                  onClose={() => handleCloseMenu("isVisible")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem value={null} disabled>
                    Search is visible or not
                  </MenuItem>

                  <MenuItem
                    value={""}
                    sx={{
                      backgroundColor: isVisible == "" ? "#219B89" : "",
                      color: isVisible == "" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorIsVisible(null);
                      setIsVisible("");
                    }}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    value={true}
                    sx={{
                      backgroundColor: isVisible == "true" ? "#219B89" : "",
                      color: isVisible == "true" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorIsVisible(null);
                      setIsVisible("true");
                    }}
                  >
                    Yes
                  </MenuItem>
                  <MenuItem
                    value={false}
                    sx={{
                      backgroundColor: isVisible == "false" ? "#219B89" : "",
                      color: isVisible == "false" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorIsVisible(null);
                      setIsVisible("false");
                    }}
                  >
                    No
                  </MenuItem>
                </Menu>
                <TableCell align="center" padding="normal">
                  Icon
                </TableCell>
                {privileges.includes("PRODUCT_UPDATE") ||
                privileges.includes("PRODUCT_DELETE") ? (
                  <TableCell align="center" padding="normal">
                    Actions
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {products && products.length > 0 ? (
                products.map((product, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={product.id}
                    >
                      <TableCell align="center">
                        {pageNo * pageSize + index + 1}.
                      </TableCell>
                      <TableCell align="center">{product.name}</TableCell>
                      <TableCell align="center">
                        <b>{product.roleType}</b>
                      </TableCell>
                      <TableCell align="center">
                        {product.productPrice}
                      </TableCell>
                      <TableCell align="center">
                        {product.featured ? (
                          <Chip label="Yes" color="success" />
                        ) : (
                          <Chip label="No" color="error" />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {product.forMenu ? (
                          <Chip label="Yes" color="success" />
                        ) : (
                          <Chip label="No" color="error" />
                        )}
                      </TableCell>
                      <TableCell align="center" sx={{ borderRadius: "5px" }}>
                        {product.icon ? (
                          <img
                            style={{
                              height: "150px",
                              width: "150px",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                            src={product.icon.fileUrl}
                          />
                        ) : (
                          "No Icon"
                        )}
                      </TableCell>
                      <TableCell padding="none" align="center">
                        <Box mr={2}>
                          {" "}
                          {privileges.includes("PRODUCT_UPDATE") && (
                            <IconButton
                              aria-label="details"
                              size="large"
                              onClick={() => goEditPage(product.id)}
                            >
                              <Edit color="primary" />
                            </IconButton>
                          )}
                          {privileges.includes("PRODUCT_DELETE") && (
                            <IconButton
                              aria-label="delete"
                              size="large"
                              onClick={() => handleDelete(product.id)}
                            >
                              <Delete sx={{ color: "#d93232" }} />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>No Products Found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        title="Delete Product"
        text="Are you sure that you want to delete this product?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteProduct}
      />
    </div>
  );
}

function ProductList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Products" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Products
          </Typography>
        </Grid>
        {privileges.includes("PRODUCT_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/product/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Product
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Products</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ProductList;
