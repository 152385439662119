import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Button as MuiButton, Typography, Grid } from "@mui/material";
import { spacing } from "@mui/system";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Page404() {
  return (
    <Wrapper>
      <Helmet title="Unauthorized User" />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "90vh", overflowY: "hidden" }}
      >
        <Grid item xs={12}>
          <Typography component="h1" variant="h1" align="center" gutterBottom>
            Unauthorized User!
          </Typography>
          {/* <Typography component="h2" variant="h5" align="center" gutterBottom>
        Page not found.
      </Typography> */}
          <Typography
            component="h2"
            variant="body1"
            align="center"
            gutterBottom
          >
            You are not authorized for this page.
          </Typography>
          <Button
            component={Link}
            to="/"
            variant="contained"
            color="secondary"
            mt={2}
          >
            Return to website
          </Button>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Page404;
