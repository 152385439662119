import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  CircularProgress,
  Card,
  CardContent,
  TextField,
  Breadcrumbs,
} from "@mui/material";
import { Add as AddIcon, Delete, Edit } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { ConfirmationDialog } from "../../../components/dialogs/ConfirmationDialog";
import { getToastError, getToastSuccess } from "../../../utils/toasts";
import { useSelector } from "react-redux";
import {
  glossaryAllGet,
  glosssaryByIdDelete,
} from "../../../requests/glossary";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const [glossaries, setGlossaries] = useState([]);

  const [searchGlossaries, setSearchGlossaries] = useState([]);

  const [name, setName] = useState("");

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [glossaryId, setGlossaryId] = useState(0);

  useEffect(() => {
    getAllGlossaries();
  }, []);

  const getAllGlossaries = async () => {
    setLoading(true);
    const responses = await glossaryAllGet({
      sortBy: "name",
      ascOrDesc: "ASCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setGlossaries(responses.data);
        setSearchGlossaries(responses.data);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        if (value) {
          let searchResult = glossaries.filter((glossary) =>
            glossary.name.toLowerCase().includes(value.toLowerCase())
          );
          setPageNo(0);
          setSearchGlossaries(searchResult);
        } else {
          setSearchGlossaries(glossaries);
        }
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (glossaryId) => {
    setConfirmationOpen(true);
    setGlossaryId(glossaryId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setGlossaryId(0);
  };

  const deleteGlossary = async () => {
    setLoading(true);
    const responses = await glosssaryByIdDelete(glossaryId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        getAllGlossaries();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id) => {
    navigate(`/glossary/update/${id}`);
  };

  return (
    <div>
      <Card mb={6}>
        <CardContent pb={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                size="small"
                id="name"
                name="name"
                value={name}
                onChange={handleChange}
                placeholder="Search by Name"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Paper>
          <TableContainer>
            <Grid
              container
              display={loading ? "flex" : "none"}
              justifyContent="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    Sl. No.
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Name
                  </TableCell>
                  {privileges.includes("GLOSSARY_UPDATE") ||
                  privileges.includes("GLOSSARY_DELETE") ? (
                    <TableCell align="center" padding="normal" width={"20%"}>
                      Actions
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {searchGlossaries && searchGlossaries.length > 0 ? (
                  searchGlossaries
                    .slice(pageNo * pageSize, pageNo * pageSize + pageSize)
                    .map((glossary, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={glossary.id}
                        >
                          <TableCell align="left">
                            {pageNo * pageSize + index + 1}.
                          </TableCell>
                          <TableCell align="left">{glossary.name}</TableCell>
                          {privileges.includes("GLOSSARY_UPDATE") ||
                          privileges.includes("GLOSSARY_DELETE") ? (
                            <TableCell padding="none" align="center">
                              <Box mr={2}>
                                {privileges.includes("GLOSSARY_UPDATE") && (
                                  <IconButton
                                    aria-label="details"
                                    size="large"
                                    onClick={() => goEditPage(glossary.id)}
                                  >
                                    <Edit color="primary" />
                                  </IconButton>
                                )}
                                {privileges.includes("GLOSSARY_DELETE") && (
                                  <IconButton
                                    aria-label="delete"
                                    size="large"
                                    onClick={() => handleDelete(glossary.id)}
                                  >
                                    <Delete sx={{ color: "#d93232" }} />
                                  </IconButton>
                                )}
                              </Box>
                            </TableCell>
                          ) : (
                            ""
                          )}
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>No Glossaries Found!</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={glossaries.length}
            rowsPerPage={pageSize}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>
      <ConfirmationDialog
        title="Delete Glossary"
        text="Are you sure that you want to delete this glossary?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteGlossary}
      />
    </div>
  );
}

function GlossaryList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Glossaries" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Glossaries
          </Typography>
        </Grid>
        {privileges.includes("GLOSSARY_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/glossary/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Glossary
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Glossaries </Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default GlossaryList;
