export function basePath() {
  // return "https://api.staging.minawill.com";
  return "https://api.minawill.com";
}
export function formPath() {
  // return "https://admin.form2flow.dhrubok.me";
  return "https://admin.form2flow.minawill.com";
}
export const headers = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  };
};

export const headersForFile = () => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("token"),
    },
  };
};

// export const BASE_URL = "https://support.minawill.dhrubok.me";

export const BASE_URL = "https://support.minawill.com";
