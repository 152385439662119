import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

// import useAuth from "../../hooks/useAuth";
import { loginPost, sendOtpEmailPost } from "../../requests/auth";
import { login } from "../../redux/slices/auth";
import { useDispatch } from "react-redux";
import { getToastSuccess } from "../../utils/toasts";
import { RemoveRedEyeRounded, VisibilityOffRounded } from "@mui/icons-material";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passType, setPassType] = useState("password");
  const [loading, setLoading] = useState(false);

  //password show icon
  const handleShowPassword = () => {
    if (passType === "password") {
      setPassType("text");
    } else {
      setPassType("password");
    }
  };

  const onSubmit = async (values, setErrors, setStatus, setSubmitting) => {
    setLoading(true);
    const postData = {
      email: values.email,
      password: values.password,
    };
    const response = await loginPost(postData);
    try {
      if (response.success) {
        const {
          tokenType,
          accessToken,
          refreshToken,
          user,
          supportTicketSessionToken,
        } = response.data;
        const token = `${tokenType} ${accessToken}`;
        if (user) {
          if (user.roles && user.roles[0].roleType === "ADMIN") {
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem(
              "supportTicketSessionToken",
              supportTicketSessionToken
            );
            await dispatch(
              login({
                token: token,
                refreshToken: refreshToken,
                supportTicketSessionToken: supportTicketSessionToken,
                user: user,
                isLogged: true,
              })
            );
            getToastSuccess(response.message);
            navigate("/private");
          } else {
            setStatus({ success: false });
            setErrors({ submit: "Sorry! You are not an admin!" });
            setSubmitting(false);
            setLoading(false);
          }
          // if (user.verified) {
          //   localStorage.setItem("token", token);
          //   localStorage.setItem("refreshToken", refreshToken);
          //   await dispatch(
          //     login({
          //       token: token,
          //       refreshToken: refreshToken,
          //       user: user,
          //       isLogged: true,
          //     })
          //   );
          //   getToastSuccess(response.message);
          //   navigate("/private");
          // } else {
          //   verifyEmail(user.email, setErrors, setStatus, setSubmitting);
          //   setStatus({ success: false });
          //   setErrors({ submit: "Check mail and verify please" });
          //   setSubmitting(false);
          // }
        } else {
          setSubmitting(false);
          setLoading(false);
        }
      } else {
        const message = response.message;

        setStatus({ success: false });
        setErrors({ submit: message });
        setSubmitting(false);
        setLoading(false);
      }
      // await signIn(values.email, values.password);
    } catch (error) {
      const message = error.message || "Something went wrong";

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
      setLoading(false);
    }
  };

  const verifyEmail = async (email, setErrors, setStatus, setSubmitting) => {
    const postData = {
      userEmail: email,
    };
    const response = await sendOtpEmailPost(postData);
    try {
      const message = response.message;

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    } catch (error) {
      const message = error.message || "Something went wrong";

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        onSubmit(values, setErrors, setStatus, setSubmitting);
        // try {
        //   await signIn(values.email, values.password);

        //   navigate("/private");
        // } catch (error) {
        //   const message = error.message || "Something went wrong";

        //   setStatus({ success: false });
        //   setErrors({ submit: message });
        //   setSubmitting(false);
        // }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {/* <Alert mt={3} mb={3} severity="info">
            Use <strong>demo@bootlab.io</strong> and{" "}
            <strong>unsafepassword</strong> to sign in
          </Alert> */}
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type={passType}
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
            InputProps={{
              endAdornment:
                passType === "password" ? (
                  <RemoveRedEyeRounded
                    style={{ cursor: "pointer" }}
                    onClick={handleShowPassword}
                  />
                ) : (
                  <VisibilityOffRounded
                    style={{ cursor: "pointer" }}
                    onClick={handleShowPassword}
                  />
                ),
            }}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting || loading}
          >
            Sign in
          </Button>
          <Button
            component={Link}
            to="/auth/reset-password"
            fullWidth
            color="primary"
          >
            Forgot password
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
