import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  InputLabel,
  Autocomplete,
  Chip,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { pageByIdGet, pageUpdatePut } from "../../../requests/page";
import { sectionAllGet } from "../../../requests/section";
import { getToastError, getToastSuccess } from "../../../utils/toasts";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

function UpdateForm() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [sections, setSections] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [inputValueSection, setInputValueSection] = useState("");
  const [allSectionIndex, setAllSectionIndex] = useState([]);

  const [description, setDescription] = useState("");
  const [slug, setSlug] = useState("");
  const [tag, setTag] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllSections();
    getPageDetails();
  }, []);

  useEffect(() => {
    let allIndex = [];
    selectedSections.map((sec) => {
      let secIndex = sections.findIndex((section) => section.id == sec.id);
      if (secIndex !== -1) {
        allIndex.push(secIndex);
      }
    });

    setAllSectionIndex(allIndex);
  }, [selectedSections, sections]);

  const getAllSections = async () => {
    setLoading(true);
    const responses = await sectionAllGet({
      pageNo: 0,
      pageSize: 500,
      title: inputValueSection.length > 0 ? inputValueSection : null,
    });
    try {
      setLoading(false);

      if (responses.success) {
        setSections(responses.data.content);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getPageDetails = async () => {
    setLoading(true);
    const responses = await pageByIdGet(id);
    try {
      setLoading(false);

      if (responses.success) {
        const post = responses.data;
        setTitle(post.title);
        setSubTitle(post.subTitle);
        setDescription(post.description);
        setSlug(post.slug);
        setTag(post.tag);
        setSelectedSections(post.sections);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "subTitle":
        setSubTitle(value);
        break;
      case "tag":
        setTag(value);
        break;
      case "slug":
        setSlug(value);
        break;
      default:
        break;
    }
  };

  const handleChangeEditor = (value) => {
    setDescription(value);
  };

  const handleUpdate = async () => {
    setLoading(true);
    var sectionIdArray = selectedSections.map(function (sec) {
      return sec.id;
    });
    const putData = {
      pageId: id,
      sectionIds: sectionIdArray,
      description,
      title,
      subTitle,
      tag,
      slug,
    };
    const responses = await pageUpdatePut(putData);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate("/page-management/page/list");
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Update
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          id="title"
          name="title"
          label="Title"
          required
          variant="outlined"
          value={title}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          margin="normal"
          id="subTitle"
          name="subTitle"
          label="Sub-Title"
          variant="outlined"
          value={subTitle}
          onChange={handleChange}
        />

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              margin="normal"
              id="tag"
              name="tag"
              label="Tag"
              variant="outlined"
              value={tag}
              disabled
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              margin="normal"
              id="slug"
              name="slug"
              label="Page URL"
              variant="outlined"
              value={slug}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        {selectedSections.length > 0 && (
          <>
            <InputLabel>Sections</InputLabel>
            {selectedSections.map((section) => {
              return (
                <Chip sx={{ mr: 1 }} color="secondary" label={section.title} />
              );
            })}
          </>
        )}

        <InputLabel sx={{ mt: 2, mb: 1 }}>Description</InputLabel>
        <ReactQuill
          value={description}
          placeholder="Write Description..."
          onChange={handleChangeEditor}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleUpdate}
          disabled={title === "" || tag === ""}
        >
          Update
        </LoadingButton>
        <Button
          sx={{ mt: 3, ml: 1 }}
          onClick={() => navigate("/page-management/page/list")}
        >
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function PageUpdate() {
  return (
    <React.Fragment>
      <Helmet title="Page Update" />

      <Typography variant="h3" gutterBottom display="inline">
        Page Update
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/page-management/page/list">
          Pages
        </Link>
        <Typography> Update</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <UpdateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PageUpdate;
