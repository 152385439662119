import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  CircularProgress,
  Card as MuiCard,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled, { css } from "styled-components/macro";
import { rgba } from "polished";
import { green, red } from "@mui/material/colors";

import Actions from "./Actions";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import DoughnutChart from "./DoughnutChart";
import Stats from "./Stats";
import Table from "./Table";
import {
  dashboardAllCountGet,
  fundCountGet,
  uploadedDocSummaryGet,
  userCountGet,
  willCountGet,
} from "../../../requests/dashboard";
import { getToastError } from "../../../utils/toasts";
import { nonProfitAllGet } from "../../../requests/nonProfit";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Default() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalUser, setTotalUser] = useState(0);
  const [totalNonprofit, setTotalNonprofit] = useState(0);
  const [totalWill, setTotalWill] = useState(0);
  const [totalFund, setTotalFund] = useState(0);
  const [signedBequestCount, setSignedBequestCount] = useState(0);
  const [nonProfits, setNonProfits] = useState([]);
  const [uploadedDocs, setUploadedDocs] = useState([]);

  useEffect(() => {
    getAllCount();
    getAllNonProfits();
    getUploadedDocs();
  }, []);

  const getAllCount = async () => {
    setLoading(true);
    const responses = await dashboardAllCountGet();
    try {
      setLoading(false);

      if (responses.success) {
        let counts = responses.data;
        setTotalUser(counts.userCount);
        setTotalNonprofit(counts.nonProfitCount);
        setTotalWill(counts.willCount);
        setTotalFund(counts.totalFund);
        setSignedBequestCount(counts.signedBequestCount);
        console.log(responses.data);
        // setTotalFund(responses.data);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          navigate("/");
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getUploadedDocs = async () => {
    setLoading(true);
    const responses = await uploadedDocSummaryGet();
    try {
      setLoading(false);

      if (responses.success) {
        setUploadedDocs(responses.data);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        }
      }
    } catch (error) {
      setLoading(false);
      // getToastError(error.response.data.message);
    }
  };

  const getAllNonProfits = async () => {
    setLoading(true);
    const responses = await nonProfitAllGet({ pageNo: 0, pageSize: 500 });
    try {
      setLoading(false);

      if (responses.success) {
        setNonProfits(responses.data.content);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        }
      }
    } catch (error) {
      setLoading(false);
      // getToastError(error.response.data.message);
    }
  };

  const getNonProfitNameById = (id) => {
    const nonProfitIndex = nonProfits.findIndex((non) => non.id == id);
    if (nonProfitIndex !== -1) {
      return nonProfits[nonProfitIndex].name;
    } else {
      return "Name not found";
    }
  };

  const Card = styled(MuiCard)`
    position: relative;
    // margin-bottom: ${(props) => props.theme.spacing(6)};
    padding: 20px;

    ${(props) =>
      props.theme.palette.mode !== "dark" &&
      css`
        background: ${(props) => rgba(props.theme.palette.primary.main, 0.125)};
        color: ${(props) => props.theme.palette.primary.main};
      `}
  `;

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
          {/* <Typography variant="subtitle1">
            {t("Welcome back")}! {t("We've missed you")}.{" "}
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography> */}
        </Grid>
        {!loading && (
          <Grid item>
            <Card>
              <Typography variant="h6">
                <b>Total Fundraising:</b> {totalFund}
              </Typography>
            </Card>
          </Grid>
        )}
      </Grid>

      <Divider my={6} />
      {loading ? (
        <Grid container justifyContent={"center"}>
          <Grid item justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6} lg={3} xl>
              <Stats
                title="Total Users"
                amount={totalUser}
                illustration="/static/img/illustrations/users.png"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl>
              <Stats
                title="Total Nonprofit Users"
                amount={totalNonprofit}
                illustration="/static/img/illustrations/nonprofit.png"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl>
              <Stats
                title="Total Generated Will"
                amount={totalWill}
                illustration="/static/img/illustrations/will.png"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl>
              <Stats
                title="Total Signed Bequest"
                amount={signedBequestCount}
                illustration="/static/img/illustrations/signed-bequest.png"
              />
            </Grid>
          </Grid>

          <Grid container spacing={6}>
            {/* <Grid item xs={12} lg={8}>
          <LineChart />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DoughnutChart />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <BarChart />
        </Grid> */}
            {uploadedDocs && uploadedDocs.length > 0 && (
              <Grid item xs={12}>
                <Table
                  uploadedDocs={uploadedDocs}
                  nonProfits={nonProfits}
                  getNonProfitNameById={getNonProfitNameById}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default Default;
