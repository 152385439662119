import axios from "axios";
import { basePath, headers, headersForFile } from "../utils/http";

export const contactAllGet = async (params) => {
  try {
    const response = await axios.get(`${basePath()}/contact`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const contactByIdGet = async (uuid) => {
  try {
    const response = await axios.get(
      `${basePath()}/contact/uuid/${uuid}`,
      headers()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const contactCreatePost = async (data) => {
  try {
    const response = await axios.post(`${basePath()}/contact`, data, headers());
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const contactUpdatePut = async (data) => {
  try {
    const response = await axios.put(`${basePath()}/contact`, data, headers());
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const contactByIdDelete = async (uuid) => {
  try {
    const response = await axios.delete(
      `${basePath()}/contact/uuid/${uuid}`,
      headers()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const contactUploadPost = async (data) => {
  try {
    const response = await axios.post(
      `${basePath()}/contact/upload-contacts`,
      data,
      headersForFile()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};
