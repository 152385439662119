import axios from "axios";
import { basePath, headers } from "../utils/http";


export const supportTicektSessionDelete = async (token, blogUUID) => {
    try {
      const response = await axios.delete(`${basePath()}/support-ticket/session/delete/${token}`,
      {
        headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
        ticketApiHeader: 'apiHeader@minawill.com'
      }},
      );
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };