import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommonPage from "../components/auth/Common";
import Page404 from "../pages/auth/Page404";
import { login } from "../redux/slices/auth";
import { myDetailsGet } from "../requests/user";
let count = 0;
function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const userData = useSelector((state) => state.auth.user);
  const [privileges, setPrivileges] = useState([]);

  // const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    // console.log(userData);

    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);

  useEffect(() => {}, [privileges, path, userData]);

  useEffect(() => {
    count++;
    // console.log(count);
  }, [privileges]);

  const checkPrivileges = () => {
    //role
    if (path.includes("/role/list") && !privileges.includes("ROLE_READ")) {
      return false;
    } else if (
      path.includes("/role/create") &&
      !privileges.includes("ROLE_CREATE")
    ) {
      return false;
    } else if (
      path.includes("/role/update") &&
      !privileges.includes("ROLE_UPDATE")
    ) {
      return false;
    }
    //user
    else if (path.includes("/user/list") && !privileges.includes("USER_READ")) {
      return false;
    } else if (
      path.includes("/user/create") &&
      !privileges.includes("USER_CREATE")
    ) {
      return false;
    } else if (
      path.includes("/user/update") &&
      !privileges.includes("USER_UPDATE")
    ) {
      return false;
    }

    //CMS
    else if (
      path.includes("/page-management") &&
      path.includes("/list") &&
      !privileges.includes("CMS_READ")
    ) {
      return false;
    } else if (
      path.includes("/page-management") &&
      path.includes("/create") &&
      !privileges.includes("CMS_CREATE")
    ) {
      return false;
    } else if (
      path.includes("/page-management") &&
      path.includes("/update") &&
      !privileges.includes("CMS_UPDATE")
    ) {
      return false;
    }

    //testimonial
    else if (
      path.includes("/testimonial/create") &&
      !privileges.includes("RATING_CREATE")
    ) {
      return false;
    } else if (
      path.includes("/testimonial/update") &&
      !privileges.includes("RATING_UPDATE")
    ) {
      return false;
    }

    //glossary
    else if (
      path.includes("/glossary/create") &&
      !privileges.includes("GLOSSARY_CREATE")
    ) {
      return false;
    } else if (
      path.includes("/glossary/update") &&
      !privileges.includes("GLOSSARY_UPDATE")
    ) {
      return false;
    }

    //Legal documents
    else if (
      path.includes("/legal-docs/list") &&
      !privileges.includes("LEGAL_DOC_READ")
    ) {
      return false;
    } else if (
      path.includes("/legal-docs/update") &&
      !privileges.includes("LEGAL_DOC_UPDATE")
    ) {
      return false;
    }

    //subscription packages
    else if (
      path.includes("subscription-packages/create") &&
      !privileges.includes("SUBSCRIPTION_PACKAGE_CREATE")
    ) {
      return false;
    } else if (
      path.includes("subscription-packages/update") &&
      !privileges.includes("SUBSCRIPTION_PACKAGE_UPDATE")
    ) {
      return false;
    }

    //coupon
    else if (
      path.includes("/coupon/create") &&
      !privileges.includes("COUPON_CREATE")
    ) {
      return false;
    } else if (
      path.includes("/coupon/update") &&
      !privileges.includes("COUPON_UPDATE")
    ) {
      return false;
    }

    //promos
    else if (
      path.includes("/promos/create") &&
      !privileges.includes("PROMO_CREATE")
    ) {
      return false;
    } else if (
      path.includes("/promos/update") &&
      !privileges.includes("PROMO_UPDATE")
    ) {
      return false;
    }

    //History
    else if (
      path.includes("/transaction-history") &&
      !privileges.includes("HISTORY_TRANSACTION_READ")
    ) {
      return false;
    } else if (
      path.includes("/will-history") &&
      !privileges.includes("HISTORY_WILL_READ")
    ) {
      return false;
    } else if (
      path.includes("/bequest-history") &&
      !privileges.includes("HISTORY_BEQUEST_READ")
    ) {
      return false;
    } else if (
      path.includes("/healthcare-directive-history") &&
      !privileges.includes("HISTORY_HEALTH_CARE_READ")
    ) {
      return false;
    } else if (
      path.includes("/donate-stock-history") &&
      !privileges.includes("HISTORY_STOCK_READ")
    ) {
      return false;
    }

    //verification doc
    else if (
      path.includes("/verification-docs/list") &&
      !privileges.includes("VERIFICATIONDOC_READ")
    ) {
      return false;
    } else if (
      path.includes("/verification-docs/create") &&
      !privileges.includes("VERIFICATIONDOC_CREATE")
    ) {
      return false;
    } else if (
      path.includes("/verification-docs/update") &&
      !privileges.includes("VERIFICATIONDOC_UPDATE")
    ) {
      return false;
    }

    //uploaded doc
    else if (
      path.includes("/verification-docs/all") &&
      !privileges.includes("UPLOADEDDOC_UPDATE")
    ) {
      return false;
    }

    //Blog
    else if (
      path.includes("/blog") &&
      path.includes("/list") &&
      !privileges.includes("BLOG_READ")
    ) {
      return false;
    } else if (
      path.includes("/blog") &&
      path.includes("/create") &&
      !privileges.includes("BLOG_CREATE")
    ) {
      return false;
    } else if (
      path.includes("/blog") &&
      path.includes("/update") &&
      !privileges.includes("BLOG_UPDATE")
    ) {
      return false;
    }

    //organization type
    else if (
      path.includes("/organization/type/create") &&
      !privileges.includes("ORGANIZATIONTYPE_CREATE")
    ) {
      return false;
    } else if (
      path.includes("/organization/type/update") &&
      !privileges.includes("ORGANIZATIONTYPE_UPDATE")
    ) {
      return false;
    }

    //product
    else if (
      path.includes("/product/create") &&
      !privileges.includes("PRODUCT_CREATE")
    ) {
      return false;
    } else if (
      path.includes("/product/update") &&
      !privileges.includes("PRODUCT_UPDATE")
    ) {
      return false;
    }

    //scholar
    else if (
      path.includes("/scholar/create") &&
      !privileges.includes("SCHOLAR_CREATE")
    ) {
      return false;
    } else if (
      path.includes("/scholar/update") &&
      !privileges.includes("SCHOLAR_UPDATE")
    ) {
      return false;
    }

    //Inquiry
    else if (
      path.includes("/inquiry/list") &&
      !privileges.includes("INQUIRY_ALL")
    ) {
      return false;
    }
    //Marketing
    else if (
      path.includes("/contact") &&
      !privileges.includes("MARKETING_ALL")
    ) {
      return false;
    } else if (
      path.includes("/sent-email") &&
      !privileges.includes("MARKETING_ALL")
    ) {
      return false;
    } else return true;
  };
  if (!checkPrivileges() && count > 0) {
    return <Page404 />;
  } else if (checkPrivileges()) {
    return <>{children}</>;
  } else {
    return <CommonPage />;
  }
}

export default AuthProvider;
