import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import "../../styles/quil.css";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  InputLabel,
  Select,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload, Delete } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { productCreatePost } from "../../requests/product";
import { fileCommonPost } from "../../requests/file";
import { hexColorValidate } from "../../validation/validation";
import { roleTypesAllGet } from "../../requests/role";
import { getToastError, getToastSuccess } from "../../utils/toasts";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function CreateForm() {
  const navigate = useNavigate();
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [hexColor, setHexColor] = useState("");
  const [hasErrorHexColor, setHasErrorHexColor] = useState(false);
  const [errorTextHexColor, setErrorTextHexColor] = useState(false);
  const [featured, setFeatured] = useState(true);
  const [forMenu, setForMenu] = useState(false);
  const [roleType, setRoleType] = useState("");
  const [roleTypeOptions, setRoleTypeOptions] = useState([]);
  const [productPrice, setProductPrice] = useState("");
  const [mainButtonText, setMainButtonText] = useState("");

  const inputFile = useRef(null);
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const inputFileIcon = useRef(null);
  const [icon, setIcon] = useState("");
  const [iconFile, setIconFile] = useState(null);

  useEffect(() => {
    getAllRoleTypes();
  }, []);

  const getAllRoleTypes = async () => {
    setLoading(true);
    const responses = await roleTypesAllGet();
    try {
      setLoading(false);

      if (responses.success) {
        let roleTypes = responses.data;
        let adminIndex = roleTypes.findIndex((role) => role === "ADMIN");
        roleTypes.splice(adminIndex, 1);
        setRoleTypeOptions(roleTypes);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "mainButtonText":
        setMainButtonText(value);
        break;
      case "tag":
        setTag(value);
        break;
      case "featured":
        setFeatured(value);
        break;
      case "forMenu":
        setForMenu(value);
        break;
      case "roleType":
        if (value !== "USER") {
          setProductPrice("");
        }
        setRoleType(value);
        break;
      case "shortDescription":
        setShortDescription(value);
        break;
      case "productPrice":
        setProductPrice(value);
        break;
      case "hexColor":
        setHexColor(value);
        if (value.trim()) {
          let checkError = hexColorValidate.test(value.trim());
          let error = checkError ? "" : "Invalid color code";

          setErrorTextHexColor(error);
          setHasErrorHexColor(!checkError);
        } else {
          setErrorTextHexColor("");
          setHasErrorHexColor(false);
        }
        break;
      default:
        break;
    }
  };

  const handleChangeEditor = (value) => {
    setLongDescription(value);
  };

  const handleChangeFile = (event) => {
    const { name, files } = event.target;
    switch (name) {
      case "file":
        if (files[0] != null) {
          setImage(URL.createObjectURL(files[0]));
          setFile(files[0]);
        }
        break;
      case "icon":
        if (files[0] != null) {
          setIcon(URL.createObjectURL(files[0]));
          setIconFile(files[0]);
        }
        break;
      default:
        break;
    }
  };

  const handleUpload = (fileName) => {
    switch (fileName) {
      case "file":
        inputFile.current.click();
        break;

      case "icon":
        inputFileIcon.current.click();
        break;

      default:
        break;
    }
  };

  const handleRemove = (fileName) => {
    switch (fileName) {
      case "file":
        setFile(null);
        setImage("");
        break;

      case "icon":
        setIconFile(null);
        setIcon("");
        break;

      default:
        break;
    }
  };

  const handleSubmit = async () => {
    let fileId = null;
    let iconId = null;
    setLoading(true);
    if (file) {
      let formData = new FormData();
      formData.append("file", file);
      const response = await fileCommonPost(formData, "PRODUCT");

      try {
        if (response.success) {
          fileId = response.data.id;
        } else {
          setLoading(false);
          getToastError(response.message);
        }
      } catch (error) {
        setLoading(false);
        getToastError(error.response.data.message);
      }
    }
    if (iconFile) {
      let formData = new FormData();
      formData.append("file", iconFile);
      const response = await fileCommonPost(formData, "PRODUCT_ICON");

      try {
        if (response.success) {
          iconId = response.data.id;
        } else {
          setLoading(false);
          getToastError(response.message);
        }
      } catch (error) {
        setLoading(false);
        getToastError(error.response.data.message);
      }
    }
    handleCreate(fileId, iconId);
  };

  const handleCreate = async (fileId, iconFileId) => {
    setLoading(true);
    const postData = {
      featured,
      fileId,
      iconFileId,
      hexColor,
      longDescription,
      name,
      tag,
      forMenu,
      roleType,
      shortDescription,
      productPrice,
      mainButtonText,
    };
    const responses = await productCreatePost(postData);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate("/product/list");
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Create
        </Typography>

        <Grid container>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <InputLabel sx={{ mt: 2, mb: 1 }}>Icon*</InputLabel>
              </Grid>
              <Grid item xs={12}>
                {iconFile ? <img style={{ height: "30vh" }} src={icon} /> : ""}
              </Grid>
              <Grid item xs={12}>
                {iconFile ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      onClick={() => handleUpload("icon")}
                    >
                      <CloudUpload mr={2} /> Change
                    </Button>
                    <Button
                      sx={{ ml: 1 }}
                      variant="contained"
                      color="error"
                      component="span"
                      onClick={() => handleRemove("icon")}
                    >
                      <Delete mr={2} /> Remove
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={() => handleUpload("icon")}
                  >
                    <CloudUpload mr={2} /> Upload
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                <input
                  type="file"
                  id="icon"
                  name="icon"
                  accept="image/*"
                  ref={inputFileIcon}
                  style={{ display: "none" }}
                  onChange={handleChangeFile}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <InputLabel sx={{ mt: 2, mb: 1 }}>Image*</InputLabel>
              </Grid>
              <Grid item xs={12}>
                {file ? <img style={{ height: "30vh" }} src={image} /> : ""}
              </Grid>
              <Grid item xs={12}>
                {file ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      onClick={() => handleUpload("file")}
                    >
                      <CloudUpload mr={2} /> Change
                    </Button>
                    <Button
                      sx={{ ml: 1 }}
                      variant="contained"
                      color="error"
                      component="span"
                      onClick={() => handleRemove("file")}
                    >
                      <Delete mr={2} /> Remove
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={() => handleUpload("file")}
                  >
                    <CloudUpload mr={2} /> Upload
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept="image/*"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={handleChangeFile}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              id="name"
              name="name"
              label="Name*"
              variant="outlined"
              value={name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              id="tag"
              name="tag"
              label="Tag*"
              variant="outlined"
              value={tag}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 1 }}>
          {/* <Grid item xs={12} sm={12} md={4}>
            <TextField
              fullWidth
              margin="normal"
              id="hexColor"
              name="hexColor"
              label="Color Code(Hex)"
              variant="outlined"
              value={hexColor}
              error={hasErrorHexColor}
              helperText={errorTextHexColor}
              onChange={handleChange}
            />
          </Grid> */}

          <Grid item xs={12} sm={12} md={6}>
            <FormControl m={2} margin="normal" sx={{ width: "100%" }} required>
              <InputLabel id="roleType">Role Type</InputLabel>
              <Select
                fullWidth
                labelId="roleType"
                id="roleType"
                value={roleType}
                name="roleType"
                label="Role Type"
                onChange={handleChange}
              >
                {roleTypeOptions.map((type, index) => {
                  return (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              type="number"
              margin="normal"
              id="productPrice"
              name="productPrice"
              label="Product price(in $)"
              variant="outlined"
              value={productPrice}
              onChange={handleChange}
              disabled={roleType !== "USER"}
              error={productPrice && productPrice < 0}
              helperText={
                productPrice && productPrice < 0
                  ? "Product price cannot be less than 0"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              fullWidth
              margin="normal"
              id="mainButtonText"
              name="mainButtonText"
              label="CTA Button Text"
              variant="outlined"
              value={mainButtonText}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={false} sm={false} md={1}></Grid>
          <Grid item xs={12} sm={12} md={3}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Is Featured?*
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="featured"
              value={featured}
              onChange={handleChange}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item xs={false} sm={false} md={1}></Grid>
          <Grid item xs={12} sm={12} md={3}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Is Visible in Menu?*
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="forMenu"
              value={forMenu}
              onChange={handleChange}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
        </Grid>

        <TextField
          fullWidth
          margin="normal"
          id="shortDescription"
          multiline
          rows={3}
          name="shortDescription"
          label="Short Description"
          variant="outlined"
          value={shortDescription}
          onChange={handleChange}
        />

        <InputLabel sx={{ mt: 2, mb: 1 }}>Long Description</InputLabel>
        <ReactQuill
          className="ql-editor"
          value={longDescription}
          placeholder="Write Description..."
          onChange={handleChangeEditor}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={
            name === "" ||
            roleType === "" ||
            hasErrorHexColor ||
            tag.length === 0 ||
            (productPrice && productPrice < 0) ||
            !file ||
            !iconFile
          }
        >
          Create
        </LoadingButton>
        <Button sx={{ mt: 3, ml: 1 }} onClick={() => navigate("/product/list")}>
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function ProductCreate() {
  return (
    <React.Fragment>
      <Helmet title="Product Create" />

      <Typography variant="h3" gutterBottom display="inline">
        Product Create
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/product/list">
          Products
        </Link>
        <Typography> Create</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CreateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ProductCreate;
