import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { Bell, Gift } from "react-feather";
import {
  notificationAllGet,
  notificationMarkAsReadPost,
  notificationUnreadCountGet,
} from "../../requests/notification";
import { getToastError } from "../../utils/toasts";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { LockOpen, Task } from "@mui/icons-material";
import moment from "moment";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({
  key,
  title,
  description,
  date,
  notificationType,
  redirectToNotification,
  handleClose,
  Icon,
}) {
  return (
    <ListItem
      key={key}
      divider
      onClick={handleClose}
      component={Link}
      to={redirectToNotification(notificationType)}
    >
      <ListItemAvatar>
        <Avatar>
          <SvgIcon fontSize="small">
            <Icon />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={
          <React.Fragment>
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="body1"
              color="text.secondary"
            >
              {description}
            </Typography>
            <br />
            {date && `- ${moment.unix(date).format("Do MMMM YYYY, h:mm a")}`}
          </React.Fragment>
        }
      />
    </ListItem>
  );
}

function NavbarNotificationsDropdown() {
  const ref = useRef(null);
  const location = useLocation();
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUnreadNotificationCount();
    }
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUnreadNotifications();
    }
  }, [notificationCount]);

  const getUnreadNotificationCount = async () => {
    const responses = await notificationUnreadCountGet();
    try {
      if (responses.success) {
        setNotificationCount(responses.data);
      } else {
        console.log(responses.message);
      }
    } catch (error) {
      console.log(error.responses.data.message);
    }
  };

  const getUnreadNotifications = async () => {
    const responses = await notificationAllGet({
      sort_by: "id",
      asc_or_desc: "desc",
      page_no: 0,
      page_size: 20,
    });
    try {
      if (responses.success) {
        setNotifications(responses.data.content);
      } else {
        console.log(responses.message);
      }
    } catch (error) {
      console.log(error.responses.data.message);
    }
  };

  const getMarkAsReadNotification = async () => {
    const responses = await notificationMarkAsReadPost();
    try {
      if (responses.success) {
      } else {
        console.log(responses.message);
      }
    } catch (error) {
      console.log(error.responses.data.message);
    }
  };

  const redirectToNotification = (type) => {
    if (type.includes("SUBSCRIPTION") || type.includes("TRANSACTION")) {
      return "/transaction-history";
    } else if (type.includes("BEQUEST")) {
      return "/bequest-history";
    } else if (type.includes("WILL")) {
      return "/will-history";

    } else if (type.includes("HEALTH_CARE_DIRECTIVE")) {
      return "/healthcare-directive-history";

    } else if (type.includes("STOCK")) {
      return "/donate-stock-history";

    } else if (type.includes("NEW_DOC") || type.includes("DOCUMENT")) {
      return "/verification-docs/all";
    } else if (type.includes("SIGN_UP")) {
      return "/organization/non-profit/list";
    } else if (type.includes("INQUIRY") || type.includes("DEMO")) {
      return "/inquiry/list";
    }
  };

  const handleOpen = () => {
    setOpen(true);
    if (notificationCount > 0) {
      getMarkAsReadNotification();
    }
  };

  const handleClose = () => {
    if (notificationCount > 0) {
      getUnreadNotificationCount();
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={notificationCount}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {notificationCount > 0
              ? `${notificationCount} New Notifications`
              : "No New Notifications"}
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            {notifications.map((notification, index) => (
              <Notification
                key={index}
                title={notification.title}
                description={notification.message}
                date={notification.creationDateTimeStamp}
                notificationType={notification.type}
                redirectToNotification={redirectToNotification}
                handleClose={handleClose}
                Icon={
                  notification.type.includes("SUBSCRIPTION")
                    ? SubscriptionsIcon
                    : notification.type.includes("NEW_DOC") ||
                      notification.type.includes("DOCUMENT")
                    ? Task
                    : notification.type.includes("SIGN_UP")
                    ? LockOpen
                    : notification.type.includes("BEQUEST")
                    ? Gift
                    : Bell
                }
              />
            ))}
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button
              onClick={handleClose}
              size="small"
              component={Link}
              to="/notifications"
            >
              Show all notifications
            </Button>
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
