import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  TextField,
  Menu,
  Chip,
  MenuItem,
  Button,
  Link,
} from "@mui/material";
import {
  Add as AddIcon,
  CloudUpload,
  Delete,
  Edit,
  RemoveRedEye,
  Search,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  sentEmailAllGet,
  sentEmailByIdDelete,
} from "../../requests/sent-email";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { Eye } from "react-feather";
import { useSelector } from "react-redux";
import moment from "moment";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const [emails, setEmails] = useState([]);

  const [email, setEmail] = useState("");
  const [anchorEmail, setAnchorEmail] = useState(null);
  const openEmail = Boolean(anchorEmail);

  const [subject, setSubject] = useState("");
  const [anchorSubject, setAnchorSubject] = useState(null);
  const openSubject = Boolean(anchorSubject);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [emailId, setEmailId] = useState(0);

  useEffect(() => {
    getAllSentEmails();
  }, [pageNo, pageSize, email, subject]);

  const getAllSentEmails = async () => {
    setLoading(true);
    const responses = await sentEmailAllGet({
      pageNo,
      pageSize,
      email: email.length > 0 ? email : null,
      subject: subject.length > 0 ? subject : null,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setEmails(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "email":
        setAnchorEmail(event.currentTarget);
        break;
      case "subject":
        setAnchorSubject(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "email":
        setAnchorEmail(null);
        break;
      case "subject":
        setAnchorSubject(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "subject":
        setSubject(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (emailId) => {
    setConfirmationOpen(true);
    setEmailId(emailId);
  };

  const handleUpdate = (emailId) => {
    setEmailId(emailId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setEmailId(0);
  };

  const deleteEmail = async () => {
    setLoading(true);
    const responses = await sentEmailByIdDelete(emailId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllSentEmails();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id) => {
    navigate(`/sent-email/view/${id}`);
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  Sl. No.
                </TableCell>
                <TableCell align="left" padding="normal">
                  Email
                  <IconButton onClick={(e) => handleClick(e, "email")}>
                    <Search
                      fontSize="small"
                      color={email.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEmail}
                  open={openEmail}
                  onClose={() => handleCloseMenu("email")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    placeholder="Search Email"
                  />
                </Menu>
                <TableCell align="left" padding="normal">
                  Subject
                  <IconButton onClick={(e) => handleClick(e, "subject")}>
                    <Search
                      fontSize="small"
                      color={subject.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorSubject}
                  open={openSubject}
                  onClose={() => handleCloseMenu("subject")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="subject"
                    name="subject"
                    value={subject}
                    onChange={handleChange}
                    placeholder="Search Phone Number"
                  />
                </Menu>

                <TableCell align="left" padding="normal">
                  Mail Sent At
                </TableCell>
                {privileges.includes("MARKETING_ALL") && (
                  <TableCell align="left" padding="normal">
                    Action
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {emails && emails.length > 0 ? (
                emails.map((email, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={email.id}
                    >
                      <TableCell align="left">
                        {pageNo * pageSize + index + 1}.
                      </TableCell>
                      <TableCell align="left">{email.email}</TableCell>
                      <TableCell align="left">{email.subject}</TableCell>
                      <TableCell align="left">
                        {email.creationDateTimeStamp &&
                        email.creationDateTimeStamp !== 0
                          ? `${moment
                              .unix(email.creationDateTimeStamp)
                              .format("Do MMMM YYYY")}`
                          : "No date found"}
                      </TableCell>
                      <TableCell padding="none" align="left">
                        {privileges.includes("MARKETING_ALL") && (
                          <Box mr={2}>
                            <IconButton
                              aria-label="details"
                              size="large"
                              onClick={() => goEditPage(email.id)}
                            >
                              <RemoveRedEye color="primary" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              size="large"
                              onClick={() => handleDelete(email.id)}
                            >
                              <Delete color="error" />
                            </IconButton>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>No Emails Found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        title="Delete Sent Email History"
        text="Are you sure that you want to delete this email's history?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteEmail}
      />
    </div>
  );
}

function SentEmailList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Sent Emails" />

      <Grid container justifyContent="space-between" spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Sent Emails
          </Typography>
        </Grid>
        {privileges.includes("MARKETING_ALL") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/sent-email/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  Send New Email
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Sent Emails</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SentEmailList;
