import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { emailValidate } from "../../validation/validation";
import { contactByIdGet, contactUpdatePut } from "../../requests/contact";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

function UpdateForm() {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [hasErrorMail, setHasErrorMail] = useState(false);
  const [errorMailText, setErrorMailText] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getContactDetails();
  }, []);

  const getContactDetails = async () => {
    setLoading(true);
    const responses = await contactByIdGet(uuid);
    try {
      setLoading(false);

      if (responses.success) {
        const contact = responses.data;
        setName(contact.name);
        setEmail(contact.email);
        setPhoneNumber(contact.phoneNumber);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        let checkError = emailValidate.test(value.trim());
        let error = checkError ? "" : "Please provide a valid email";

        setErrorMailText(error);
        setHasErrorMail(!checkError);
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const handleOnChangePhone = (value) => {
    if (value.trim()) {
      let validNumber = isValidPhoneNumber(`+${value}`);
      let error = validNumber ? "" : "Please enter a valid phone number";
      setPhoneNumber(value);
      setPhoneNumberError(error);
    } else {
      setPhoneNumberError("");
      setPhoneNumber("");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const postData = {
      uuid,
      name,
      email,
      phoneNumber: phoneNumber,
    };
    const responses = await contactUpdatePut(postData);
    try {
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate("/contact/list");
      } else {
        setLoading(false);
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const inputStyle = (center = false, letterSpacing = 2, ph = 20) => {
    return {
      height: 50,
      margin: 10,
      borderWidth: 1,
      borderColor: "#c4c4c4",
      width: "100%",
      borderRadius: 24,
      paddingHorizontal: ph,
      fontSize: 14,
      fontFamily: "DM Sans, sans-serif",
      textAlign: center ? "center" : "",
      letterSpacing,
      backgroundColor: "#e4e4e4",
    };
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Update
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              id="name"
              name="name"
              label="Name"
              required
              variant="outlined"
              value={name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <PhoneInput
              country="us"
              value={phoneNumber}
              onChange={handleOnChangePhone}
              countryCodeEditable={false}
              placeholder="Enter phone number*"
              containerStyle={{
                marginTop: 14,
                width: "100%",
                paddingRight: 8,
              }}
              inputStyle={{
                ...inputStyle(),
                paddingLeft: 60,
                backgroundColor: "#fff",
              }}
              buttonStyle={{
                backgroundColor: "#e4e4e4",
                borderLeft: 0,
                borderTop: 0,
                borderBottom: 0,
                paddingRight: 12,
                paddingLeft: 12,
                borderTopLeftRadius: 24,
                borderBottomLeftRadius: 24,
              }}
              dropdownStyle={{ width: "300px", height: "300%" }}
            />
            <span style={{ color: "#d93232", marginLeft: 10 }}>
              {phoneNumberError}
            </span>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              id="email"
              name="email"
              label="Email"
              required
              variant="outlined"
              value={email}
              error={hasErrorMail}
              helperText={errorMailText}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={
            name.length === 0 ||
            email === "" ||
            hasErrorMail ||
            phoneNumber.length < 4 ||
            phoneNumberError.length > 0
          }
        >
          Update
        </LoadingButton>
        <Button sx={{ mt: 3, ml: 1 }} onClick={() => navigate("/contact/list")}>
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function ContactUpdate() {
  return (
    <React.Fragment>
      <Helmet title="Contact Update" />

      <Typography variant="h3" gutterBottom display="inline">
        Contact Update
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/contact/list">
          Contacts
        </Link>
        <Typography> Update</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <UpdateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ContactUpdate;
