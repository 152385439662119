import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  TextField,
  Card,
  CardContent,
  Autocomplete,
  IconButton,
  Menu,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { spacing } from "@mui/system";
import { getToastError } from "../../utils/toasts";
import { Search } from "@mui/icons-material";
import { NavLink, useNavigate } from "react-router-dom";
import { allStockDonationGet, programAllGet } from "../../requests/history";
import moment from "moment";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable() {
  const navigate = useNavigate();
  const [stocks, setStocks] = useState([]);

  const [programs, setPrograms] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState({});
  const [inputValueProgram, setInputValueProgram] = useState("");
  const [programId, setProgramId] = useState(0);

  const [donationEmail, setDonationEmail] = useState("");
  const [anchorDonationEmail, setAnchorDonationEmail] = useState(null);
  const openDonationEmail = Boolean(anchorDonationEmail);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllPrograms();
  }, [inputValueProgram]);

  useEffect(() => {
    getAllStocks();
  }, [programId, donationEmail, pageNo, pageSize]);

  const getAllPrograms = async () => {
    const responses = await programAllGet({
      pageNo: 0,
      pageSize: 50,
    });
    try {
      if (responses.success) {
        setPrograms(responses.data.content);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };

  const getAllStocks = async () => {
    setLoading(true);
    const responses = await allStockDonationGet({
      page_no: pageNo,
      page_size: pageSize,
      program: programId > 0 ? programId : null,
      donationEmail: donationEmail.length > 0 ? donationEmail : null,
      sort_by: "id",
      asc_or_desc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setStocks(responses.data.content);
        setTotalItems(responses.data.totalElements);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.responses.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "donationEmail":
        setAnchorDonationEmail(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "donationEmail":
        setAnchorDonationEmail(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "donationEmail":
        setDonationEmail(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  return (
    <div>
      <Card mb={6}>
        <CardContent pb={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                fullWidth
                disablePortal
                id="combo-box-demo"
                size="small"
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedPrograms(newValue);
                    setProgramId(newValue.id);
                  } else {
                    setSelectedPrograms({});
                    setProgramId(0);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValueProgram(newInputValue);
                }}
                options={programs}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    size={"small"}
                    label="Filter By Program"
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Paper>
          <TableContainer>
            <Grid
              container
              display={loading ? "flex" : "none"}
              justifyContent="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    Sl. No.
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    {" "}
                    Name
                  </TableCell>{" "}
                  <TableCell align="left" padding="normal">
                    Email
                    <IconButton
                      onClick={(e) => handleClick(e, "donationEmail")}
                    >
                      <Search
                        fontSize="small"
                        color={donationEmail.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorDonationEmail}
                    open={openDonationEmail}
                    onClose={() => handleCloseMenu("donationEmail")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="donationEmail"
                      name="donationEmail"
                      value={donationEmail}
                      onChange={handleChange}
                      placeholder="Search Email"
                    />
                  </Menu>
                  <TableCell align="left" padding="normal">
                    Nonprofit
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Program
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Financial Brokerage
                  </TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {stocks && stocks.length > 0 ? (
                  stocks.map((stock, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={stock.id}
                      >
                        <TableCell align="left">
                          {pageNo * pageSize + index + 1}.
                        </TableCell>
                        <TableCell align="left">{stock.name}</TableCell>
                        <TableCell align="left">
                          {stock.donationEmail}
                        </TableCell>
                        <TableCell align="left">
                          {stock?.nonProfit?.name}
                        </TableCell>
                        <TableCell align="left">
                          {stock?.program?.name}
                        </TableCell>

                        <TableCell align="left">
                          {stock.financialBrokerage}
                        </TableCell>
                        <TableCell align="left">
                          {stock.creationDateTimeStamp
                            ? moment
                                .unix(stock.creationDateTimeStamp)
                                .format("Do MMMM YYYY, h:mm a")
                            : "No Date"}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      No Donated Stock History Found!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={pageSize}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>
    </div>
  );
}

function StockHistoryList() {
  return (
    <React.Fragment>
      <Helmet title="Donate Stock History" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Donate Stock History
          </Typography>
        </Grid>
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Donate Stock History</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default StockHistoryList;
