import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import "../../styles/quil.css";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  InputLabel,
  Alert,
  Autocomplete,
  createFilterOptions,
  Chip,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload, Delete } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { sentEmailCreatePost } from "../../requests/sent-email";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { emailValidate } from "../../validation/validation";
import { contactAllGet } from "../../requests/contact";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function CreateForm() {
  const navigate = useNavigate();

  const [mailBody, setMailBody] = useState("");
  const [subject, setSubject] = useState("");

  const filter = createFilterOptions();
  const [keywordOptions, setKeywordOptions] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [inputValueKeyword, setInputValueKeyword] = useState("");

  const [recipientEmailOptions, setRecipientEmailOptions] = useState([]);
  const [recipientEmails, setRecipientEmails] = useState([]);
  const [inputValueEmails, setInputValueEmails] = useState("");

  const [hasErrorEmail, setHasErrorEmail] = useState(false);
  const [errorTextEmail, setErrorTextEmail] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllContacts();
  }, []);

  const getAllContacts = async () => {
    setLoading(true);
    const responses = await contactAllGet({
      pageNo: 0,
      pageSize: 500,
    });
    try {
      setLoading(false);

      if (responses.success) {
        let emailValues = responses.data.content.map((value) => value.email);

        setRecipientEmailOptions(emailValues);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "subject":
        setSubject(value);
        break;
      default:
        break;
    }
  };

  const handleChangeEditor = (value) => {
    setMailBody(value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const postData = {
      html: true,
      mailBody,
      subject,
      recipientEmails,
    };
    const responses = await sentEmailCreatePost(postData);
    try {
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate("/sent-email/list");
      } else {
        setLoading(false);
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          New Email
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              multiple
              onChange={(event, newValue) => {
                let count = 0;
                newValue.map((email) => {
                  if (!emailValidate.test(email)) {
                    count++;
                  }
                });
                if (count > 0) {
                  setHasErrorEmail(true);
                  setErrorTextEmail("Please add valid mails");
                } else {
                  setHasErrorEmail(false);
                  setErrorTextEmail("");
                }
                setRecipientEmails(newValue);
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== "") {
                  filtered.push(`${params.inputValue}`);
                }

                return filtered;
              }}
              onInputChange={(event, newInputValue) => {
                setInputValueEmails(newInputValue);
              }}
              options={recipientEmailOptions}
              getOptionLabel={(option) => {
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option;
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color={emailValidate.test(option) ? "secondary" : "error"}
                    label={`${option}`}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  required
                  error={hasErrorEmail}
                  helperText={errorTextEmail}
                  label="Recipient Emails"
                />
              )}
            />
          </Grid>
        </Grid>
        <TextField
          required
          fullWidth
          margin="normal"
          id="subject"
          name="subject"
          label="Subject"
          variant="outlined"
          value={subject}
          onChange={handleChange}
        />
        <InputLabel sx={{ mt: 2, mb: 1 }}>Mail Body*</InputLabel>
        <ReactQuill
          className="ql-editor"
          value={mailBody}
          placeholder="Write mail body..."
          onChange={handleChangeEditor}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={
            subject === "" ||
            hasErrorEmail ||
            recipientEmails.length === 0 ||
            mailBody === "" ||
            loading
          }
        >
          Send Mail
        </LoadingButton>
        <Button
          sx={{ mt: 3, ml: 1 }}
          onClick={() => navigate("/sent-email/list")}
        >
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function SentEmailCreate() {
  return (
    <React.Fragment>
      <Helmet subject="Send Email" />

      <Typography variant="h3" gutterBottom display="inline">
        New Email
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/sent-email/list">
          Sent Emails
        </Link>
        <Typography>Send New Email</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CreateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SentEmailCreate;
