import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

// import useAuth from "../../hooks/useAuth";
import { signupAdminPost } from "../../requests/auth";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import { getToastSuccess } from "../../utils/toasts";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignUp() {
  const navigate = useNavigate();
  // const { signUp } = useAuth();

  const onSignUp = async (values, setErrors, setStatus, setSubmitting) => {
    const postData = {
      name: values.name,
      email: values.email,
      // password: values.password,
    };
    const response = await signupAdminPost(postData);
    try {
      if (response.success) {
        getToastSuccess(response.message);
        navigate("/");
      } else {
        const message = response.message;

        setStatus({ success: false });
        setErrors({ submit: message });
        setSubmitting(false);
      }
      // signUp(
      //   values.email,
      //   values.password,
      //   values.lastName
      // );
    } catch (error) {
      const message = error.response.data.message || "Something went wrong";

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required("Name is required"),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        // password: Yup.string()
        //   .matches(/^(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/,
        //   "Password must contain at least one digit and a special character (!@#$%&)")
        //   .required("Required"),
        // confirmPassword: Yup.string().when("password", {
        //   is: (val) => (val && val.length > 0 ? true : false),
        //   then: Yup.string().oneOf(
        //     [Yup.ref("password")],
        //     "Both password need to be the same"
        //   ),
        // }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        onSignUp(values, setErrors, setStatus, setSubmitting);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Alert mt={3} mb={3} severity="info">
            After Signing up, you'll get a password via <strong>mail</strong>.
          </Alert>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="text"
            name="name"
            label="Name"
            value={values.name}
            error={Boolean(touched.name && errors.name)}
            fullWidth
            helperText={touched.name && errors.name}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          {/* <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Sign up
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignUp;
