import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  InputLabel,
  Alert,
  Autocomplete,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload, Delete } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { roleAllGet } from "../../requests/role";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { emailValidate } from "../../validation/validation";
import { signupAdminPost } from "../../requests/auth";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function CreateForm() {
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState(0);
  const [roles, setRoles] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [hasErrorMail, setHasErrorMail] = useState(false);
  const [errorMailText, setErrorMailText] = useState("");
  const inputFile = useRef(null);
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [variant, setVariant] = useState("success");

  useEffect(() => {
    getAllRoles();
  }, []);

  const getAllRoles = async () => {
    setLoading(true);
    const responses = await roleAllGet({
      roleType: "ADMIN",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setRoles(responses.data);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        let checkError = emailValidate.test(value.trim());
        let error = checkError ? "" : "Please provide a valid email";

        setErrorMailText(error);
        setHasErrorMail(!checkError);
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const postData = {
      name,
      email,
      roleId,
    };
    const responses = await signupAdminPost(postData);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate("/user/list/ADMIN");
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Create
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              id="name"
              name="name"
              label="Name"
              required
              variant="outlined"
              value={name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              margin="normal"
              id="email"
              name="email"
              label="Email"
              required
              variant="outlined"
              value={email}
              error={hasErrorMail}
              helperText={errorMailText}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(event, newValue) => {
                if (newValue) {
                  setRoleId(newValue.id);
                } else {
                  setRoleId(0);
                }
              }}
              options={roles}
              getOptionLabel={(option) => option.roleName}
              renderInput={(params) => (
                <TextField {...params} required margin="normal" label="Role" />
              )}
            />
          </Grid>
        </Grid>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3, mr: 1 }}
          onClick={handleSubmit}
          disabled={
            name.length === 0 || email === "" || hasErrorMail || roleId === 0
          }
        >
          Create
        </LoadingButton>
        <Button sx={{ mt: 3 }} onClick={() => navigate("/user/list/ADMIN")}>
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function UserCreate() {
  return (
    <React.Fragment>
      <Helmet title="Admin Create" />

      <Typography variant="h3" gutterBottom display="inline">
        Admin Create
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/user/list/ADMIN">
          Admins
        </Link>
        <Typography> Create</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CreateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UserCreate;
