import axios from "axios";
import { basePath, headers } from "../utils/http";

export const productPageCreatePost = async (data) => {
  try {
    const response = await axios.post(
      `${basePath()}/product-page`,
      data,
      headers()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const productByPageTagGet = async (pageTag) => {
  try {
    const response = await axios.get(
      `${basePath()}/product-page/by-pageTag?pageTag=${pageTag}`,
      headers()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};

export const pageTagByProductGet = async (productId) => {
  try {
    const response = await axios.get(
      `${basePath()}/product-page/by-product?productId=${productId}`,
      headers()
    );
    if (response.data.success) {
      return {
        success: true,
        data: response.data.payload,
        message: response.data.message,
      };
    } else {
      return { success: false, data: {}, message: response.data.message };
    }
  } catch (error) {
    return {
      success: false,
      data: {},
      message:
        error.response === undefined
          ? "Something went wrong"
          : error.response.data.message,
    };
  }
};
