import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Breadcrumbs, Divider, Link, Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";

import ChangePasswordComponent from "../../components/auth/ChangePassword";
import { NavLink } from "react-router-dom";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ChangePassword() {
  return (
    <React.Fragment>
      {/* <Brand /> */}
      <Helmet title="Change Password" />
      <Typography variant="h3" gutterBottom display="inline">
        Change Password
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography>Change Password</Typography>
      </Breadcrumbs>

      <Divider sx={{ mt: 6, mb: 6 }} />

      <Wrapper>
        <ChangePasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default ChangePassword;
