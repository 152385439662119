import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  Alert,
  CircularProgress,
  TextField,
  Menu,
  Breadcrumbs,
  Stack,
} from "@mui/material";
import {
  Add as AddIcon,
  ArrowBack,
  Delete,
  Edit,
  Search,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { ConfirmationDialog } from "../../../components/dialogs/ConfirmationDialog";
import { sectionAllGet, sectionByIdDelete } from "../../../requests/section";
import { pageAllGet } from "../../../requests/page";
import { getToastError, getToastSuccess } from "../../../utils/toasts";
import { useSelector } from "react-redux";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const { pageId } = useParams();

  const [sections, setSections] = useState([]);

  const [title, setTitle] = useState("");
  const [anchorTitle, setAnchorTitle] = useState(null);
  const openTitle = Boolean(anchorTitle);

  const [tag, setTag] = useState("");
  const [anchorTag, setAnchorTag] = useState(null);
  const openTag = Boolean(anchorTag);

  const [prefix, setPrefix] = useState("");
  const [anchorPrefix, setAnchorPrefix] = useState(null);
  const openPrefix = Boolean(anchorPrefix);

  const [layout, setLayout] = useState("");
  const [anchorLayout, setAnchorLayout] = useState(null);
  const openLayout = Boolean(anchorLayout);

  const [order, setOrder] = useState("");
  const [anchorOrder, setAnchorOrder] = useState(null);
  const openOrder = Boolean(anchorOrder);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [sectionId, setSectionId] = useState(0);

  useEffect(() => {
    getAllSections();
  }, [pageNo, pageSize, title, tag, prefix, order, layout]);

  const getAllSections = async () => {
    setLoading(true);
    const responses = await sectionAllGet({
      pageNo,
      pageSize,
      title: title.length > 0 ? title : null,
      tag: tag.length > 0 ? tag : null,
      prefix: prefix.length > 0 ? prefix : null,
      layout: layout.length > 0 ? layout : null,
      order: order.length > 0 ? order : null,
      pageId: pageId !== 0 ? pageId : null,
      sortBy: "order",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setSections(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "title":
        setAnchorTitle(event.currentTarget);
        break;
      case "tag":
        setAnchorTag(event.currentTarget);
        break;
      case "prefix":
        setAnchorPrefix(event.currentTarget);
        break;
      case "layout":
        setAnchorLayout(event.currentTarget);
        break;
      case "order":
        setAnchorOrder(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "title":
        setAnchorTitle(null);
        break;
      case "tag":
        setAnchorTag(null);
        break;
      case "prefix":
        setAnchorPrefix(null);
        break;
      case "layout":
        setAnchorLayout(null);
        break;
      case "order":
        setAnchorOrder(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "tag":
        setTag(value);
        break;
      case "prefix":
        setPrefix(value);
        break;
      case "order":
        setOrder(value);
        break;
      case "layout":
        setLayout(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (sectionId) => {
    setConfirmationOpen(true);
    setSectionId(sectionId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setSectionId(0);
  };

  const deleteSection = async () => {
    setLoading(true);
    const responses = await sectionByIdDelete(sectionId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllSections();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id) => {
    navigate(`/page-management/section/update/${pageId}/${id}`);
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  Sl. No.
                </TableCell>
                <TableCell align="left" padding="normal">
                  Title
                  <IconButton onClick={(e) => handleClick(e, "title")}>
                    <Search
                      fontSize="small"
                      color={title.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorTitle}
                  open={openTitle}
                  onClose={() => handleCloseMenu("title")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="title"
                    name="title"
                    value={title}
                    onChange={handleChange}
                    placeholder="Search Title"
                  />
                </Menu>
                <TableCell align="left" padding="normal">
                  Tag
                  <IconButton onClick={(e) => handleClick(e, "tag")}>
                    <Search
                      fontSize="small"
                      color={tag.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorTag}
                  open={openTag}
                  onClose={() => handleCloseMenu("tag")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="tag"
                    name="tag"
                    value={tag}
                    onChange={handleChange}
                    placeholder="Search Tag"
                  />
                </Menu>
                {/* <TableCell align="left" padding="normal">
                  Prefix
                  <IconButton onClick={(e) => handleClick(e, "prefix")}>
                    <Search
                      fontSize="small"
                      color={prefix.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorPrefix}
                  open={openPrefix}
                  onClose={() => handleCloseMenu("prefix")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="prefix"
                    name="prefix"
                    value={prefix}
                    onChange={handleChange}
                    placeholder="Search Prefix"
                  />
                </Menu>
                <TableCell align="left" padding="normal">
                  Layout
                  <IconButton onClick={(e) => handleClick(e, "layout")}>
                    <Search
                      fontSize="small"
                      color={layout.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorLayout}
                  open={openLayout}
                  onClose={() => handleCloseMenu("layout")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="layout"
                    name="layout"
                    value={layout}
                    onChange={handleChange}
                    placeholder="Search Layout"
                  />
                </Menu> */}

                <TableCell align="left" padding="normal">
                  Order
                  <IconButton onClick={(e) => handleClick(e, "order")}>
                    <Search
                      fontSize="small"
                      color={order.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorOrder}
                  open={openOrder}
                  onClose={() => handleCloseMenu("order")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    type={"number"}
                    id="order"
                    name="order"
                    value={order}
                    onChange={handleChange}
                    placeholder="Search Order"
                  />
                </Menu>
                {privileges.includes("CMS_UPDATE") ||
                privileges.includes("CMS_DELETE") ? (
                  <TableCell align="left" padding="normal">
                    Actions
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {sections && sections.length > 0 ? (
                sections.map((sec, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={sec.id}>
                      <TableCell align="left">
                        {pageNo * pageSize + index + 1}.
                      </TableCell>
                      <TableCell align="left">{sec.title}</TableCell>
                      <TableCell align="left">{sec.tag}</TableCell>
                      {/* <TableCell align="left">{sec.prefix}</TableCell>
                      <TableCell align="left">{sec.layout}</TableCell> */}
                      <TableCell align="left">{sec.order}</TableCell>
                      <TableCell padding="none" align="left">
                        <Box mr={2}>
                          {privileges.includes("CMS_UPDATE") && (
                            <IconButton
                              aria-label="details"
                              size="large"
                              onClick={() => goEditPage(sec.id)}
                            >
                              <Edit color="primary" />
                            </IconButton>
                          )}
                          {privileges.includes("CMS_DELETE") && (
                            <IconButton
                              aria-label="delete"
                              size="large"
                              onClick={() => handleDelete(sec.id)}
                            >
                              <Delete sx={{ color: "#d93232" }} />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>No Sections Found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        title="Delete Section"
        text="Are you sure that you want to delete this section?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteSection}
      />
    </div>
  );
}

function SectionList() {
  const { pageId } = useParams();

  const [selectedPage, setSelectedPage] = useState({});
  const [pages, setPages] = useState([]);
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);

  useEffect(() => {
    getAllPages();
  }, []);

  useEffect(() => {
    if (pages.length > 0) {
      let pageIndex = pages.findIndex((page) => page.id == pageId);
      if (pageIndex !== -1) {
        setSelectedPage(pages[pageIndex]);
      }
    }
  }, [pages]);

  const getAllPages = async () => {
    const responses = await pageAllGet({
      pageNo: 0,
      pageSize: 500,
    });
    try {
      if (responses.success) {
        setPages(responses.data.content);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };
  return (
    <React.Fragment>
      <Helmet title="Section" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Section
          </Typography>
        </Grid>
        {privileges.includes("CMS_CREATE") && (
          <Grid item>
            <div>
              <Link
                component={NavLink}
                to={`/page-management/section/create/${pageId}`}
              >
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Section
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>
      {selectedPage && Object.keys(selectedPage).length > 0 && (
        <>
          <Grid justifyContent="space-between" container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                gutterBottom
                display="inline"
                color="secondary"
              >
                Page Title: {selectedPage.title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                gutterBottom
                display="inline"
                color="secondary"
              >
                Page Tag: {selectedPage.tag}
              </Typography>
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent={"space-between"}>
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link component={NavLink} to="/private">
                Dashboard
              </Link>
              <Link component={NavLink} to={`/page-management/page/list`}>
                Pages
              </Link>
              <Typography> Sections</Typography>
            </Breadcrumbs>
            <Link component={NavLink} to={`/page-management/page/list`}>
              <Button variant="outlined">
                {" "}
                <ArrowBack sx={{ mr: 1 }} /> Go Back
              </Button>
            </Link>
          </Stack>
        </>
      )}

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SectionList;
