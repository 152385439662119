import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  InputLabel,
  FormControl,
  FormLabel,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload, Delete } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { fileCommonPost } from "../../requests/file";
import { verificationDocCreatePost } from "../../requests/varificationDoc";
import { getToastError, getToastSuccess } from "../../utils/toasts";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function CreateForm() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [required, setRequired] = useState(false);
  const [active, setActive] = useState(false);
  const [expirationNeeded, setExpirationNeeded] = useState(false);

  const inputFile = useRef(null);
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "active":
        setActive(value);
        break;
      case "required":
        setRequired(value);
        break;
      case "expirationNeeded":
        setExpirationNeeded(value);
        break;
      default:
        break;
    }
  };

  const handleChangeFile = (e) => {
    if (e.target.files[0] != null) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    inputFile.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImage("");
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (file) {
      let formData = new FormData();
      formData.append("file", file);
      const response = await fileCommonPost(formData, "VERIFICATION_DOC_ICON");

      try {
        setLoading(false);
        if (response.success) {
          handleCreate(response.data.id);
        } else {
          getToastError(response.message);
        }
      } catch (error) {
        setLoading(false);
        getToastError(error.response.data.message);
      }
    } else {
      handleCreate(null);
    }
  };

  const handleCreate = async (id) => {
    setLoading(true);
    const postData = {
      iconFileId: id,
      name,
      required,
      expirationNeeded,
      active,
    };
    const responses = await verificationDocCreatePost(postData);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate("/verification-docs/list");
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Create
        </Typography>
        <InputLabel sx={{ mt: 2, mb: 1 }}>Icon</InputLabel>
        <Grid container>
          <Grid item xs={12}>
            {file ? <img style={{ height: "30vh" }} src={image} /> : ""}
          </Grid>
          <Grid item xs={12}>
            {file ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={() => handleUpload()}
                >
                  <CloudUpload mr={2} /> Change
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  color="error"
                  component="span"
                  onClick={() => handleRemove()}
                >
                  <Delete mr={2} /> Remove
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={() => handleUpload()}
              >
                <CloudUpload mr={2} /> Upload
              </Button>
            )}
          </Grid>
        </Grid>

        <input
          type="file"
          id="file"
          accept="image/*"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={handleChangeFile}
          onClick={(event) => {
            event.target.value = null;
          }}
        />

        <TextField
          fullWidth
          required
          margin="normal"
          id="name"
          name="name"
          label="Name"
          variant="outlined"
          value={name}
          onChange={handleChange}
        />

        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} sx={{ pl: 1 }}>
            <FormControl fullWidth margin="normal">
              <FormLabel id="demo-required">Is Required?</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-required"
                name="required"
                value={required}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={4}>
            <FormControl fullWidth margin="normal">
              <FormLabel id="demo-expirationNeeded">
                Has Expiration Date?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-expirationNeeded"
                name="expirationNeeded"
                value={expirationNeeded}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={4}>
            <FormControl fullWidth margin="normal">
              <FormLabel id="demo-active">Is Active?</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-active"
                name="active"
                value={active}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={name === ""}
        >
          Create
        </LoadingButton>
        <Button
          sx={{ mt: 3, ml: 1 }}
          onClick={() => navigate("/verification-docs/list")}
        >
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function VerificationDocsCreate() {
  return (
    <React.Fragment>
      <Helmet title="Verification Doc Create" />

      <Typography variant="h3" gutterBottom display="inline">
        Verification Doc Create
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/verification-docs/list">
          Verification Docs
        </Link>
        <Typography> Create</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CreateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default VerificationDocsCreate;
