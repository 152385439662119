import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import "../../styles/quil.css";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  InputLabel,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { legalDocsAllGet, legalDocsCreatePost } from "../../requests/legalDocs";
import { getToastError, getToastSuccess } from "../../utils/toasts";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function UpdateForm() {
  const navigate = useNavigate();
  const [aboutUs, setAboutUs] = useState("");
  const [contactUs, setContactUs] = useState("");
  const [faq, setFaq] = useState("");
  const [legal, setLegal] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllDocs();
  }, []);

  const getAllDocs = async () => {
    setLoading(true);
    const responses = await legalDocsAllGet();
    try {
      setLoading(false);

      if (responses.success) {
        let doc = responses.data;
        if (responses.data) {
          setAboutUs(doc.aboutUs);
          setContactUs(doc.contactUs);
          setFaq(doc.faq);
          setLegal(doc.legal);
          setPrivacyPolicy(doc.privacyPolicy);
          setTermsAndConditions(doc.termsAndConditions);
        }
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    const postData = {
      aboutUs: window.btoa(unescape(encodeURIComponent(aboutUs))),
      contactUs: window.btoa(unescape(encodeURIComponent(contactUs))),
      faq: window.btoa(unescape(encodeURIComponent(faq))),
      legal: window.btoa(unescape(encodeURIComponent(legal))),
      privacyPolicy: window.btoa(unescape(encodeURIComponent(privacyPolicy))),
      termsAndConditions: window.btoa(
        unescape(encodeURIComponent(termsAndConditions))
      ),
    };
    const responses = await legalDocsCreatePost(postData);
    try {
      setLoading(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        navigate("/legal-docs/list");
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Update
        </Typography>
        <InputLabel sx={{ mt: 2, mb: 1 }}>About Us</InputLabel>
        <ReactQuill
          className="ql-editor"
          value={aboutUs}
          placeholder="Write About Us..."
          onChange={(value) => {
            setAboutUs(value);
          }}
        />

        <InputLabel sx={{ mt: 2, mb: 1 }}>Contact Us</InputLabel>
        <ReactQuill
          className="ql-editor"
          value={contactUs}
          placeholder="Write Contact Us..."
          onChange={(value) => {
            setContactUs(value);
          }}
        />

        <InputLabel sx={{ mt: 2, mb: 1 }}>Legal</InputLabel>
        <ReactQuill
          className="ql-editor"
          value={legal}
          placeholder="Write Legal..."
          onChange={(value) => {
            setLegal(value);
          }}
        />

        <InputLabel sx={{ mt: 2, mb: 1 }}>Privacy Policy</InputLabel>
        <ReactQuill
          className="ql-editor"
          value={privacyPolicy}
          placeholder="Write Privacy Policy..."
          onChange={(value) => {
            setPrivacyPolicy(value);
          }}
        />

        <InputLabel sx={{ mt: 2, mb: 1 }}>Terms and Conditions</InputLabel>
        <ReactQuill
          className="ql-editor"
          value={termsAndConditions}
          placeholder="Write Terms and Conditions..."
          onChange={(value) => {
            setTermsAndConditions(value);
          }}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleUpdate}
        >
          Update
        </LoadingButton>
        <Button
          sx={{ mt: 3, ml: 1 }}
          onClick={() => navigate("/legal-docs/list")}
        >
          Cancel
        </Button>
      </CardContent>
    </Card>
  );
}

function LegalDocUpdate() {
  return (
    <React.Fragment>
      <Helmet title="Legal Document Update" />

      <Typography variant="h3" gutterBottom display="inline">
        Legal Document Update
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/legal-docs/list">
          Legal Documents
        </Link>
        <Typography> Update</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <UpdateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default LegalDocUpdate;
