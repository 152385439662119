import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  CircularProgress,
  Chip,
  Menu,
  TextField,
  MenuItem,
  Breadcrumbs,
} from "@mui/material";
import { Add as AddIcon, Delete, Edit, Search } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { useSelector } from "react-redux";
import {
  subscriptionPackegesAllGet,
  subscriptionPackegesByIdDelete,
} from "../../requests/subscriptionPackeges";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);

  const [name, setName] = useState("");
  const [anchorName, setAnchorName] = useState(null);
  const openName = Boolean(anchorName);

  const [isActive, setIsActive] = useState("");
  const [anchorIsActive, setAnchorIsActive] = useState(null);
  const openIsActive = Boolean(anchorIsActive);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [packageId, setPackageId] = useState(0);

  useEffect(() => {
    getAllPackages();
  }, [name, isActive, pageNo, pageSize]);

  const getAllPackages = async () => {
    setLoading(true);
    const responses = await subscriptionPackegesAllGet({
      pageNo,
      pageSize,
      name: name.length > 0 ? name : null,
      isActive: isActive.length > 0 ? isActive : null,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setPackages(responses.data.content);
        setTotalItems(responses.data.totalElements);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "name":
        setAnchorName(event.currentTarget);
        break;
      case "isActive":
        setAnchorIsActive(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "name":
        setAnchorName(null);
        break;
      case "isActive":
        setAnchorIsActive(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (packageId) => {
    setConfirmationOpen(true);
    setPackageId(packageId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setPackageId(0);
  };

  const deleteSubscription = async () => {
    setLoading(true);
    const responses = await subscriptionPackegesByIdDelete(packageId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllPackages();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id) => {
    navigate(`/subscription-packages/update/${id}`);
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  Sl. No.
                </TableCell>
                <TableCell align="left" padding="normal">
                  {" "}
                  Name
                  <IconButton onClick={(e) => handleClick(e, "name")}>
                    <Search
                      fontSize="small"
                      color={name.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorName}
                  open={openName}
                  onClose={() => handleCloseMenu("name")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    placeholder="Search Name"
                  />
                </Menu>

                <TableCell align="left" padding="normal">
                  Description
                </TableCell>
                <TableCell align="left" padding="normal">
                  Fee(In $)
                </TableCell>
                <TableCell align="left" padding="normal">
                  Period (In Months)
                </TableCell>
                <TableCell align="left" padding="normal">
                  {" "}
                  Active?
                  <IconButton onClick={(e) => handleClick(e, "isActive")}>
                    <Search
                      fontSize="small"
                      color={isActive.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorIsActive}
                  open={openIsActive}
                  onClose={() => handleCloseMenu("isActive")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem value={null} disabled>
                    Search is active or not
                  </MenuItem>

                  <MenuItem
                    value={""}
                    sx={{
                      backgroundColor: isActive == "" ? "#219B89" : "",
                      color: isActive == "" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorIsActive(null);
                      setIsActive("");
                    }}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    value={true}
                    sx={{
                      backgroundColor: isActive == "true" ? "#219B89" : "",
                      color: isActive == "true" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorIsActive(null);
                      setIsActive("true");
                    }}
                  >
                    Yes
                  </MenuItem>
                  <MenuItem
                    value={false}
                    sx={{
                      backgroundColor: isActive == "false" ? "#219B89" : "",
                      color: isActive == "false" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorIsActive(null);
                      setIsActive("false");
                    }}
                  >
                    No
                  </MenuItem>
                </Menu>
                {privileges.includes("SUBSCRIPTION_PACKAGE_UPDATE") ||
                privileges.includes("SUBSCRIPTION_PACKAGE_DELETE") ? (
                  <TableCell align="left" padding="normal">
                    Actions
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {packages && packages.length > 0 ? (
                packages.map((subscription, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={subscription.id}
                    >
                      <TableCell align="left">
                        {pageNo * pageSize + index + 1}.
                      </TableCell>
                      <TableCell align="left">{subscription.name}</TableCell>
                      <TableCell align="left">
                        {subscription.description}
                      </TableCell>
                      <TableCell align="left">{subscription.fee}</TableCell>
                      <TableCell align="left">
                        {subscription.periodInMonths}
                      </TableCell>
                      <TableCell align="left">
                        {subscription.active ? (
                          <Chip label="Yes" color="success" />
                        ) : (
                          <Chip label="No" color="error" />
                        )}
                      </TableCell>

                      <TableCell padding="none" align="left">
                        <Box mr={2}>
                          {privileges.includes(
                            "SUBSCRIPTION_PACKAGE_UPDATE"
                          ) && (
                            <IconButton
                              aria-label="details"
                              size="large"
                              onClick={() => goEditPage(subscription.id)}
                            >
                              <Edit color="primary" />
                            </IconButton>
                          )}
                          {privileges.includes(
                            "SUBSCRIPTION_PACKAGE_DELETE"
                          ) && (
                            <IconButton
                              aria-label="delete"
                              size="large"
                              onClick={() => handleDelete(subscription.id)}
                            >
                              <Delete sx={{ color: "#d93232" }} />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    No Subscription Packages Found!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        title="Delete Subscription Package"
        text="Are you sure that you want to delete this subscription package?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteSubscription}
      />
    </div>
  );
}

function SubscriptionPackagesList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Subscription Packages" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Subscription Packages
          </Typography>
        </Grid>
        {privileges.includes("SUBSCRIPTION_PACKAGE_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/subscription-packages/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Package
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Subscription Packages</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SubscriptionPackagesList;
