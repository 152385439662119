import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  TextField,
  Menu,
  Chip,
  MenuItem,
  Button,
  Link,
} from "@mui/material";
import {
  Add as AddIcon,
  CheckCircle,
  CheckCircleOutline,
  CloudUpload,
  Delete,
  Edit,
  Search,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { contactAllGet, contactByIdDelete } from "../../requests/contact";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { useSelector } from "react-redux";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);

  const [name, setName] = useState("");
  const [anchorName, setAnchorName] = useState(null);
  const openName = Boolean(anchorName);

  const [email, setEmail] = useState("");
  const [anchorEmail, setAnchorEmail] = useState(null);
  const openEmail = Boolean(anchorEmail);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [anchorPhoneNumber, setAnchorPhoneNumber] = useState(null);
  const openPhoneNumber = Boolean(anchorPhoneNumber);

  //   const [isResolved, setIsResolved] = useState("");
  //   const [anchorIsResolved, setAnchorIsResolved] = useState(null);
  //   const openIsResolved = Boolean(anchorIsResolved);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [contactId, setContactId] = useState(0);

  useEffect(() => {
    getAllContacts();
  }, [pageNo, pageSize, name, email, phoneNumber]);

  const getAllContacts = async () => {
    setLoading(true);
    const responses = await contactAllGet({
      pageNo,
      pageSize,
      name: name.length > 0 ? name : null,
      email: email.length > 0 ? email : null,
      phoneNumber: phoneNumber.length > 0 ? phoneNumber : null,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setContacts(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "name":
        setAnchorName(event.currentTarget);
        break;
      case "email":
        setAnchorEmail(event.currentTarget);
        break;
      case "phoneNumber":
        setAnchorPhoneNumber(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "name":
        setAnchorName(null);
        break;
      case "email":
        setAnchorEmail(null);
        break;
      case "phoneNumber":
        setAnchorPhoneNumber(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phoneNumber":
        setPhoneNumber(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (contactId) => {
    setConfirmationOpen(true);
    setContactId(contactId);
  };

  const handleUpdate = (contactId) => {
    setContactId(contactId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setContactId(0);
  };

  const deleteContact = async () => {
    setLoading(true);
    const responses = await contactByIdDelete(contactId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllContacts();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (uuid) => {
    navigate(`/contact/update/${uuid}`);
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  Sl. No.
                </TableCell>
                <TableCell align="left" padding="normal">
                  Name{" "}
                  <IconButton onClick={(e) => handleClick(e, "name")}>
                    <Search
                      fontSize="small"
                      color={name.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorName}
                  open={openName}
                  onClose={() => handleCloseMenu("name")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    placeholder="Search Name"
                  />
                </Menu>
                <TableCell align="left" padding="normal">
                  Email
                  <IconButton onClick={(e) => handleClick(e, "email")}>
                    <Search
                      fontSize="small"
                      color={email.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEmail}
                  open={openEmail}
                  onClose={() => handleCloseMenu("email")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    placeholder="Search Email"
                  />
                </Menu>
                <TableCell align="left" padding="normal">
                  Phone Number
                  <IconButton onClick={(e) => handleClick(e, "phoneNumber")}>
                    <Search
                      fontSize="small"
                      color={phoneNumber.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorPhoneNumber}
                  open={openPhoneNumber}
                  onClose={() => handleCloseMenu("phoneNumber")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={handleChange}
                    placeholder="Search Phone Number"
                  />
                </Menu>
                {privileges.includes("MARKETING_ALL") && (
                  <TableCell align="left" padding="normal">
                    Action
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {contacts && contacts.length > 0 ? (
                contacts.map((contact, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={contact.id}
                    >
                      <TableCell align="left">
                        {pageNo * pageSize + index + 1}.
                      </TableCell>
                      <TableCell align="left">{contact.name}</TableCell>
                      <TableCell align="left">{contact.email}</TableCell>
                      <TableCell align="left">{contact.phoneNumber}</TableCell>
                      <TableCell padding="none" align="left">
                        {privileges.includes("MARKETING_ALL") && (
                          <Box mr={2}>
                            <IconButton
                              aria-label="details"
                              size="large"
                              onClick={() => goEditPage(contact.uuid)}
                            >
                              <Edit color="primary" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              size="large"
                              onClick={() => handleDelete(contact.uuid)}
                            >
                              <Delete sx={{ color: "#d93232" }} />
                            </IconButton>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>No Contacts Found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        title="Delete Contact"
        text="Are you sure that you want to delete this contact?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteContact}
      />
    </div>
  );
}

function ContactList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Contacts" />

      <Grid container justifyContent="space-between" spacing={4}>
        <Grid item xs={12} sm={4} md={6} lg={8}>
          <Typography variant="h3" gutterBottom display="inline">
            Contacts
          </Typography>
        </Grid>
        {privileges.includes("MARKETING_ALL") && (
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            lg={4}
            sx={{ justifyContent: "end", display: "flex", alightItems: "end" }}
          >
            <div>
              <Link component={NavLink} to="/contact/upload">
                <Button variant="contained" color="primary" sx={{ mr: 1 }}>
                  <CloudUpload sx={{ mr: 1 }} />
                  Upload Contacts
                </Button>
              </Link>
            </div>
            <div>
              <Link component={NavLink} to="/contact/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Contact
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Products</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ContactList;
