import axios from "axios";
import { basePath, headers } from "../utils/http";

export const scholarAllGet = async (params) => {
    try {
      const response = await axios.get(`${basePath()}/scholar/all`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        }
      });
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const scholarCreatePost = async (data) => {
    try {
      const response = await axios.post(`${basePath()}/scholar/admin`, data, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const scholarUpdatePut = async (data) => {
    try {
      const response = await axios.put(`${basePath()}/scholar/admin`, data, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const scholarByIdGet = async (scholarId) => {
    try {
      const response = await axios.get(`${basePath()}/scholar/id/${scholarId}`, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const scholarByIdDelete = async (scholarId) => {
    try {
      const response = await axios.delete(`${basePath()}/scholar/admin/id/${scholarId}`, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };
