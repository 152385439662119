import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  TextField,
  Menu,
  Chip,
  MenuItem,
  Card,
  CardContent,
  InputLabel,
  Select,
  Link,
  Stack,
} from "@mui/material";
import {
  Add as AddIcon,
  CheckCircleOutline,
  Delete,
  Search,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  inquiryAllGet,
  inquiryByIdDelete,
  inquiryUpdatePut,
} from "../../requests/inquiry";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { useSelector } from "react-redux";
import moment from "moment";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const [inquiries, setInquiries] = useState([]);

  const [name, setName] = useState("");
  const [anchorName, setAnchorName] = useState(null);
  const openName = Boolean(anchorName);

  const [email, setEmail] = useState("");
  const [anchorEmail, setAnchorEmail] = useState(null);
  const openEmail = Boolean(anchorEmail);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [anchorPhoneNumber, setAnchorPhoneNumber] = useState(null);
  const openPhoneNumber = Boolean(anchorPhoneNumber);

  const [isResolved, setIsResolved] = useState("");
  const [anchorIsResolved, setAnchorIsResolved] = useState(null);
  const openIsResolved = Boolean(anchorIsResolved);

  const [nameOfNonProfit, setNameOfNonProfit] = useState("");
  const [anchorNameOfNonProfit, setAnchorNameOfNonProfit] = useState(null);
  const openNameOfNonProfit = Boolean(anchorNameOfNonProfit);

  const [inquiryType, setInquiryType] = useState("GENERAL");

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationOpenResolve, setConfirmationOpenResolve] = useState(false);
  const [inquiryId, setInquiryId] = useState(0);

  useEffect(() => {
    getAllInquiries();
  }, [
    pageNo,
    pageSize,
    name,
    email,
    phoneNumber,
    isResolved,
    inquiryType,
    nameOfNonProfit,
  ]);

  const getAllInquiries = async () => {
    setLoading(true);
    const responses = await inquiryAllGet({
      pageNo,
      pageSize,
      nameOfNonProfit: nameOfNonProfit.length > 0 ? nameOfNonProfit : null,
      inquiryType: inquiryType.length > 0 ? inquiryType : null,
      name: name.length > 0 ? name : null,
      email: email.length > 0 ? email : null,
      phoneNumber: phoneNumber.length > 0 ? phoneNumber : null,
      isResolved: isResolved.length > 0 ? isResolved : null,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setInquiries(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "name":
        setAnchorName(event.currentTarget);
        break;
      case "email":
        setAnchorEmail(event.currentTarget);
        break;
      case "phoneNumber":
        setAnchorPhoneNumber(event.currentTarget);
        break;
      case "nameOfNonProfit":
        setAnchorNameOfNonProfit(event.currentTarget);
        break;
      case "isResolved":
        setAnchorIsResolved(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "name":
        setAnchorName(null);
        break;
      case "email":
        setAnchorEmail(null);
        break;
      case "phoneNumber":
        setAnchorPhoneNumber(null);
        break;
      case "nameOfNonProfit":
        setAnchorNameOfNonProfit(null);
        break;
      case "isResolved":
        setAnchorIsResolved(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phoneNumber":
        setPhoneNumber(value);
        break;
      case "nameOfNonProfit":
        setNameOfNonProfit(value);
        break;
      case "inquiryType":
        setInquiryType(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (inquiryId) => {
    setConfirmationOpen(true);
    setInquiryId(inquiryId);
  };

  const handleUpdate = (inquiryId) => {
    setConfirmationOpenResolve(true);
    setInquiryId(inquiryId);
  };

  const handleClose = () => {
    setConfirmationOpenResolve(false);
    setConfirmationOpen(false);
    setInquiryId(0);
  };

  const deleteInquiry = async () => {
    setLoading(true);
    const responses = await inquiryByIdDelete(inquiryId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllInquiries();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };

  const updateInquiry = async () => {
    setLoading(true);
    const putData = {
      id: inquiryId,
      resolved: true,
    };
    const responses = await inquiryUpdatePut(putData);
    try {
      setLoading(false);
      setConfirmationOpenResolve(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllInquiries();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };

  return (
    <div>
      <Card mb={6}>
        <CardContent pb={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <InputLabel id="inquiryType">Inquiry Type</InputLabel>
              <Select
                fullWidth
                labelId="inquiryType"
                id="inquiryType"
                name="inquiryType"
                value={inquiryType}
                label="Inqiry Type"
                size="small"
                margin="normal"
                onChange={handleChange}
              >
                <MenuItem value="GENERAL">General</MenuItem>
                <MenuItem value="DEMO">Demo</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </CardContent>
        <Paper>
          <TableContainer>
            <Grid
              container
              display={loading ? "flex" : "none"}
              justifyContent="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal" width={"5%"}>
                    Sl. No.
                  </TableCell>
                  <TableCell align="left" padding="normal" width={"10%"}>
                    Name{" "}
                    <IconButton onClick={(e) => handleClick(e, "name")}>
                      <Search
                        fontSize="small"
                        color={name.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorName}
                    open={openName}
                    onClose={() => handleCloseMenu("name")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="name"
                      name="name"
                      value={name}
                      onChange={handleChange}
                      placeholder="Search Name"
                    />
                  </Menu>
                  <TableCell align="left" padding="normal" width={"8%"}>
                    Email
                    <IconButton onClick={(e) => handleClick(e, "email")}>
                      <Search
                        fontSize="small"
                        color={email.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEmail}
                    open={openEmail}
                    onClose={() => handleCloseMenu("email")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      placeholder="Search Email"
                    />
                  </Menu>
                  <TableCell align="left" padding="normal" width={"12%"}>
                    Phone Number
                    <IconButton onClick={(e) => handleClick(e, "phoneNumber")}>
                      <Search
                        fontSize="small"
                        color={phoneNumber.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorPhoneNumber}
                    open={openPhoneNumber}
                    onClose={() => handleCloseMenu("phoneNumber")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={handleChange}
                      placeholder="Search Phone Number"
                    />
                  </Menu>
                  {inquiryType === "DEMO" && (
                    <>
                      {" "}
                      <TableCell align="left" padding="normal">
                        {" "}
                        Organization Name{" "}
                        <IconButton
                          onClick={(e) => handleClick(e, "nameOfNonProfit")}
                        >
                          <Search
                            fontSize="small"
                            color={nameOfNonProfit.length > 0 ? "primary" : ""}
                          />
                        </IconButton>
                      </TableCell>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorNameOfNonProfit}
                        open={openNameOfNonProfit}
                        onClose={() => handleCloseMenu("nameOfNonProfit")}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <TextField
                          size="small"
                          id="nameOfNonProfit"
                          name="nameOfNonProfit"
                          value={nameOfNonProfit}
                          onChange={handleChange}
                          placeholder="Search Organization Name"
                        />
                      </Menu>
                      <TableCell align="left" padding="normal">
                        {" "}
                        Number of Donors{" "}
                      </TableCell>
                      <TableCell align="left" padding="normal">
                        {" "}
                        Product Interested{" "}
                      </TableCell>
                    </>
                  )}
                  <TableCell align="left" padding="normal" width={"15%"}>
                    {" "}
                    Description{" "}
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Creation Date & Time
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Resolved Date & Time
                  </TableCell>
                  <TableCell align="left" padding="normal" width={"10%"}>
                    {" "}
                    Resolved?
                    <IconButton onClick={(e) => handleClick(e, "isResolved")}>
                      <Search
                        fontSize="small"
                        color={isResolved.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorIsResolved}
                    open={openIsResolved}
                    onClose={() => handleCloseMenu("isResolved")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem value={null} disabled>
                      Search is resolved or not
                    </MenuItem>

                    <MenuItem
                      value={""}
                      sx={{
                        backgroundColor: isResolved == "" ? "#219B89" : "",
                        color: isResolved == "" ? "#fff" : "",
                        "&:hover": {
                          backgroundColor: "#219B89",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        setAnchorIsResolved(null);
                        setIsResolved("");
                      }}
                    >
                      All
                    </MenuItem>
                    <MenuItem
                      value={true}
                      sx={{
                        backgroundColor: isResolved == "true" ? "#219B89" : "",
                        color: isResolved == "true" ? "#fff" : "",
                        "&:hover": {
                          backgroundColor: "#219B89",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        setAnchorIsResolved(null);
                        setIsResolved("true");
                      }}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      value={false}
                      sx={{
                        backgroundColor: isResolved == "false" ? "#219B89" : "",
                        color: isResolved == "false" ? "#fff" : "",
                        "&:hover": {
                          backgroundColor: "#219B89",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        setAnchorIsResolved(null);
                        setIsResolved("false");
                      }}
                    >
                      No
                    </MenuItem>
                  </Menu>
                  {privileges.includes("INQUIRY_ALL") && (
                    <TableCell align="left" padding="normal" width={"10%"}>
                      Action
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {inquiries && inquiries.length > 0 ? (
                  inquiries.map((inquiry, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={inquiry.id}
                      >
                        <TableCell align="left">
                          {pageNo * pageSize + index + 1}.
                        </TableCell>
                        <TableCell align="left">{inquiry.name}</TableCell>
                        <TableCell align="left">{inquiry.email}</TableCell>
                        <TableCell align="left">
                          {inquiry.phoneNumber}
                        </TableCell>
                        {inquiryType === "DEMO" && (
                          <>
                            <TableCell align="left">
                              {inquiry.nameOfNonProfit}
                            </TableCell>
                            <TableCell align="left">
                              {inquiry.numberOfDonors}
                            </TableCell>
                            <TableCell align="left">
                              {inquiry.productInterested}
                            </TableCell>
                          </>
                        )}
                        <TableCell align="left">
                          {inquiry.description}
                        </TableCell>
                        <TableCell align="left">
                          {inquiry.creationDateTimeStamp &&
                          inquiry.creationDateTimeStamp !== 0
                            ? `${moment
                                .unix(inquiry.creationDateTimeStamp)
                                .format("Do MMMM YYYY, h:mm a")}`
                            : "No date found"}
                        </TableCell>
                        <TableCell align="left">
                          {inquiry.resolved
                            ? inquiry.lastModifiedDateTimeStamp &&
                              inquiry.lastModifiedDateTimeStamp !== 0
                              ? `${moment
                                  .unix(inquiry.lastModifiedDateTimeStamp)
                                  .format("Do MMMM YYYY, h:mm a")}`
                              : "No date found"
                            : "Not resolved yet"}
                        </TableCell>
                        <TableCell align="left">
                          {inquiry.resolved ? (
                            <Chip label="Yes" color="success" />
                          ) : (
                            <Chip label="No" color="error" />
                          )}
                        </TableCell>
                        <TableCell padding="none" align="left">
                          {privileges.includes("INQUIRY_ALL") && (
                            <Stack direction={"row"} m={2}>
                              {!inquiry.resolved && (
                                <IconButton
                                  variant="contained"
                                  size="small"
                                  color="success"
                                  onClick={() => handleUpdate(inquiry.id)}
                                >
                                  <CheckCircleOutline />
                                </IconButton>
                              )}
                              <IconButton
                                aria-label="delete"
                                size="large"
                                onClick={() => handleDelete(inquiry.id)}
                              >
                                <Delete sx={{ color: "#d93232" }} />
                              </IconButton>
                            </Stack>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>No Inquiries Found!</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={pageSize}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <ConfirmationDialog
          title="Delete Inquiry"
          text="Are you sure that you want to delete this inquiry?"
          open={confirmationOpen}
          handleClose={handleClose}
          handleSubmit={deleteInquiry}
        />

        <ConfirmationDialog
          title="Resolve Inquiry"
          text="Are you sure that this inquiry is resolved? Proceed to mark as resolve."
          open={confirmationOpenResolve}
          handleClose={handleClose}
          handleSubmit={updateInquiry}
        />
      </Card>
    </div>
  );
}

function InquiryList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Inquiries" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Inquiries
          </Typography>
        </Grid>
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Inquiries</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default InquiryList;
