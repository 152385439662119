import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Calendar, Edit, Home } from "react-feather";

import {
  Alert,
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useSelector } from "react-redux";
import { Call, Email, Wc } from "@mui/icons-material";
import UploadProfilePictureDialog from "../../components/profile/UploadProfilePicture";
import {
  myDetailsGet,
  updateProfileInfoPut,
  updateProfilePicturePut,
} from "../../requests/user";
import { login } from "../../redux/slices/auth";
import { useDispatch } from "react-redux";
import UploadProfileDetailsDialog from "../../components/profile/UpdateProfile";
import moment from "moment";
import { getToastError, getToastSuccess } from "../../utils/toasts";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

function Details(props) {
  const { userData } = props;
  const imageId = userData && userData.image ? userData.image.id : 0;
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleProfilePictureDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleProfilePictureDialogClose = () => {
    setDialogOpen(false);
  };

  const handleUpload = async (data) => {
    const responses = await updateProfilePicturePut(data);
    try {
      if (responses.success) {
        getToastSuccess(responses.message);
        props.getCurrentUser();
        setDialogOpen(false);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Profile Details
        </Typography>

        <Spacer mb={4} />

        <Centered>
          <Avatar
            alt={userData && userData.name}
            src={userData && userData.image ? userData.image.fileUrl : ""}
          />
          <Typography variant="body2" component="div" gutterBottom>
            <Box fontWeight="fontWeightMedium">{userData && userData.name}</Box>
            <Box fontWeight="fontWeightRegular">
              {userData && userData.roles && userData.roles.length > 0
                ? userData.roles[0].roleType
                : ""}
            </Box>
          </Typography>

          <Button
            mr={2}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleProfilePictureDialogOpen()}
          >
            Upload Profile Picture
          </Button>
          {/* <Button mr={2} variant="contained" color="primary" size="small">
            Message
          </Button> */}
        </Centered>
      </CardContent>
      <UploadProfilePictureDialog
        open={dialogOpen}
        imageId={imageId}
        handleClose={handleProfilePictureDialogClose}
        handleSubmit={handleUpload}
        userName={userData && userData.name}
        userImage={userData && userData.image ? userData.image.fileUrl : null}
      />
    </Card>
  );
}

function About(props) {
  const { userData } = props;
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleProfileDetailsDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleProfileDetailsDialogClose = () => {
    setDialogOpen(false);
  };

  const submitDetails = async (data) => {
    const responses = await updateProfileInfoPut(data);
    try {
      if (responses.success) {
        getToastSuccess(responses.message);
        props.getCurrentUser();
        setDialogOpen(false);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          About{" "}
          <Edit
            style={{ cursor: "pointer" }}
            onClick={() => handleProfileDetailsDialogOpen()}
          />
        </Typography>

        <Spacer mb={4} />
        {userData && userData.email ? (
          <Grid container direction="row" alignItems="center" mb={2}>
            <Grid item>
              <AboutIcon>
                <Email />
              </AboutIcon>
            </Grid>

            <Grid item>
              Email Address:{" "}
              <Link href={`mailto:${userData.email}`}>{userData.email}</Link>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {userData && userData.address ? (
          <Grid container direction="row" alignItems="center" mb={2}>
            <Grid item>
              <AboutIcon>
                <Home />
              </AboutIcon>
            </Grid>

            <Grid item>
              Lives in <Link>{userData.address}</Link>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        {userData && userData.phoneNumber ? (
          <Grid container direction="row" alignItems="center" mb={2}>
            <Grid item>
              <AboutIcon>
                <Call />
              </AboutIcon>
            </Grid>

            <Grid item>
              Contact Number:{" "}
              <Link href={`tel:${userData.phoneNumber}`}>
                {userData.phoneNumber}
              </Link>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        {userData && userData.gender ? (
          <Grid container direction="row" alignItems="center" mb={2}>
            <Grid item>
              <AboutIcon>
                <Wc />
              </AboutIcon>
            </Grid>

            <Grid item>
              Gender: <Link>{userData.gender}</Link>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        {userData && userData.dateOfBirth ? (
          <Grid container direction="row" alignItems="center" mb={2}>
            <Grid item>
              <AboutIcon>
                <Calendar />
              </AboutIcon>
            </Grid>

            <Grid item>
              Born on{" "}
              <Link>
                {moment.unix(userData.dateOfBirth).format("MM/DD/YYYY")}
              </Link>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </CardContent>
      <UploadProfileDetailsDialog
        open={dialogOpen}
        handleClose={handleProfileDetailsDialogClose}
        handleSubmit={submitDetails}
      />
    </Card>
  );
}

function ProfileDetails() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {}, [userData]);

  const getCurrentUser = async () => {
    const response = await myDetailsGet();
    if (response.success) {
      dispatch(
        login({
          token: localStorage.getItem("token"),
          refreshToken: localStorage.getItem("refreshToken"),
          user: response.data,
          isLogged: true,
        })
      );
    }
  };
  return (
    <React.Fragment>
      <Helmet title="Profile" />

      <Typography variant="h3" gutterBottom display="inline">
        Profile
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography>Profile</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Details userData={userData} getCurrentUser={getCurrentUser} />
          <About userData={userData} getCurrentUser={getCurrentUser} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ProfileDetails;
