import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  Alert,
  CircularProgress,
  CardContent,
  Card,
  Autocomplete,
  TextField,
  Menu,
} from "@mui/material";
import { Add as AddIcon, Delete, Edit, Search } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { categoryAllGet, categoryByIdDelete } from "../../../requests/category";
import { blogAllGet } from "../../../requests/blog";
import { ConfirmationDialog } from "../../../components/dialogs/ConfirmationDialog";
import { getToastError, getToastSuccess } from "../../../utils/toasts";
import { useSelector } from "react-redux";
import { id } from "date-fns/locale";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [blogUUID, setBlogUUID] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [inputValueCategory, setInputValueCategory] = useState("");
  const [parentCategoryId, setParentCategoryId] = useState(0);
  const [subCategories, setSubCategories] = useState([]);

  const [title, setTitle] = useState("");
  const [anchorTitle, setAnchorTitle] = useState(null);
  const openTitle = Boolean(anchorTitle);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [categoryId, setCategoryId] = useState(0);

  useEffect(() => {
    if (location.search && location.search.length > 0) {
      if (location.search.split("=").length === 2) {
        setBlogUUID(location.search.split("=")[1]);
      }
    }
    getAllBlogs();
  }, []);

  useEffect(() => {
    if (blogUUID.length > 0) {
      let blogIndex = blogs.findIndex((blog) => blog.uuid === blogUUID);
      if (blogIndex !== -1) {
        setSelectedBlog(blogs[blogIndex]);
      }
    }
  }, [blogs, blogUUID]);

  useEffect(() => {
    if (blogUUID !== "") {
      getAllCategories();
    }
  }, [blogUUID]);

  useEffect(() => {
    if (blogUUID !== "") {
      getAllSubCategories();
    }
  }, [blogUUID, pageNo, pageSize, title, parentCategoryId]);

  const getAllBlogs = async () => {
    setLoading(true);
    const responses = await blogAllGet();
    try {
      setLoading(false);

      if (responses.success) {
        let blog = responses.data;
        setBlogs(blog);
        if (blog.length > 0 && blogUUID.length === 0 && !location.search) {
          setBlogUUID(blog[0].uuid);
        }
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllCategories = async () => {
    setLoading(true);
    const responses = await categoryAllGet({
      pageNo: 0,
      pageSize: 500,
      title: inputValueCategory.length > 0 ? inputValueCategory : null,
      blogUUID,
    });
    try {
      setLoading(false);

      if (responses.success) {
        setCategories(responses.data.content);
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const getAllSubCategories = async () => {
    setLoading(true);
    const responses = await categoryAllGet({
      pageNo,
      pageSize,
      title: title.length > 0 ? title : null,
      blogUUID,
      parentCategoryId: parentCategoryId === 0 ? null : parentCategoryId,
      ascOrDesc: "DESCENDING",
      sortBy: "id",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setSubCategories(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };
  const handleClick = (event, name) => {
    switch (name) {
      case "title":
        setAnchorTitle(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "title":
        setAnchorTitle(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (categoryId) => {
    setConfirmationOpen(true);
    setCategoryId(categoryId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setCategoryId(0);
  };

  const deleteCategory = async () => {
    setLoading(true);
    const responses = await categoryByIdDelete(categoryId, blogUUID);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllCategories();
        getAllSubCategories();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id, uuid) => {
    navigate(`/blog/category/update/${id}/${uuid}`);
  };

  return (
    <div>
      <Card mb={6}>
        <CardContent pb={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={
                  selectedBlog && Object.keys(selectedBlog).length > 0
                    ? selectedBlog
                    : null
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    if (newValue.uuid !== blogUUID) {
                      setParentCategoryId(0);
                      setSelectedCategory({});
                    }
                    setSelectedBlog(newValue);
                    setBlogUUID(newValue.uuid);
                  } else {
                    setSelectedBlog({});
                    setBlogUUID("");
                    setParentCategoryId(0);
                    setSelectedCategory({});
                    setSubCategories([]);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={blogs}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    size="small"
                    label="Filter By Blog"
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                disabled={blogUUID.length === 0}
                disablePortal
                value={selectedCategory}
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedCategory(newValue);
                    setParentCategoryId(newValue.id);
                  } else {
                    setSelectedCategory({});
                    setParentCategoryId(0);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValueCategory(newInputValue);
                }}
                options={categories}
                getOptionLabel={(option) => (option.title ? option.title : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    size="small"
                    label="Filter By Category"
                  />
                )}
              />
            </Grid> */}
          </Grid>
        </CardContent>
        <Paper>
          <TableContainer>
            <Grid
              container
              display={loading ? "flex" : "none"}
              justifyContent="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" padding="normal">
                    Sl. No.
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Title
                    <IconButton onClick={(e) => handleClick(e, "title")}>
                      <Search
                        fontSize="small"
                        color={title.length > 0 ? "primary" : ""}
                      />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorTitle}
                    open={openTitle}
                    onClose={() => handleCloseMenu("title")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <TextField
                      size="small"
                      id="title"
                      name="title"
                      value={title}
                      onChange={handleChange}
                      placeholder="Search Title"
                    />
                  </Menu>
                  <TableCell
                    align="left"
                    padding="normal"
                    sx={{ width: "25%" }}
                  >
                    Sub-Title
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    Image
                  </TableCell>
                  {privileges.includes("BLOG_UPDATE") ||
                  privileges.includes("BLOG_DELETE") ? (
                    <TableCell align="left" padding="normal">
                      Actions
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {subCategories && subCategories.length > 0 ? (
                  subCategories.map((category, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={category.id}
                      >
                        <TableCell align="left">
                          {pageNo * pageSize + index + 1}.
                        </TableCell>
                        <TableCell align="left">{category.title}</TableCell>
                        <TableCell align="left" sx={{ width: "25%" }}>
                          {category.subTitle}
                        </TableCell>
                        <TableCell align="left" sx={{ borderRadius: "5px" }}>
                          {category.image ? (
                            <img
                              style={{
                                height: "150px",
                                width: "150px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                              src={category.image.fileUrl}
                            />
                          ) : (
                            "No Image"
                          )}
                        </TableCell>
                        <TableCell padding="none" align="left">
                          <Box mr={2}>
                            {privileges.includes("BLOG_UPDATE") && (
                              <IconButton
                                aria-label="details"
                                size="large"
                                onClick={() =>
                                  goEditPage(category.id, category.blogUUID)
                                }
                              >
                                <Edit color="primary" />
                              </IconButton>
                            )}
                            {privileges.includes("BLOG_DELETE") && (
                              <IconButton
                                aria-label="delete"
                                size="large"
                                onClick={() => handleDelete(category.id)}
                              >
                                <Delete sx={{ color: "#d93232" }} />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      No Categories Found for this Blog!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={pageSize}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>
      <ConfirmationDialog
        title="Delete Category"
        text="Are you sure that you want to delete this category?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteCategory}
      />
    </div>
  );
}

function CategoryList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Categories" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Categories
          </Typography>
        </Grid>
        {privileges.includes("BLOG_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/blog/category/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Category
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography> Categories</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CategoryList;
