import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  InputLabel,
  Alert,
  Autocomplete,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload, Delete } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { nonProfitCreatePost } from "../../requests/nonProfit";
import { fileCommonPost } from "../../requests/file";
import { organizationTypeAllGet } from "../../requests/organizationType";
import { emailValidate } from "../../validation/validation";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function CreateForm() {
  const navigate = useNavigate();

  const [types, setTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [inputValueType, setInputValueType] = useState("");

  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [hasErrorMail, setHasErrorMail] = useState(false);
  const [errorMailText, setErrorMailText] = useState("");

  const inputFile = useRef(null);
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [variant, setVariant] = useState("success");

  useEffect(() => {
    getAllTypes();
  }, [inputValueType]);

  const getAllTypes = async () => {
    setLoading(true);
    const responses = await organizationTypeAllGet({
      pageNo: 0,
      pageSize: 500,
      name: inputValueType.length > 0 ? inputValueType : null,
    });
    try {
      setLoading(false);

      if (responses.success) {
        setText("");
        setTypes(responses.data);
      } else {
        setText(responses.message);
        setVariant("error");
      }
    } catch (error) {
      setLoading(false);
      setText(error.response.data.message);
      setVariant("error");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        let checkError = emailValidate.test(value.trim());
        let error = checkError ? "" : "Please provide a valid email";

        setErrorMailText(error);
        setHasErrorMail(!checkError);
        setEmail(value);
        break;
      case "shortDescription":
        setShortDescription(value);
        break;
      case "longDescription":
        setLongDescription(value);
        break;
      default:
        break;
    }
  };

  const handleChangeFile = (e) => {
    if (e.target.files[0] != null) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    inputFile.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImage("");
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (file) {
      let formData = new FormData();
      formData.append("file", file);
      const response = await fileCommonPost(formData, "SUBMISSION");

      try {
        setLoading(false);
        setText(response.message);
        if (response.success) {
          handleCreate(response.data.id);
        } else {
          setVariant("error");
        }
      } catch (error) {
        setLoading(false);
        setText(error.response.data.message);
        setVariant("error");
      }
    } else {
      handleCreate(null);
    }
  };

  const handleCreate = async (id) => {
    setLoading(true);
    var typeIdArray = selectedTypes.map(function (type) {
      return type.id;
    });

    const postData = {
      organizationTypeIds: typeIdArray,
      longDescription,
      shortDescription,
      iconFileId: id,
      name,
      email,
    };
    const responses = await nonProfitCreatePost(postData);
    try {
      setLoading(false);
      setText(responses.message);
      if (responses.success) {
        setVariant("success");
        navigate("/organization/non-profit/list");
      } else {
        setVariant("error");
      }
    } catch (error) {
      setLoading(false);
      setText(error.response.data.message);
      setVariant("error");
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Create
        </Typography>
        {text.length > 0 ? (
          <Alert mt={2} mb={3} severity={variant}>
            {text}
          </Alert>
        ) : (
          ""
        )}

        <InputLabel sx={{ mt: 2, mb: 1 }}>Image</InputLabel>
        <Grid container>
          <Grid item xs={12}>
            {file ? <img style={{ height: "30vh" }} src={image} /> : ""}
          </Grid>
          <Grid item xs={12}>
            {file ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={() => handleUpload()}
                >
                  <CloudUpload mr={2} /> Change
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  color="error"
                  component="span"
                  onClick={() => handleRemove()}
                >
                  <Delete mr={2} /> Remove
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={() => handleUpload()}
              >
                <CloudUpload mr={2} /> Upload
              </Button>
            )}
          </Grid>
        </Grid>

        <input
          type="file"
          id="file"
          accept="image/*"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={handleChangeFile}
          onClick={(event) => {
            event.target.value = null;
          }}
        />

        <TextField
          fullWidth
          margin="normal"
          required
          id="name"
          name="name"
          label="Name"
          variant="outlined"
          value={name}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          margin="normal"
          id="email"
          name="email"
          label="Email"
          required
          variant="outlined"
          value={email}
          error={hasErrorMail}
          helperText={errorMailText}
          onChange={handleChange}
        />

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          multiple
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedTypes(newValue);
            } else {
              setSelectedTypes([]);
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValueType(newInputValue);
          }}
          options={types}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              label="Select Organization Type"
            />
          )}
        />

        <TextField
          fullWidth
          margin="normal"
          id="shortDescription"
          multiline
          rows={2}
          name="shortDescription"
          label="Short Description"
          variant="outlined"
          value={shortDescription}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          margin="normal"
          id="longDescription"
          multiline
          rows={4}
          name="longDescription"
          label="Long Description"
          variant="outlined"
          value={longDescription}
          onChange={handleChange}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={name === "" || hasErrorMail}
        >
          Create
        </LoadingButton>
      </CardContent>
    </Card>
  );
}

function NonProfitCreate() {
  return (
    <React.Fragment>
      <Helmet title="Non-Profit Create" />

      <Typography variant="h3" gutterBottom display="inline">
        Non-Profit Create
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Link component={NavLink} to="/organization/non-profit/list">
          Non-Profits
        </Link>
        <Typography> Create</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CreateForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default NonProfitCreate;
