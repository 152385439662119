import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  CircularProgress,
  TextField,
  Menu,
  Chip,
  MenuItem,
  Stack,
} from "@mui/material";
import {
  Add as AddIcon,
  CheckCircleOutline,
  Delete,
  Edit,
  Search,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  updateUserPatch,
  userAllGet,
  userByIdDelete,
  userVerifyPatch,
} from "../../requests/user";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { useSelector } from "react-redux";
import UpdateUserDetailsDialog from "../../components/user/UpdateUserDetails";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const navigate = useNavigate();
  const { privileges } = props;

  const { roleType } = useParams();

  const pathName = useLocation().pathname;

  const [users, setUsers] = useState([]);

  const [name, setName] = useState("");
  const [anchorName, setAnchorName] = useState(null);
  const openName = Boolean(anchorName);

  const [email, setEmail] = useState("");
  const [anchorEmail, setAnchorEmail] = useState(null);
  const openEmail = Boolean(anchorEmail);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [anchorPhoneNumber, setAnchorPhoneNumber] = useState(null);
  const openPhoneNumber = Boolean(anchorPhoneNumber);

  const [isVerified, setIsVerified] = useState("");
  const [anchorIsVerified, setAnchorIsVerified] = useState(null);
  const openIsVerified = Boolean(anchorIsVerified);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationOpenVerify, setConfirmationOpenVerify] = useState(false);
  const [userId, setUserId] = useState(0);

  const [userDetails, setUserDetails] = useState({});
  const [updateUserDetailsOpen, setUpdateUserDetailsOpen] = useState(false);

  useEffect(() => {
    setPageNo(0);
    setPageSize(10);
    setTotalItems(0);
  }, [pathName]);

  useEffect(() => {
    getAllUsers();
  }, [pageNo, pageSize, name, email, phoneNumber, isVerified, pathName]);

  const getAllUsers = async () => {
    setLoading(true);
    const responses = await userAllGet({
      pageNo,
      pageSize,
      name: name.length > 0 ? name : null,
      email: email.length > 0 ? email : null,
      roleType,
      phoneNumber: phoneNumber.length > 0 ? phoneNumber : null,
      isVerified: isVerified.length > 0 ? isVerified : null,
      sortBy: "id",
      ascOrDesc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setUsers(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  useEffect(() => {}, [users, totalItems]);
  const handleClick = (event, name) => {
    switch (name) {
      case "name":
        setAnchorName(event.currentTarget);
        break;
      case "email":
        setAnchorEmail(event.currentTarget);
        break;
      case "phoneNumber":
        setAnchorPhoneNumber(event.currentTarget);
        break;
      case "isVerified":
        setAnchorIsVerified(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "name":
        setAnchorName(null);
        break;
      case "email":
        setAnchorEmail(null);
        break;
      case "phoneNumber":
        setAnchorPhoneNumber(null);
        break;
      case "isVerified":
        setAnchorIsVerified(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phoneNumber":
        setPhoneNumber(value);
        break;
      case "isVerified":
        setIsVerified(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (userId) => {
    setConfirmationOpen(true);
    setUserId(userId);
  };

  const handleVerify = (userId) => {
    setConfirmationOpenVerify(true);
    setUserId(userId);
  };

  const handleUpdate = (userDetails) => {
    setUserDetails(userDetails);
    setUpdateUserDetailsOpen(true);
  };

  const handleClose = () => {
    setUpdateUserDetailsOpen(false);
    setConfirmationOpenVerify(false);
    setConfirmationOpen(false);
    setUserId(0);
    setUserDetails({});
  };

  const verifyUser = async () => {
    setLoading(true);
    const putData = {
      userId,
      verified: true,
    };
    const responses = await userVerifyPatch(putData);
    try {
      setLoading(false);
      setConfirmationOpenVerify(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllUsers();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };

  const updateUser = async (data) => {
    setLoading(true);
    const responses = await updateUserPatch(data);
    try {
      setLoading(false);
      setUpdateUserDetailsOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllUsers();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };

  const deleteUser = async () => {
    setLoading(true);
    const responses = await userByIdDelete(userId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllUsers();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      getToastError(error.response.data.message);
    }
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  Sl. No.
                </TableCell>
                <TableCell align="left" padding="normal">
                  Name{" "}
                  <IconButton onClick={(e) => handleClick(e, "name")}>
                    <Search
                      fontSize="small"
                      color={name.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorName}
                  open={openName}
                  onClose={() => handleCloseMenu("name")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    placeholder="Search Name"
                  />
                </Menu>
                <TableCell align="left" padding="normal">
                  Email
                  <IconButton onClick={(e) => handleClick(e, "email")}>
                    <Search
                      fontSize="small"
                      color={email.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEmail}
                  open={openEmail}
                  onClose={() => handleCloseMenu("email")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    placeholder="Search Email"
                  />
                </Menu>
                <TableCell align="left" padding="normal">
                  Phone Number
                  <IconButton onClick={(e) => handleClick(e, "phoneNumber")}>
                    <Search
                      fontSize="small"
                      color={phoneNumber.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorPhoneNumber}
                  open={openPhoneNumber}
                  onClose={() => handleCloseMenu("phoneNumber")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={handleChange}
                    placeholder="Search Phone Number"
                  />
                </Menu>
                {roleType !== "ADMIN" ? (
                  <>
                    <TableCell align="left" padding="normal">
                      Verified?
                      <IconButton onClick={(e) => handleClick(e, "isVerified")}>
                        <Search
                          fontSize="small"
                          color={isVerified.length > 0 ? "primary" : ""}
                        />
                      </IconButton>
                    </TableCell>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorIsVerified}
                      open={openIsVerified}
                      onClose={() => handleCloseMenu("isVerified")}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem value={null} disabled>
                        Search is verified or not
                      </MenuItem>

                      <MenuItem
                        value={""}
                        sx={{
                          backgroundColor: isVerified == "" ? "#219B89" : "",
                          color: isVerified == "" ? "#fff" : "",
                          "&:hover": {
                            backgroundColor: "#219B89",
                            color: "#fff",
                          },
                        }}
                        onClick={() => {
                          setAnchorIsVerified(null);
                          setIsVerified("");
                        }}
                      >
                        All
                      </MenuItem>
                      <MenuItem
                        value={true}
                        sx={{
                          backgroundColor:
                            isVerified == "true" ? "#219B89" : "",
                          color: isVerified == "true" ? "#fff" : "",
                          "&:hover": {
                            backgroundColor: "#219B89",
                            color: "#fff",
                          },
                        }}
                        onClick={() => {
                          setAnchorIsVerified(null);
                          setIsVerified("true");
                        }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value={false}
                        sx={{
                          backgroundColor:
                            isVerified == "false" ? "#219B89" : "",
                          color: isVerified == "false" ? "#fff" : "",
                          "&:hover": {
                            backgroundColor: "#219B89",
                            color: "#fff",
                          },
                        }}
                        onClick={() => {
                          setAnchorIsVerified(null);
                          setIsVerified("false");
                        }}
                      >
                        No
                      </MenuItem>
                    </Menu>{" "}
                    <TableCell align="left" padding="normal">
                      Actions
                    </TableCell>
                  </>
                ) : (
                  <TableCell align="left" padding="normal">
                    Role
                  </TableCell>
                )}
                {roleType === "ADMIN" &&
                  (privileges.includes("USER_UPDATE") ||
                  privileges.includes("USER_DELETE") ? (
                    <TableCell align="left" padding="normal">
                      Actions
                    </TableCell>
                  ) : null)}
              </TableRow>
            </TableHead>

            <TableBody>
              {users && users.length > 0 ? (
                users.map((user, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={user.id}>
                      <TableCell align="left">
                        {pageNo * pageSize + index + 1}.
                      </TableCell>
                      <TableCell align="left">{user.name}</TableCell>
                      <TableCell align="left">{user.email}</TableCell>
                      <TableCell align="left">{user.phoneNumber}</TableCell>
                      {roleType !== "ADMIN" ? (
                        <>
                          <TableCell align="left">
                            {user.verified ? (
                              <Chip label="Yes" color="success" />
                            ) : (
                              <Chip label="No" color="error" />
                            )}
                          </TableCell>

                          <TableCell padding="none" align="left">
                            <Box mr={2}>
                              {privileges.includes("USER_UPDATE") &&
                                !user.verified && (
                                  <IconButton
                                    variant="contained"
                                    size="small"
                                    color="success"
                                    onClick={() => handleVerify(user.id)}
                                  >
                                    <CheckCircleOutline />
                                  </IconButton>
                                )}
                              {privileges.includes("USER_DELETE") && (
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  onClick={() => handleDelete(user.id)}
                                >
                                  <Delete sx={{ color: "#d93232" }} />
                                </IconButton>
                              )}
                            </Box>
                          </TableCell>
                        </>
                      ) : (
                        <TableCell align="left">
                          <Chip
                            // color="secondary"
                            label={
                              user.roles &&
                              user.roles.length > 0 &&
                              user.roles[0].roleName
                            }
                          />
                        </TableCell>
                      )}
                      {roleType === "ADMIN" && (
                        <TableCell padding="none" align="left">
                          <Stack mr={2} direction="row">
                            {privileges.includes("USER_UPDATE") &&
                              user.name.toLowerCase().trim() !=
                                "super admin" && (
                                <IconButton
                                  aria-label="details"
                                  size="large"
                                  onClick={() => handleUpdate(user)}
                                >
                                  <Edit color="primary" />
                                </IconButton>
                              )}
                            {privileges.includes("USER_DELETE") &&
                              user.name.toLowerCase().trim() !=
                                "super admin" && (
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  onClick={() => handleDelete(user.id)}
                                >
                                  <Delete sx={{ color: "#d93232" }} />
                                </IconButton>
                              )}
                          </Stack>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>No Users Found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* </Card> */}
      <ConfirmationDialog
        title="Delete User"
        text="Are you sure that you want to delete this user? All the data related to this user will also be removed."
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deleteUser}
      />

      <ConfirmationDialog
        title="Verify User"
        text="Are you sure that you want to make this user verified? If yes, please proceed."
        open={confirmationOpenVerify}
        handleClose={handleClose}
        handleSubmit={verifyUser}
      />
      <UpdateUserDetailsDialog
        open={updateUserDetailsOpen}
        userDetails={userDetails}
        handleClose={handleClose}
        handleSubmit={updateUser}
      />
    </div>
  );
}

function UserList() {
  const { roleType } = useParams();
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet
        title={
          roleType === "ADMIN"
            ? "Admins"
            : roleType === "USER"
            ? "Users"
            : "Non-Profit Users"
        }
      />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {roleType === "ADMIN"
              ? "Admins"
              : roleType === "USER"
              ? "Users"
              : "Non-Profit Users"}
          </Typography>
        </Grid>

        {roleType === "ADMIN" && privileges.includes("USER_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/user/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Admin
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography>
          {" "}
          {roleType === "ADMIN"
            ? "Admins"
            : roleType === "USER"
            ? "Users"
            : "Non-Profit Users"}
        </Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UserList;
