import axios from "axios";
import { basePath, headers } from "../utils/http";

export const verificationDocAllGet = async () => {
    try {
      const response = await axios.get(`${basePath()}/verification-docs`, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const verificationDocCreatePost = async (data) => {
    try {
      const response = await axios.post(`${basePath()}/verification-docs`, data, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const verificationDocUpdatePut = async (data) => {
    try {
      const response = await axios.put(`${basePath()}/verification-docs`, data, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const verificationDocByIdGet = async (id) => {
    try {
      const response = await axios.get(`${basePath()}/verification-docs/id/${id}`, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const verificationDocByIdDelete = async (id) => {
    try {
      const response = await axios.delete(`${basePath()}/verification-docs/id/${id}`, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };
  export const verificationDocAllUploadedGet = async (params) => {
    try {
      const response = await axios.get(`${basePath()}/non-profit-docs/admin`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        }
      });
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  };

  export const verificationDocApproveRejectPost = async (data) => {
    try {
      const response = await axios.post(`${basePath()}/non-profit-docs/accept-reject`, data, headers());
      if (response.data.success) {
        return {
          success: true,
          data: response.data.payload,
          message: response.data.message,
        };
      } else {
        return { success: false, data: {}, message: response.data.message };
      }
    } catch (error) {
      return { success: false, data: {}, message: error.response === undefined ? "Something went wrong" : error.response.data.message };
    }
  }; 