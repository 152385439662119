import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  CircularProgress,
  TextField,
  Menu,
  MenuItem,
  Tooltip,
  Stack,
} from "@mui/material";
import { Add as AddIcon, Delete, Edit, Search } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { promosAllGet, promoByIdDelete } from "../../requests/promo";
import { getToastError, getToastSuccess } from "../../utils/toasts";
import moment from "moment";
import { promoTypes } from "../../constant";
import { stringEachWordFirstLetterUpperCase } from "../../formatted";
import { useSelector } from "react-redux";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EnhancedTable(props) {
  const { privileges } = props;
  const navigate = useNavigate();
  const [promos, setPromos] = useState([]);

  const [code, setCode] = useState("");
  const [anchorCode, setAnchorCode] = useState(null);
  const openCode = Boolean(anchorCode);

  const [promoType, setPromoType] = useState("");
  const [anchorPromoType, setAnchorPromoType] = useState(null);
  const openPromoType = Boolean(anchorPromoType);

  const [active, setActive] = useState("");
  const [anchorActive, setAnchorActive] = useState(null);
  const openActive = Boolean(anchorActive);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [promoId, setPromoId] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      getAllPromos();
    }, 600);
    return () => {
      clearTimeout(timer);
    };
  }, [pageNo, pageSize, code, promoType, active]);

  const getAllPromos = async () => {
    setLoading(true);
    const responses = await promosAllGet({
      page_no: pageNo,
      page_size: pageSize,
      code: code || null,
      promoType: promoType || null,
      active: active || null,
      sort_by: "id",
      asc_or_desc: "DESCENDING",
    });
    try {
      setLoading(false);

      if (responses.success) {
        setPromos(responses.data.content);
        setTotalItems(responses.data.totalElements);
        setTotalPages(responses.data.totalPages);
      } else {
        let message = responses.message;
        if (
          message &&
          message.toLowerCase().includes("full authentication is required")
        ) {
          // getToastError("Your session ended. Please login again.");
          navigate("/");
        } else {
          getToastError(message);
        }
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const handleClick = (event, name) => {
    switch (name) {
      case "code":
        setAnchorCode(event.currentTarget);
        break;
      case "promoType":
        setAnchorPromoType(event.currentTarget);
        break;
      case "active":
        setAnchorActive(event.currentTarget);
        break;
      default:
        break;
    }
  };
  const handleCloseMenu = (name) => {
    switch (name) {
      case "code":
        setAnchorCode(null);
        break;
      case "promoType":
        setAnchorPromoType(null);
        break;
      case "active":
        setAnchorActive(null);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "code":
        setCode(value);
        break;
      case "promoType":
        setPromoType(value);
        break;
      case "active":
        setActive(value);
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleDelete = (promoId) => {
    setConfirmationOpen(true);
    setPromoId(promoId);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
    setPromoId(0);
  };

  const deletePromo = async () => {
    setLoading(true);
    const responses = await promoByIdDelete(promoId);
    try {
      setLoading(false);
      setConfirmationOpen(false);
      if (responses.success) {
        getToastSuccess(responses.message);
        setPageNo(0);
        getAllPromos();
      } else {
        getToastError(responses.message);
      }
    } catch (error) {
      setLoading(false);
      getToastError(error.response.data.message);
    }
  };

  const goEditPage = (id) => {
    navigate(`/promos/update/${id}`);
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Grid
            container
            display={loading ? "flex" : "none"}
            justifyContent="center"
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" padding="normal">
                  Sl. No.
                </TableCell>
                <TableCell align="left" padding="normal">
                  Code
                  <IconButton onClick={(e) => handleClick(e, "code")}>
                    <Search
                      fontSize="small"
                      color={code.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorCode}
                  open={openCode}
                  onClose={() => handleCloseMenu("code")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <TextField
                    size="small"
                    id="code"
                    name="code"
                    value={code}
                    onChange={handleChange}
                    placeholder="Search Code"
                  />
                </Menu>
                <TableCell align="left" padding="normal">
                  Promo Type
                  <IconButton onClick={(e) => handleClick(e, "promoType")}>
                    <Search
                      fontSize="small"
                      color={promoType.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorPromoType}
                  open={openPromoType}
                  onClose={() => handleCloseMenu("promoType")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem value={null} disabled>
                    Search Promo Type
                  </MenuItem>
                  <MenuItem
                    value={""}
                    sx={{
                      backgroundColor: promoType == "" ? "#219B89" : "",
                      color: promoType == "" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorPromoType(null);
                      setPromoType("");
                    }}
                  >
                    All
                  </MenuItem>
                  {promoTypes.map((type, index) => {
                    return (
                      <MenuItem
                        key={index}
                        sx={{
                          backgroundColor: promoType == type ? "#219B89" : "",
                          color: promoType == type ? "#fff" : "",
                          "&:hover": {
                            backgroundColor: "#219B89",
                            color: "#fff",
                          },
                        }}
                        onClick={() => {
                          setAnchorPromoType(null);
                          setPromoType(type);
                        }}
                        value={type}
                      >
                        {stringEachWordFirstLetterUpperCase(type)}
                      </MenuItem>
                    );
                  })}
                </Menu>
                <TableCell align="left" padding="normal">
                  Expiration Date
                </TableCell>
                <TableCell align="left" padding="normal">
                  Total Usages
                </TableCell>
                <TableCell align="center" padding="normal">
                  Products
                </TableCell>
                <TableCell align="left" padding="normal">
                  Active?
                  <IconButton onClick={(e) => handleClick(e, "active")}>
                    <Search
                      fontSize="small"
                      color={active.length > 0 ? "primary" : ""}
                    />
                  </IconButton>
                </TableCell>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorActive}
                  open={openActive}
                  onClose={() => handleCloseMenu("active")}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem value={null} disabled>
                    Search is Active or not
                  </MenuItem>

                  <MenuItem
                    value={""}
                    sx={{
                      backgroundColor: active == "" ? "#219B89" : "",
                      color: active == "" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorActive(null);
                      setActive("");
                    }}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    value={true}
                    sx={{
                      backgroundColor: active == "true" ? "#219B89" : "",
                      color: active == "true" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorActive(null);
                      setActive("true");
                    }}
                  >
                    Yes
                  </MenuItem>
                  <MenuItem
                    value={false}
                    sx={{
                      backgroundColor: active == "false" ? "#219B89" : "",
                      color: active == "false" ? "#fff" : "",
                      "&:hover": {
                        backgroundColor: "#219B89",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setAnchorActive(null);
                      setActive("false");
                    }}
                  >
                    No
                  </MenuItem>
                </Menu>

                {privileges.includes("PROMO_UPDATE") ||
                privileges.includes("PROMO_DELETE") ? (
                  <TableCell align="left" padding="normal">
                    Actions
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {promos && promos.length > 0 ? (
                promos.map((promo, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={promo.id}
                    >
                      <TableCell align="left">
                        {pageNo * pageSize + index + 1}.
                      </TableCell>
                      <TableCell align="left">{promo.code}</TableCell>
                      <TableCell align="left">
                        {promo.promoType &&
                          stringEachWordFirstLetterUpperCase(promo.promoType)}
                      </TableCell>
                      <TableCell align="left">
                        {moment
                          .unix(promo.expirationDate)
                          .format("MM/DD/YYYY hh:mm a")}
                      </TableCell>
                      <TableCell align="left">{promo.totalUsages}</TableCell>
                      <TableCell align="center">
                        {promo.promoProducts &&
                          promo.promoProducts.map((product) => {
                            return (
                              <Chip
                                label={product.productTag}
                                sx={{ m: 1 }}
                                color="secondary"
                              />
                            );
                          })}
                      </TableCell>
                      <TableCell align="left">
                        {promo.active ? (
                          <Chip label="Yes" color="success" />
                        ) : (
                          <Chip label="No" color="error" />
                        )}
                      </TableCell>

                      <TableCell padding="none" align="left">
                        <Stack direction="row" mr={2}>
                          {" "}
                          {privileges.includes("PROMO_UPDATE") && (
                            <Tooltip title="Edit">
                              <IconButton
                                aria-label="details"
                                size="large"
                                onClick={() => goEditPage(promo.id)}
                              >
                                <Edit color="primary" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {privileges.includes("PROMO_DELETE") && (
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                size="large"
                                onClick={() => handleDelete(promo.id)}
                              >
                                <Delete sx={{ color: "#d93232" }} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={9}>No Promos Found!</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={pageNo}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmationDialog
        title="Delete Promo"
        text="Are you sure that you want to delete this promo?"
        open={confirmationOpen}
        handleClose={handleClose}
        handleSubmit={deletePromo}
      />
    </div>
  );
}

function PromoList() {
  const [privileges, setPrivileges] = useState([]);

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userData.roles && userData.roles.length > 0) {
      let privilegeAll = userData.roles[0].privileges;
      let privileges = privilegeAll.map((pri) => pri.name);
      setPrivileges(privileges);
    }
  }, [userData]);
  useEffect(() => {}, [privileges]);
  return (
    <React.Fragment>
      <Helmet title="Promos" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Promos
          </Typography>
        </Grid>
        {privileges.includes("PROMO_CREATE") && (
          <Grid item>
            <div>
              <Link component={NavLink} to="/promos/create">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  New Promo
                </Button>
              </Link>
            </div>
          </Grid>
        )}
      </Grid>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private">
          Dashboard
        </Link>
        <Typography>Promos</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable privileges={privileges} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PromoList;
